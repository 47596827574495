import {
    makeObservable,
    observable,
    runInAction,
    action,
    computed
} from 'mobx';

export default class LayoutStore {

    public layoutCode: string;
    public adminMode: boolean;

    constructor() {
        makeObservable(this, {
            updateValue: action,
            load: action,
            layoutCode: observable,
            adminMode: observable,
            changed: computed
        });

        this.layoutCode = '';
        this.adminMode = false;
    }

    public load(clockData: any, password: string = "") {
        this.adminMode = true;
    }

    public updateAdminRequest(sdk:any, code: string, admin: string): Promise<void>{
        return sdk.fetchApi({
            action: 'updatelayout',
            code: code,
            authcode: admin,
            value: this.layoutCode
        }, 'PATCH')
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    get changed() {
        return this.layoutCode !== '';
    }

    get storeId() {
        return 'layout';
    }
}