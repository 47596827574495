import {
    observer
} from 'mobx-react-lite';
import { TextField, Container, Checkbox, PickMedia } from '../../controls';

const GeneralEdit = observer(({ sdk, store, editMode }: any) => {

    const isEditMode: boolean = !!editMode;

    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Tournament name
            </Container>
            <TextField
                id={'general-h1-label'}
                maxLength={64}
                inputOption={{
                    widthPx: 500,
                    margin: [0, 0, 8, 50],
                    float: 'left',
                    borderSize: 2,
                    border: 'solid #fff'
                    // textAlign: 'left'
                }}
                placeholder={'Enter title'}
                store={store}
                storeProperty={'h1Label'}
            />
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Tagline ( optional )
            </Container>
            <TextField
                id={'general-h2-label'}
                maxLength={128}
                inputOption={{
                    widthPx: 500,
                    margin: [0, 0, 8, 50],
                    float: 'left',
                    borderSize: 2,
                    border: 'solid #fff'
                    // textAlign: 'left'
                }}
                placeholder={'Enter subtitle ( optional )'}
                store={store}
                storeProperty={'h2Label'}
            />
            {sdk.user.premiumUser ? (<>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Logo ( Url )
                </Container>
                <PickMedia
                    id={'general-logo-label'}
                    containerOption={{
                        margin: [0, 0, 8, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                        // textAlign: 'left'
                    }}
                    store={store}
                    storeProperty={'logo'}
                    allowUri={true}
                    mediaType={'image'}
                    defaultValue={'Default'}
                />
                {/* <TextField
                    id={'general-logo-label'}
                    maxLength={256}
                    placeholder={'Enter url to logo ( optional )'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 8, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                        // textAlign: 'left'
                    }}
                    store={store}
                    storeProperty={'logo'}
                /> */}
            </>) : null}
            {!sdk.user.premiumUser ? (<>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Password ( optional )
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    upperCase={true}
                    placeholder={'Enter password ( optional )'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 8, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                        // textAlign: 'left'
                    }}
                    disabled={isEditMode}
                    store={store}
                    storeProperty={'password'}
                />
            </>) : null}
            <Checkbox
                id={'currency-allowed'}
                store={store}
                storeProperty={'allowCurrency'}
                label={'Currency enabled'}
                containerOption={{
                    float: 'left',
                    widthPx: 440,
                    margin: [16, 0, 0, 0]
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            <Checkbox
                id={'floating'}
                store={store}
                storeProperty={'floating'}
                label={'Floating number'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store?.allowCurrency ? (
                <>
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        Currency ( eur, chf, usd, gbp )
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={3}
                        upperCase={true}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 8, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                            // textAlign: 'left'
                        }}
                        store={store}
                        storeProperty={'currency'}
                    />
                </>
            ) : null}
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Country ( de, ch, en )
            </Container>
            <TextField
                id={'general-h1-label'}
                maxLength={2}
                upperCase={true}
                inputOption={{
                    widthPx: 500,
                    margin: [0, 0, 8, 50],
                    float: 'left',
                    borderSize: 2,
                    border: 'solid #fff'
                    // textAlign: 'left'
                }}
                store={store}
                storeProperty={'country'}
            />
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                {store.currencyPreview}
            </Container>
            <Checkbox
                id={'stream-allowed'}
                store={store}
                storeProperty={'allowStream'}
                label={'Stream enabled'}
                containerOption={{
                    float: 'left',
                    widthPx: 440,
                    margin: [16, 0, 0, 0]
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.allowStream ? (<>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Stream
                </Container>
                <TextField
                    id={'stream-url'}
                    maxLength={256}
                    placeholder={'Optional: Stream Url'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 8, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                        // textAlign: 'left'
                    }}
                    store={store}
                    storeProperty={'streamUrl'}
                />
            </>) : null}
            {store.stylecode !== null ? (<>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Style Code ( optional )
                </Container>
                <TextField
                    id={'general-stylecode'}
                    maxLength={40}
                    upperCase={true}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 8, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                        // textAlign: 'left'
                    }}
                    store={store}
                    storeProperty={'stylecode'}
                />
            </>) : null}
        </Container>
    </>;
});

export default GeneralEdit;