export const EVENTS = {
    EVENT_NEXTLEVEL: 'NXT',
    EVENT_BREAK_START: 'BRS',
    EVENT_BREAK_END: 'BRE',
    EVENT_NEXTLEVEL_5: 'NXT5',
    EVENT_NEXTLEVEL_10: 'NXT10',
    EVENT_NEXTLEVEL_30: 'NXT30',
    EVENT_NEXTLEVEL_60: 'NXT60'
}

export const EVENT_TYPE = {
    EVENT_SOUND: 'SND'
}