import {
    observer
} from 'mobx-react-lite';
// import {
//     translateKey
// } from '../../../utils/helper'
import getSdk from '../../../api'

const TextArea = observer(({
    id,
    inputOption,
    value,
    store = undefined,
    storeProperty = undefined,
    onManipulateInput = null,
    onInput = null
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const propsInput = useStyles({
        padding: [16, 48, 16, 48],
        fontSize: 32,
        lineHeight: 32,
        widthPx: 480,
        ...(inputOption || {})
    });

    let useValue = value;

    if (store && storeProperty) {
        if (Array.isArray(storeProperty)) {
            useValue = store[storeProperty[0]][storeProperty[1]];
        } else {
            useValue = store[storeProperty];
        }
    }

    return <textarea
        id={id}
        className={'txtedit'}
        style={propsInput}
        onInput={(event: any) => {
            let useInput = (event.target.value || '');
  
            if(onManipulateInput) {
                useInput = onManipulateInput(useInput)
            }
            
            // Update Targetvalue
            if (useInput !== event.target.value) {
                event.target.innerHTML = useInput;
                event.target.value = useInput;
            }

            // Update the statepath
            if (store && storeProperty) {
                if (Array.isArray(storeProperty)) {
                    if (store?.updateArrayValue) {
                        store.updateArrayValue(storeProperty[0], storeProperty[1], useInput)
                    }
                } else {
                    if (store?.updateValue) {
                        store.updateValue(storeProperty, useInput)
                    }
                }
            }

            // Trigger the onInput callback if necessary
            if (onInput) {
                onInput(useInput);
            }
        }}
        value={useValue}
    />
});


export default TextArea;

// let newTextInput = document.createElement('textarea');
// newTextInput.setAttribute('id', inputName);
// newTextInput.className = 'txtedit';
// newTextInput = this.setCssStyle(newTextInput, options);
// newTextInput.value = value;
// newTextInput.placeholder = placeholder;
// if (maxLength) newTextInput.maxLength = maxLength;
// newTextInput.oninput = (event) => oninput ? oninput(event.target.value) : null;
// parent.appendChild(newTextInput);