import { useState } from 'react';
import {
    observer
} from 'mobx-react-lite';
import { Container, ActionButton, TextField, List } from '../controls';

const ClockLogin = observer(({ sdk }: any) => {
    const [code, setCode] = useState(sdk?.latestClockTmp?.code || '');
    const user = sdk.user;

    return <>
        <Container
            containerOption={{
                widthPx: 700,
                margin: [16, 0, 16, 10],
                fontSize: 32,
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff'
            }}
        >
            {`Clock Login`}
        </Container>
        <Container
            id={'login-body'}
            containerOption={{
                widthPx: 700,
                heightPx: 940,
                margin: [16, 0, 0, 10]
            }}
            className={'settingarea'}
        >
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 280,
                    margin: [16, 0, 0, 10],
                    textAlign: 'center',
                    float: 'left',
                    borderSize: 2,
                    borderBottom: 'solid #fff'
                }}
            >
                <Container
                    containerOption={{
                        width: '100%'
                    }}
                >
                    <Container>
                        Clockcode
                    </Container>
                    <TextField
                        id={'clock-admin-code'}
                        upperCase={true}
                        value={code}
                        onInput={(v: any) => setCode(v)}
                        maxLength={4}
                        inputOption={{
                            widthPx: 500,
                            margin: [16, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                    />
                    <ActionButton
                        actionColor={'green'}
                        onClick={() => sdk.loadClock(code)}
                        buttonOption={{
                            widthPx: 192,
                            heightPx: 96,
                            lineHeight: 80,
                            heightToWidth: undefined,
                            margin: [16, 16, 0, 0],
                            padding: [0, 8, 0, 8],
                            textAlign: 'center'
                        }}
                        iconOption={{
                            widthPx: 48,
                            heightPx: 48,
                            margin: [16, 0, 0, 0]
                        }}
                        icon={'./data/icon/load.svg'}
                    >
                        Load
                    </ActionButton>
                </Container>
            </Container>
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 600,
                    margin: [16, 0, 0, 10],
                    float: 'left'
                }}
            >
                {(user.loggedIn && user.account.currentLicense) ? (  <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        float: 'left',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}
                >
                    Current active tournaments
                </Container>
                <List
                    components={(user?.account?.sessionList || []).map((session: any) => (
                        <>
                            <ActionButton
                                id={'template-single-table'}
                                actionColor={'green'}
                                onClick={() => {
                                    sdk.loadClock(session.code)
                                }}
                                buttonOption={{
                                    widthPx: 620,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    heightPx: 96,
                                    lineHeight: 80,
                                    fontSize: 26,
                                    heightToWidth: undefined,
                                    padding: [0, 8, 0, 8],
                                    margin: [16, 0, 16, 0],
                                    textAlign: 'center'
                                }}
                            >
                                {session.name}
                            </ActionButton>
                        </>
                    ))}
                    containerOption={{
                        widthPx: 680,
                        heightPx: 520,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    listOption={{
                        heightPx: 128,
                        widthPx: 680
                    }}
                />
            </>) : null}
            </Container>
        </Container>
        <ActionButton
            actionColor={'red'}
            onClick={() => sdk.openMainMenu()}
            buttonOption={{
                widthPx: 480,
                heightPx: 96,
                heightToWidth: undefined,
                margin: [16, 16, 0, 128]
            }}
        >
            Leave
        </ActionButton>
    </>;
});


export default ClockLogin;