import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const Icon = observer(({
    id,
    iconOption,
    className,
    onClick,
    source
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsIcon = useStyles({
        bgImage: `url(${source})`,
        bgImageSize: 'cover',
        bgRepeat: 'no-repeat',
        widthPx: 64,
        heightPx: 64,
        border: 'none',
        ...(iconOption || {})
    });

    return <div
        id={id}
        className={className}
        style={propsIcon}
    />
});

export default Icon;