import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx';

const MAX_ADS: number = 10;

export default class AdStore {

    public sdk: any;
    public adminMode: boolean;
    public adRotateTime: string;
    public adList: Array<any>;
    public maxAdCount: number;
    public enableAds: number;
    public selectedAdId: number | null;

    constructor(sdk: any, admin: boolean = false) {
        makeObservable(this, {
            updateValue: action,
            load: action,
            toggleAdState: action,
            setAdImage: action,
            selectAd: action,
            moveUp: action,
            moveDown: action,
            adminMode: observable,
            maxAdCount: observable,
            selectedAdId: observable,
            enableAds: observable,
            adRotateTime: observable,
            adList: observable,
            selectedAd: computed,
            changed: computed
        });

        this.adminMode = admin;
        this.sdk = sdk;
        this.adList = Array.from({ length: MAX_ADS }).map(() => ([null, 0]));
        this.adRotateTime = '0';
        this.maxAdCount = 0;
        this.enableAds = 0;
        this.selectedAdId = null;
    }

    public load(clockData: any, password: string = "") {
        const useLicense = this.sdk?.user?.account?.currentLicense || null;
        this.enableAds = clockData?.config?.ads?.enable || 0;
        this.maxAdCount = useLicense?.config?.capacity?.ads || 0;
        this.adRotateTime = `${clockData?.config?.ads?.time || 10}`;
        this.adList = (clockData?.config?.ads?.list || []).map((item: any) => {
            if (typeof item === 'number') {
                return [null, 0]
            }
            return item;
        })

        if(this.adList.length === 0) {
            this.adList = Array.from({ length: MAX_ADS }).map(() => ([null, 0])); 
        }
        
        // Save in comparison store
        sessionStorage.setItem('comparestore', this.storeValues)
    }

    get selectedAd(): number | null {
        return this.selectedAdId;
    }

    public moveUp(adId:number): void {
        if(adId > 0) {
            const temp = this.adList[adId - 1];
            this.adList[adId - 1] = this.adList[adId];
            this.adList[adId] = temp;
            this.selectedAdId = adId - 1;
        }
    }
    
    public moveDown(adId:number): void {
        if(adId < this.adList.length - 1) {
            const temp = this.adList[adId + 1];
            this.adList[adId + 1] = this.adList[adId];
            this.adList[adId] = temp;
            this.selectedAdId = adId + 1;
        }
    }

    public selectAd(adId: number | null): void {
        if(this.selectedAdId === adId) {
            this.selectedAdId = null;
        } else {
            this.selectedAdId = adId;
        }
    }

    public getAdState(adId: number): number {
        if (this.adList[adId]) {
            return this.adList[adId][1];
        }
        return 0;
    }

    public getAdImage(adId: number): any {
        if (this.adList[adId]) {
            return this.adList[adId][0];
        }
        return null;
    }

    public toggleAdState(adId: number): void {
        this.adList[adId] = [
            this.adList[adId][0],
            1 - this.adList[adId][1]
        ]
    }

    public setAdImage(adId: number, image: any): void {
        this.adList[adId][0] = image;
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    get changed() {
        let compareStore = '';
        try {
            compareStore = sessionStorage.getItem('comparestore') || '';
        } catch (e) {
        }
        return this.storeValues !== compareStore;
    }

    get storeId() {
        return 'admanager';
    }

    get storeValues() {
        return JSON.stringify([
            this.adRotateTime,
            this.adList,
            this.enableAds
        ]);
    }

    get updatedConfig() {
        return {
            ads: this.config
        };
    }

    get config() {
        return {
            time: parseInt(this.adRotateTime, 10),
            list: this.adList.map((item: any) => item[0] === null ? 0 : item),
            enable: this.enableAds
        }
    }
}