import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ActionButton,
    IconButton,
    ImageComponent
} from '../../controls';
import { openWebsite } from '../../../utils/urls';

const MediaObject = observer(({ sdk, name, preview, onChangeName, onDelete, onClickContainer }: any) => {
    return <Container
        containerOption={{
            widthPx: 200,
            heightPx: 200,
            bgColor: '#fff1c9',
            margin: [16, 0, 0, 16],
            float: 'left',
            position: 'relative',
            borderRadius: 16,
            bgImage: `url(${preview})`,
            bgRepeat: 'no-repeat',
            bgImageSize: 'cover'
        }}
        onClick={() => onClickContainer ? onClickContainer() : onClickContainer}
    >
        {onDelete ?
            <IconButton
                buttonOption={{
                    widthPx: 48,
                    heightPx: 48,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: [8, 8, 0, 0]
                }}
                onClick={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                    sdk.openDeleteDialog('Really delete media', 'DELETE', () => onDelete())
                }}
                source={'./data/icon/delete.svg'}
            />
            : null}
        <Container
            containerOption={{
                fontSize: 20,
                position: 'absolute',
                bottom: 0,
                width: '100%',
                heightPx: 80,
                lineHeight: 40,
                float: 'left',
                bgColor: 'rgba(0,0,0,0.7)',
                wordWrap: 'break-all',
                overflow: 'hidden'
            }}
            onClick={(event: any) => {
                if (onChangeName) {
                    event.stopPropagation();
                    event.preventDefault();
                    sdk.openStringDialog('Change name', name, (newName: string) => onChangeName(newName), 48, true)
                }
            }}
        >
            {name}
        </Container>
    </Container>;
});

const MediaEditor = observer(({ sdk }: any) => {
    const useLicense = sdk?.user?.account?.currentLicense || null;
    const mediaFiles = useLicense?.media?.allMedia || [];
    const mediaCapacity = useLicense?.config?.capacity?.media || 0;
    const freeMedia = mediaCapacity - mediaFiles.length;

    return <>
        <Container
            containerOption={{
                widthPx: 700,
                margin: [50, 0, 16, 10],
                fontSize: 32,
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff'
            }}
        >
            Media Editor
        </Container>
        <Container
            id={'wizard-body'}
            containerOption={{
                widthPx: 700,
                heightPx: 1000,
                margin: [16, 0, 0, 10]
            }}
            className={'settingarea'}
        >
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 980,
                    margin: [10, 0, 0, 10],
                    textAlign: 'center',
                    float: 'left'
                }}
            >
                <Container
                    containerOption={{
                        width: '100%',
                        height: '100%',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}
                >
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            margin: [16, 0, 0, 0],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 28,
                            fontWeight: 'bold'
                        }}
                    >
                        {`Manage your media files (${mediaFiles.length}/${mediaCapacity})`}
                    </Container>
                    {mediaCapacity > 0 ? (
                        <Container
                            containerOption={{
                                width: '100%',
                                heightPx: 840,
                                overflowX: 'hidden',
                                overflowY: 'auto'
                            }}
                        >
                            {mediaFiles.map((media: any, i: number) => {
                                return <MediaObject
                                    key={`media-${i}`}
                                    sdk={sdk}
                                    preview={media.mediaPreviewUrl}
                                    name={media.name}
                                    onDelete={() => sdk.user.deleteMedia(media)}
                                    onChangeName={(name: string) => sdk.user.updateMedia(media, name)}
                                    onClickContainer={() => {
                                        window.open(media.mediaUrl)
                                    }}
                                />
                            })}
                            {freeMedia > 0 ? Array.from({ length: freeMedia }, () => 0).map((emptyMedia: any, i: number) => {
                                return <MediaObject key={`media-free-${i}`} sdk={sdk} preview={'./data/icon/more.svg'} name={'Add media'} onDelete={null} onClickContainer={() => sdk.openMediaDialog(true)} />
                            }) : null}
                        </Container>
                    ) : <Container
                        containerOption={{
                            width: '100%',
                            heightPx: 840,
                            overflowX: 'hidden',
                            overflowY: 'auto'
                        }}
                        onClick={() => openWebsite(process.env.REACT_APP_WEBSITE || '')}
                    >
                        You need more media capacity? Click here to upgrade your license.<br /><b>{process.env.REACT_APP_WEBSITE || ''}</b>
                    </Container>}
                </Container>
            </Container>
        </Container>
        <ActionButton
            actionColor={'red'}
            onClick={() => sdk.openMainMenu()}
            buttonOption={{
                widthPx: 220,
                heightPx: 96,
                heightToWidth: undefined,
                margin: [16, 16, 16, 16]
            }}
            icon={'./data/icon/leave.svg'}
        >
            Back
        </ActionButton>
    </>
})

export default MediaEditor;