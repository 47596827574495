import { useState } from 'react';

import {
    observer
} from 'mobx-react-lite';
import { Container, IconButton, ActionButton, TextField, List } from '../../controls';
import ClockCommandoCentral from './ClockCommandoCentral';
import ClockEditor from './ClockEditor';
import { canShare, onShare } from '../../../utils/sharing';

const ClockEmpty = observer(({ sdk }: any) => {
    const [code, setCode] = useState(sdk?.latestClockTmp?.code || '');
    const [auth, setAuthCode] = useState(sdk?.latestClockTmp?.auth || '');
    const user = sdk.user;

    return <Container
        containerOption={{
            widthPx: 680,
            heightPx: 980,
            margin: [10, 0, 0, 10],
            textAlign: 'center'
        }}
    >
        {(user.loggedIn && user.account.currentLicense) ? (
            <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        float: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Current active tournaments
                </Container>
                <List
                    components={(user?.account?.sessionList || []).map((session: any) => (
                        <>
                            <ActionButton
                                id={'template-single-table'}
                                actionColor={'green'}
                                onClick={() => {
                                    sdk.addClock(session.code, session.auth)
                                }}
                                // icon={'./data/icon/template.svg'}
                                // iconOption={{
                                //     widthPx: 16,
                                //     heightPx: 16
                                // }}
                                buttonOption={{
                                    widthPx: 560,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    heightPx: 96,
                                    lineHeight: 80,
                                    fontSize: 26,
                                    heightToWidth: undefined,
                                    padding: [0, 8, 0, 8],
                                    margin: [16, 0, 16, 0],
                                    textAlign: 'center'
                                }}
                            >
                                {session.name}
                            </ActionButton>
                            {sdk.user.hasPermission('DELETE.SESSION') ? (
                                <IconButton
                                    id={'save'}
                                    source={'./data/icon/delete.svg'}
                                    onClick={() => sdk.openDeleteDialog('Really delete session', 'DELETE', () => sdk.user.deleteSession(session.code))}
                                    enableDelay={true}
                                    // disabled={!store.deletable}
                                    buttonOption={{
                                        widthPx: 64,
                                        heightPx: 64,
                                        margin: [32, 0, 8, 0],
                                        float: 'right',
                                        border: 'none'
                                    }}
                                />
                            ) : null}

                        </>
                    ))}
                    containerOption={{
                        widthPx: 680,
                        heightPx: 860,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    listOption={{
                        heightPx: 128,
                        widthPx: 660
                    }}
                />
            </>
        ) : (
            <>
                <Container
                    containerOption={{
                        margin: [10, 0, 0, 48]
                    }}
                >
                    <Container>
                        Clockcode
                    </Container>
                    <TextField
                        id={'clock-admin-code'}
                        upperCase={true}
                        value={code}
                        placeholder={'Enter code of your code'}
                        onInput={(v: any) => setCode(v)}
                        maxLength={4}
                        inputOption={{
                            widthPx: 500,
                            margin: [16, 0, 16, 0],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                    />
                    <Container>
                        Password
                    </Container>
                    <TextField
                        id={'clock-admin-authcode'}
                        upperCase={true}
                        value={auth}
                        onInput={(v: any) => setAuthCode(v)}
                        maxLength={12}
                        placeholder={'Enter password if required'}
                        inputOption={{
                            widthPx: 500,
                            margin: [16, 0, 16, 0],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                    />
                </Container>
                <ActionButton
                    actionColor={'green'}
                    onClick={() => sdk.addClock(code, auth)}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        heightToWidth: undefined,
                        margin: [128, 0, 16, 0]
                    }}
                >
                    Add clock
                </ActionButton>
            </>
        )}

    </Container>
});


const ClockAdmin = observer(({ sdk }: any) => {
    let useClock = sdk.currentClock;

    // {`${useClock.code} (${useClock.admin || '-'})`}
    return useClock?.adminEditor ? <ClockEditor
        sdk={sdk}
        clock={useClock}
        store={useClock.adminEditor}
    /> :
        <>
            <Container
                containerOption={{
                    widthPx: 700,
                    margin: [16, 0, 16, 10],
                    fontSize: 32,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#fff',
                    whiteSpace: 'nowrap'
                }}
            >
                {useClock?.h1Label || 'Clock Admin'}
            </Container>
            <Container
                id={'clockmenu'}
                containerOption={{
                    margin: [16, 0, 0, 10],
                    widthPx: 700,
                    heightPx: 1000,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    bgColor: 'rgba(0, 0, 0, 0.6)',
                    color: '#fff'
                }}
            >
                {useClock ?
                    <ClockCommandoCentral
                        sdk={sdk}
                    /> :
                    <ClockEmpty sdk={sdk} />}
            </Container>
            <ActionButton
                actionColor={'red'}
                onClick={() => sdk.leaveAdmin()}
                // buttonOption={{
                //     widthPx: 480,
                //     heightPx: 96,
                //     heightToWidth: undefined,
                //     margin: [16, 0, 16, 120]
                // }}
                buttonOption={{
                    widthPx: 240,
                    heightPx: 96,
                    lineHeight: 80,
                    heightToWidth: undefined,
                    margin: [16, 16, 16, 120],
                    padding: [0, 8, 0, 8],
                    textAlign: 'center',
                    float: 'left'
                }}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [16, 0, 0, 0]
                }}
                icon={'./data/icon/leave.svg'}
            >
                Leave
            </ActionButton>
            {(useClock && canShare()) ? (
                <ActionButton
                    actionColor={'green'}
                    onClick={() => onShare('Open the poker clock on your device', 'Open the poker clock on your device', useClock.code)}
                    // buttonOption={{
                    //     widthPx: 480,
                    //     heightPx: 96,
                    //     heightToWidth: undefined,
                    //     margin: [16, 0, 16, 120]
                    // }}
                    buttonOption={{
                        widthPx: 240,
                        heightPx: 96,
                        lineHeight: 80,
                        heightToWidth: undefined,
                        margin: [16, 16, 16, 16],
                        padding: [0, 8, 0, 8],
                        textAlign: 'center',
                        float: 'left'
                    }}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        margin: [16, 0, 0, 0]
                    }}
                    icon={'./data/icon/share.svg'}
                >
                    Share
                </ActionButton>
            ) : null}

        </>;

});

export default ClockAdmin;