import {
    observer
} from 'mobx-react-lite';
// import {
//     translateKey
// } from '../../../utils/helper'
import getSdk from '../../../api'

const TextField = observer(({
    id,
    inputOption,
    inputType = 'text',
    autoFocus = false,
    disabled = false,
    className = 'gameinput',
    store = undefined,
    storeProperty = undefined,
    upperCase = false,
    placeholder = '',
    maxLength = 64,
    value = '',
    numberCleanFloat = false,
    onInput,
    onInputFinished,
    onManipulateInput,
    onClick,
    readOnly
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    let useValue = value;

    if (store && storeProperty) {
        if (Array.isArray(storeProperty)) {
            useValue = store[storeProperty[0]][storeProperty[1]];
        } else {
            useValue = store[storeProperty];
        }
    }

    const propsInput = useStyles({
        padding: [16, 48, 16, 48],
        fontSize: 32,
        lineHeight: 32,
        widthPx: 240,
        ...(inputOption || {})
    });

    return <input
        id={id}
        type={inputType}
        className={className}
        value={useValue}
        style={propsInput}
        placeholder={placeholder}
        // placeholder={placeholder ? translateKey(placeholder) : placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        disabled={disabled}
        readOnly={!!readOnly}
        onInput={(event: any) => {
            let useInput = (event.target.value || '');
            if (upperCase) {
                useInput = useInput.toUpperCase();
            }

            if(numberCleanFloat) {
                if(!isNaN(Number(useInput))) {
                    useInput = Number(useInput).toFixed() || '0'
                } else {
                    useInput = '0'
                }
            }

            if(onManipulateInput) {
                useInput = onManipulateInput(useInput)
            }
            
            // Update Targetvalue
            if (useInput !== event.target.value) {
                event.target.innerHTML = useInput;
                event.target.value = useInput;
            }

            // Update the statepath
            if (store && storeProperty) {
                if (Array.isArray(storeProperty)) {
                    if (store?.updateArrayValue) {
                        store.updateArrayValue(storeProperty[0], storeProperty[1], useInput)
                    }
                } else {
                    if (store?.updateValue) {
                        store.updateValue(storeProperty, useInput)
                    }
                }
            }

            // Trigger the onInput callback if necessary
            if (onInput) {
                onInput(useInput);
            }

            // Event input finished
            if(onInputFinished) {
                onInputFinished();
            }
        }}
        onClick={onClick}
    />
});

export default TextField;