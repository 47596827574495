import {
  observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const ImageComponent = observer(({
  id,
  imageOption,
  className,
  source,
  onMouseDown,
  onTouchStart,
  onClick,
  onMouseUp,
  draggable,
  imageRef
}: any) => {
  const sdk = getSdk();
  const useStyles = sdk.layoutManager.styles

  const propsImage = useStyles({
    ...(imageOption || {})
  });

  return <img
    id={id}
    src={source}
    className={className}
    style={propsImage}
    alt={""}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onTouchStart={onMouseDown}
    onMouseUp={onMouseUp}
    onTouchEnd={onMouseUp}
    draggable={draggable}
    ref={imageRef}
  />
});

export default ImageComponent;