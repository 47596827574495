import {
    makeObservable,
    observable,
    runInAction,
    action,
    computed
} from 'mobx';


class PayoutLevel {
    public payoutLevel: number;
    public payoutString: string;
    public payoutEntries: string;

    constructor(lvl: number, entries: number) {
        makeObservable(this, {
            payoutString: observable,
            payoutEntries: observable,
            updateValue: action,
            updateEntries: action,
            valid: computed,
            config: computed,
            entries: computed
        });
        this.payoutString = '';
        this.payoutEntries = `${entries}`;
        this.payoutLevel = lvl;
    }

    public updateEntries(entries: number): void {
        this.payoutEntries = `${entries}`;
    }

    get entries() {
        return parseInt(this.payoutEntries || '0', 10)
    }

    get valid() {
        return this.entries >= 0 && this.entries <= 9999;
    }

    get config() {
        return [
            this.payoutEntries,
            this.payoutString
        ]
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

}

export default class PayoutStore {
    public adminMode: boolean;
    public activeLevel: number;
    public payoutLevel: Array<PayoutLevel>;
    public payoutRound: string;

    constructor(admin: boolean = false) {
        makeObservable(this, {
            isValid: computed,
            updateValue: action,
            load: action,
            addLevel: action,
            setTemplate: action,
            previousLevel: action,
            nextLevel: action,
            deleteLevel: action,
            activeLevel: observable,
            adminMode: observable,
            payoutLevel: observable,
            payoutRound: observable,
            changed: computed,
            config: computed,
            currentLevel: computed,
            levelDisplay: computed,
            hasNextLevel: computed,
            deletable: computed,
            hasPreviousLevel: computed,
            storeValues: computed
        });

        this.adminMode = admin;
        this.activeLevel = 0;
        this.payoutLevel = [new PayoutLevel(1, 0)];

        this.payoutRound = '10';
    }

    public load(clockData: any, password: string = "") {
        if (clockData?.config?.payouts) {
            this.setTemplate(clockData.config.payouts)
        }

        this.payoutRound = clockData?.config?.payoutRound === 0 ? '0' : (clockData?.config?.payoutRound || '10');

        // Save in comparison store
        sessionStorage.setItem('comparestore', this.storeValues)
    }

    public addLevel(): void {
        const currentLength: number = this.payoutLevel?.length || 0;
        if (currentLength < 50) {
            const level = new PayoutLevel(currentLength + 1, this.payoutLevel[currentLength - 1].entries + 1);
            this.payoutLevel.push(level);
            this.nextLevel();
        }
    }

    get hasNextLevel() {
        return this.activeLevel + 1 < this.payoutLevel.length;
    }

    get levelDisplay() {
        return `Level ${this.activeLevel + 1} / ${this.payoutLevel?.length || 0}`
    }

    get hasPreviousLevel() {
        return this.activeLevel > 0;
    }

    public previousLevel(): void {
        if (this.hasPreviousLevel) {
            this.activeLevel -= 1;
        }
    }

    public nextLevel(): void {
        if (this.hasNextLevel) {
            this.activeLevel += 1;
        }
    }

    public setTemplate(templateData: any = null): void {
        const templateInfo = templateData ? templateData : null;

        this.payoutLevel = [];

        templateInfo.forEach((lvl: any, i: number) => {
            this.payoutLevel.push(new PayoutLevel(i + 1, lvl[0]));
            this.payoutLevel[i].updateValue('payoutString', lvl[1])
        })

        this.activeLevel = 0;
    }

    public deleteLevel(): void {
        if (this.payoutLevel.length > 1) {
            this.payoutLevel = this.payoutLevel.filter((lvl: any, i: number) => i !== this.activeLevel);
            if (this.activeLevel >= this.payoutLevel.length) {
                this.activeLevel = this.payoutLevel.length - 1;
            }
        }
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    get currentLevel() {
        return this.payoutLevel[this.activeLevel]
    }

    get deletable() {
        return this.payoutLevel.length > 1;
    }

    get isValid() {
        const searchInvalidLevel = this.payoutLevel.find((lvl: PayoutLevel) => !lvl.valid)
        return !isNaN(parseFloat(this.payoutRound)) && !searchInvalidLevel;
    }

    get storeId() {
        return 'payout'
    }

    get storeValues() {
        return JSON.stringify({
            cfg: this.config,
            payoutRound: this.payoutRound
        });
    }

    get changed() {
        let compareStore = '';
        try {
            compareStore = sessionStorage.getItem('comparestore') || '';
        } catch (e) {
        }

        return this.storeValues !== compareStore;
    }

    get updatedConfig() {
        return {
            payouts: this.config,
            payoutRound: parseFloat(this.payoutRound || '10')
        }
    }

    get config() {
        return this.payoutLevel.map((lvl: any) => lvl.config)
    }
}