import {
    observer
} from 'mobx-react-lite';
import { TextField, Container, Checkbox } from '../../controls';

const PayEdit = observer(({ sdk, store }: any) => {

    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Amount of start player (optional)
            </Container>
            <TextField
                id={'general-h1-label'}
                maxLength={60}
                inputType={'number'}
                inputOption={{
                    widthPx: 500,
                    margin: [0, 0, 16, 50],
                    float: 'left',
                    borderSize: 2,
                    border: 'solid #fff'
                }}
                store={store}
                storeProperty={'countPlayer'}
            />
            <Checkbox
                id={'buyin'}
                store={store}
                storeProperty={'allowBuyIn'}
                label={'BuyIn'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.allowBuyIn ? <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    BuyIn Cash
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'buyInCash'}
                />
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    BuyIn Stack
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'buyInStack'}
                />
            </> : null}
            <Checkbox
                id={'rebuy'}
                store={store}
                storeProperty={'allowRebuy'}
                label={'Allow Re-Entry'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.allowRebuy ?
                <>
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        Re-Entry Cash
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={60}
                        inputType={'number'}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                        store={store}
                        storeProperty={'rebuyCash'}
                    />
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        Re-Entry Stack
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={60}
                        inputType={'number'}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                        store={store}
                        storeProperty={'rebuyStack'}
                    />
                </>
                : null}
            <Checkbox
                id={'rebuy'}
                store={store}
                storeProperty={'allowAddOn'}
                label={'Allow Add-On'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.allowAddOn ? <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Add-On Cash
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'addonCash'}
                />
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Add-On Stack
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'addonStack'}
                />
            </> : null}
            <Checkbox
                id={'rebuy'}
                store={store}
                storeProperty={'allowEarlyBird'}
                label={'Allow Earlybird'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.allowEarlyBird ? <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    EarlyBird Cash
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'earlybirdCash'}
                />
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    EarlyBird Stack
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'earlybirdStack'}
                />
            </> : null}
            <Checkbox
                id={'guruantee_pricepool'}
                store={store}
                storeProperty={'allowPrizepoolGuarantee'}
                label={'Guaranteed prize pool'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.allowPrizepoolGuarantee ? <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Guaranteed prize pool
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'prizepoolGuarantee'}
                />
            </> : null}
        </Container>
    </>;
});

export default PayEdit;