export const SOUNDS = [
    {
        value: 'dedim.mp3',
        label: 'Dedim',
        name: 'Dedim'
    },
    {
        value: 'gong.mp3',
        label: 'Gong',
        name: 'Gong'
    },
    {
        value: 'levelup.mp3',
        label: 'Level-Up 1',
        name: 'Level-Up 1'
    },
    {
        value: 'levelup2.mp3',
        label: 'Level-Up 2',
        name: 'Level-Up 2'
    },
    {
        value: 'levelup3.mp3',
        label: 'Level-Up 3',
        name: 'Level-Up 3'
    },
    {
        value: 'triangle.mp3',
        label: 'Triangle',
        name: 'Triangle'
    }
]