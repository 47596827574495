import {
    observer
} from 'mobx-react-lite';
import { Container, ImageComponent } from '../controls';
import { buildMediaUrlFromString } from '../../utils/helper';

const ClockH1Element = observer(({
    clock
}: any) => {
    const useElementStyle = clock?.style?.['h1title'] || {};
    const useLogoConfig = clock?.style?.layout?.logoConfig || {};
    const useLogoRightConfig = clock?.style?.layout?.logoRightConfig || {};

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 110,
            lineHeight: 110,
            float: 'left',
            color: useElementStyle?.color || '#fff',
            textAlign: 'center',
            fontWeight: useElementStyle?.fontWeight || 'bold',
            bgColor: useElementStyle?.bgColor || undefined,
            // bgColor: '#ff00ff',
            fontSize: useElementStyle?.fontSize || 80
        }}
    >
        {clock.logo ? (
            <ImageComponent
                source={buildMediaUrlFromString(clock.logo)}
                imageOption={{
                    position: 'absolute',
                    heightPx: 84,
                    left: 0,
                    margin: [14, 0, 0, 14],
                    ...(useLogoConfig || {})
                }}
            />
        ) : clock?.premiumClock ? null : (
            <ImageComponent
                source={'./data/bp_qr.png'}
                imageOption={{
                    position: 'absolute',
                    heightPx: 90,
                    left: 0,
                    margin: [10, 0, 0, 60],
                    ...(useLogoConfig || {})
                }}
            />
        )}
        {clock.h1Label}
        {clock.logoRight ? (
            <ImageComponent
                // source={'https://testing.couchgames.wtf/bulletspoker.png'}
                source={clock.logoRight}
                imageOption={{
                    position: 'absolute',
                    heightPx: 84,
                    right: 0,
                    margin: [10, 60, 0, 0],
                    ...(useLogoRightConfig || {})
                }}
            />
        ) : null}
    </Container>
});

const ClockH2Element = observer(({
    clock
}: any) => {
    const useElementStyle = clock?.style?.['h2title'] || {};

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 42,
            lineHeight: 42,
            float: 'left',
            color: useElementStyle?.color || '#fff',
            textAlign: 'center',
            // bgColor: 'rgba(0, 0, 0, 0.4)',
            bgColor: useElementStyle?.bgColor || 'rgba(0, 0, 0, 0.4)',
            fontWeight: useElementStyle?.fontWeight || undefined,
            fontSize: useElementStyle?.fontSize || 30
        }}
    >
        {clock.h2Label}
    </Container>
});

const ClockAvgStack = observer(({
    id,
    clock,
    property,
    height,
    fontSize,
    fontSizeTop,
    fontSizeBottom,
    lineHeight,
    lineHeightTop,
    lineHeightBottom,
    bgColor,
    containerOption,
    fontWeight,
    disabled,
    color = '#fff'
}: any) => {
    const useElementStyle = clock?.style?.[id || ''] || {};
    const useLayoutMainConfig = clock?.style?.layout?.[id || ''] || {};

    let useProperty = clock?.[property];
    if (useProperty !== '') {
        useProperty = useProperty || property
    }
    return <Container
        containerOption={{
            heightPx: height,
            width: '100%',
            bgColor: useElementStyle?.bgColor || bgColor,
            float: 'left',
            color: useElementStyle?.color || color,
            ...(containerOption || {}),
            ...(useLayoutMainConfig || {})
        }}
    >
        <Container
            containerOption={{
                width: '100%',
                height: '20%',
                float: 'left',
                lineHeight: lineHeightTop,
                fontSize: fontSizeTop || 46
            }}
        >
            Next level at
        </Container>
        <Container
            containerOption={{
                width: '100%',
                height: '60%',
                float: 'left',
                lineHeight: lineHeight,
                fontSize: useElementStyle?.fontSize || fontSize
            }}
        >
            {clock.displayNextAverageStack}
        </Container>
        <Container
            containerOption={{
                width: '100%',
                height: '20%',
                float: 'left',
                lineHeight: lineHeightBottom,
                fontSize: fontSizeBottom || 28
            }}
        >
            avg. Stack
        </Container>
    </Container>
})

const ClockMainElement = observer(({
    id,
    clock,
    property,
    height,
    containerOption,
    fontSize,
    bgColor,
    fontWeight,
    disabled,
    color = '#fff'
}: any) => {
    const useElementStyle = clock?.style?.[id || ''] || {};
    const useLayoutMainConfig = clock?.style?.layout?.[id || ''] || {};

    let useProperty = clock?.[property];
    if (useProperty !== '') {
        useProperty = useProperty || property
    }
    return <Container
        containerOption={{
            width: '100%',
            heightPx: height,
            lineHeight: height,
            bgColor: useElementStyle?.bgColor || bgColor,
            fontSize: useElementStyle?.fontSize || fontSize,
            fontWeight: useElementStyle?.fontWeight || fontWeight,
            color: useElementStyle?.color || color,
            float: 'left',
            ...(containerOption || {}),
            ...(useLayoutMainConfig || {})
        }}
    >
        {disabled ? null : useProperty}
    </Container>
})

const ClockSideElement = observer(({ clock, id, title, property, disabled, heightSum, fntLineHeight, fntSize, forceAlign = null, forceMargin = null }: any) => {
    const useElementStyle = clock?.style?.[id || ''] || {};
    const useElementStyleHead = clock?.style?.[id ? `${id}_head` : ''] || {};
    const useElementStyleBody = clock?.style?.[id ? `${id}_body` : ''] || {};

    let useProperty = clock?.[property];
    let hasLineBreak = false;

    if (useProperty !== '') {
        useProperty = useProperty || property
    }

    if (useProperty.includes('\n')) {
        hasLineBreak = true;
    }

    return <Container
        containerOption={{
            width: '100%',
            heightPx: 112 * (heightSum || 1),
            float: 'left',
            bgColor: useElementStyle?.bgColor || undefined
        }}
    >
        {disabled ? null : (
            <>
                <Container
                    containerOption={{
                        heightPx: 42,
                        lineHeight: useElementStyleHead?.lineHeight || 42,
                        margin: [16, 0, 0, 0],
                        fontSize: useElementStyleHead?.fontSize || useElementStyle?.fontSize || 30,
                        color: useElementStyleHead?.color || useElementStyle?.color || undefined,
                        bgColor: useElementStyleHead?.bgColor || undefined
                    }}
                >
                    {title}
                </Container>
                <Container
                    containerOption={{
                        heightPx: 38 * (heightSum || 1),
                        lineHeight: useElementStyleBody?.lineHeight || fntLineHeight || 38,
                        fontSize: useElementStyleBody?.fontSize || useElementStyle?.fontSize || fntSize || 26,
                        color: useElementStyleBody?.color || useElementStyle?.color || '#c6c6c6',
                        bgColor: useElementStyleBody?.bgColor || undefined,
                        textAlign: forceAlign || useElementStyleBody?.align || 'center',
                        margin: forceMargin || useElementStyleBody?.margin || [0, 0, 0, 0]
                    }}
                >
                    {hasLineBreak ? useProperty.split('\n').map((val: any, ni: any) => {
                        return <span key={`payout-${ni}`} style={{ color: useElementStyleBody?.color || useElementStyle?.color || '#c6c6c6' }}>
                            {val}
                            <br />
                        </span>
                    }) : useProperty}
                </Container>
            </>
        )}

    </Container>
})

export const ClockViewRotated = observer(({ sdk }: any) => {
    // const marginRight = sdk.layoutManager.canvasMarginRight;

    return <Container
        containerOption={{
            width: '100%',
            height: '100%',
            margin: [0, 0, 0, 0]
            // margin: [0, 0, 0, marginRight]
        }}
    >
        <Container
            className={'rotation-wrapper-outer'}
        >
            <Container
                className={'rotation-wrapper-inner'}
            >
                <Container
                    id={'content'}
                    className={'div-rotated'}
                    containerOption={{
                        widthPx: 1280,
                        heightPx: 720
                    }}
                >
                    <ClockCanvas sdk={sdk} />
                </Container>
            </Container>
        </Container>
    </Container>
})

export const ClockCanvas = observer(({ sdk }: any) => {
    const useClock = sdk?.watchClock;

    if (useClock?.streamEnabled) {
        return <ClockViewStream sdk={sdk} />;
    }
    if (useClock?.advertising && useClock?.break && useClock?.advertisingImage) {
        return <ClockViewWithAds sdk={sdk} />;
    }
    return <ClockView sdk={sdk} />;
});

export const ClockViewStream = observer(({ sdk }: any) => {
    const useClock = sdk?.watchClock;
    const useLeftView = useClock?.style?.['leftView'] || {};
    const useClockLayoutLeft = useClock?.style?.layout?.right || [
        'pricepool',
        'payout',
        'avgstack'
    ];

    return useClock ? <Container
        containerOption={{
            // bgColor: 'rgba(0, 0, 0, 0.2)',
            width: '100%',
            height: '100%'
        }}
        onClick={() => sdk.closeClock()}
    >
        <Container
            id={'topper'}
            containerOption={{
                width: '100%',
                heightPx: 100,
                lineHeight: 100,
                float: 'left',
                color: '#fff',
                // color: useElementStyle?.color || '#fff',
                textAlign: 'center',
                // fontWeight: useElementStyle?.fontWeight || 'bold',
                // bgColor: useElementStyle?.bgColor || undefined,
                // bgColor: '#ff00ff',
                bgColor: 'rgba(0, 0, 0, 0.4)'
                // fontSize: useElementStyle?.fontSize || 80
            }}
        >
            <ClockMainElement
                clock={useClock}
                id={'level'}
                property={'displayCurrentLevelShort'}
                containerOption={{
                    height: '100%',
                    width: undefined,
                    widthPx: 220,
                    // bgColor: 'red',
                    fontWeight: 'bold'
                }}
                fontSize={70}
                disabled={useClock?.break}
            />
            {useClock?.break ? (
                <ClockMainElement
                    clock={useClock}
                    id={'break'}
                    property={'Break'}
                    containerOption={{
                        height: '100%',
                        width: undefined,
                        widthPx: 512,
                        // color: '#c6c6c6',
                        // bgColor: 'green',
                        fontWeight: 'bold'
                    }}
                    fontSize={70}
                />
            ) : (
                <ClockMainElement
                    clock={useClock}
                    id={'blinds'}
                    property={'currentBlindsInK'}
                    containerOption={{
                        height: '100%',
                        width: undefined,
                        widthPx: 512,
                        // color: '#c6c6c6',
                        // bgColor: 'green',
                        fontWeight: 'bold'
                    }}
                    fontSize={70}
                />
            )}
            <ClockMainElement
                clock={useClock}
                id={'ante'}
                property={'currentAnteType'}
                containerOption={{
                    height: '100%',
                    width: undefined,
                    widthPx: 290,
                    // color: '#c6c6c6',
                    // bgColor: 'orange',
                    fontWeight: 'bold'
                }}
                fontSize={70}
            />
            {(useClock.isMattMode && !useClock.break) ?
                <ClockAvgStack
                    clock={useClock}
                    id={'time'}
                    property={'timeString'}
                    fontSize={32}
                    height={100}
                    fontSizeTop={20}
                    fontSizeBottom={20}
                    lineHeight={60}
                    lineHeightTop={20}
                    lineHeightBottom={20}
                    containerOption={{
                        // height: '100%',
                        // heightPx: undefined,
                        width: undefined,
                        widthPx: 256,
                        // color: '#c6c6c6',
                        // bgColor: 'yellow',
                        fontWeight: 'bold'
                    }}
                />
                :
                <ClockMainElement
                    clock={useClock}
                    id={'time'}
                    property={'timeString'}
                    // bgColor={'rgba(0, 0, 0, 0.4)'}
                    containerOption={{
                        height: '100%',
                        width: undefined,
                        widthPx: 256,
                        // color: '#c6c6c6',
                        // bgColor: 'yellow',
                        fontWeight: 'bold'
                    }}
                    fontSize={70}
                />
            }
        </Container>
        <Container
            id={'sub-topper'}
            containerOption={{
                width: '100%',
                heightPx: 40,
                lineHeight: 40,
                float: 'left',
                // color: useElementStyle?.color || '#fff',
                textAlign: 'center',
                bgColor: 'rgba(0, 0, 0, 0.3)'
                // fontWeight: useElementStyle?.fontWeight || 'bold',
                // bgColor: useElementStyle?.bgColor || undefined,
                // bgColor: '#ee00ff',
                // fontSize: useElementStyle?.fontSize || 80
            }}
        >
            <ClockMainElement
                clock={useClock}
                id={'player'}
                property={'displayPlayerHorizontal'}
                // bgColor={'rgba(0, 0, 0, 0.4)'}
                containerOption={{
                    height: '100%',
                    width: undefined,
                    widthPx: 288,
                    color: '#c6c6c6',
                    // bgColor: 'yellow',
                    fontWeight: 'bold',
                    fontSize: 26
                }}
            />
            <ClockMainElement
                clock={useClock}
                id={'nextblind'}
                property={'displayNextBlindsWithKFormat'}
                disabled={useClock.nextBlinds === '0 / 0'}
                containerOption={{
                    height: '100%',
                    width: undefined,
                    widthPx: 380,
                    color: '#c6c6c6',
                    // color: '#c6c6c6',
                    // bgColor: 'orange',
                    fontWeight: 'bold',
                    fontSize: 26
                }}
            />
            <ClockMainElement
                clock={useClock}
                id={'h1Label'}
                property={'h1Label'}
                containerOption={{
                    height: '100%',
                    width: undefined,
                    widthPx: 360,
                    color: '#c6c6c6',
                    // bgColor: 'green',
                    fontWeight: 'bold',
                    fontSize: 26
                }}
            />
            <ClockMainElement
                clock={useClock}
                id={'message'}
                property={'timeNextBreakOneLine'}
                disabled={useClock?.break}
                containerOption={{
                    height: '100%',
                    width: undefined,
                    widthPx: 250,
                    color: '#c6c6c6',
                    // bgColor: 'red',
                    fontWeight: 'bold',
                    fontSize: 26
                }}
            />
        </Container>
        <Container
            id={'h2-topper'}
            containerOption={{
                width: '100%',
                heightPx: 40,
                lineHeight: 40,
                float: 'left',
                bgColor: 'rgba(0, 0, 0, 0.3)',
                // color: useElementStyle?.color || '#fff',
                textAlign: 'center',
                // fontWeight: useElementStyle?.fontWeight || 'bold',
                // bgColor: useElementStyle?.bgColor || undefined,
                // bgColor: '#ff00ff',
                // fontSize: useElementStyle?.fontSize || 80
            }}
        >
            <ClockMainElement
                clock={useClock}
                id={'h2Label'}
                property={'h2Label'}
                containerOption={{
                    height: '100%',
                    color: '#c6c6c6',
                    // color: '#c6c6c6',
                    // bgColor: 'green',
                    fontWeight: 'bold',
                    fontSize: 28
                }}
            />
        </Container>
        <Container
            id={'left-container'}
            containerOption={{
                widthPx: 318,
                height: '100%',
                float: 'left',
                color: useLeftView?.color || '#fff',
                bgColor: 'rgba(0, 0, 0, 0.2)' || undefined,
                textAlign: 'center',
                fontWeight: 'bold',
            }}
        >
            {useClockLayoutLeft.map((element: string, i: number) => {
                switch (element) {
                    case 'pricepool':
                        return useClock.showPricePool ? (
                            <ClockSideElement
                                clock={useClock}
                                id={'pricepool'}
                                title={'Prize pool'}
                                property={'displayPricePool'}
                                key={`right-element-${i}`}
                            />
                        ) : null;
                    case 'addon':
                        return useClock.showAddOn ? (
                            <ClockSideElement
                                clock={useClock}
                                id={'addon'}
                                title={'Add-On'}
                                property={'displayAddon'}
                                key={`right-element-${i}`}
                            />
                        ) : null;
                    case 'avgstack':
                        return (useClock.showBuyIn || useClock.showRebuy || useClock.showEarlyBird || useClock.showAddOn) && useClock.averageStack !== 0 ? (<ClockSideElement
                            id={'avgstack'}
                            title={'Average Stack'}
                            clock={useClock}
                            property={'displayAverageStack'}
                            key={`left-element-${i}`}
                        />) : null;
                    case 'payout':
                        return useClock.showPayout ? (
                            <ClockSideElement
                                clock={useClock}
                                id={'payout'}
                                title={'Payouts'}
                                fntSize={22}
                                fntLineHeight={30}
                                heightSum={3}
                                forceAlign={'left'}
                                forceMargin={[0, 0, 0, 16]}
                                property={'displayPayout'}
                                key={`right-element-${i}`}
                            />
                        ) : null;
                    case 'payout3':
                        return useClock.showPayout ? (
                            <ClockSideElement
                                clock={useClock}
                                id={'payout'}
                                title={'Payouts'}
                                fntSize={22}
                                fntLineHeight={30}
                                heightSum={3}
                                forceAlign={'left'}
                                forceMargin={[0, 0, 0, 16]}
                                property={'displayPayout'}
                                key={`right-element-${i}`}
                            />
                        ) : null;
                    case 'payout2':
                        return useClock.showPayout ? (
                            <ClockSideElement
                                clock={useClock}
                                id={'payout'}
                                title={'Payouts'}
                                fntSize={22}
                                fntLineHeight={30}
                                heightSum={2}
                                forceAlign={'left'}
                                forceMargin={[0, 0, 0, 16]}
                                property={'displayPayout'}
                                key={`right-element-${i}`}
                            />
                        ) : null;
                    default:
                        break;
                }
                return null;
            })}
        </Container>
        <Container
            id={'stream-container'}
            containerOption={{
                position: 'relative',
                widthPx: 960,
                heightPx: 540,
                lineHeight: 40,
                float: 'left',
                overflow: 'none',
                // color: useElementStyle?.color || '#fff',
                textAlign: 'center',
                // fontWeight: useElementStyle?.fontWeight || 'bold',
                // bgColor: useElementStyle?.bgColor || undefined,
                // bgColor: '#ff00ff',
                // fontSize: useElementStyle?.fontSize || 80
            }}
        >
            <iframe
                src={useClock.streamUrl}
                title={'Livestream'}
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'none',
                    border: 0
                }}
            />
            {useClock.premiumClock ? null : (
                <Container
                    containerOption={{
                        position: 'absolute',
                        opacity: 0.5,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        bgImage: 'url(./data/watermark.png)',
                        bgImageSize: 'contain',
                        pointerEvents: 'none'
                    }}
                />
            )}
        </Container>
    </Container>
        : null
});


const ClockViewWithAds = observer(({ sdk }: any) => {
    const useClock = sdk?.watchClock;
    return useClock ? <Container
        containerOption={{
            // bgColor: 'rgba(0, 0, 0, 0.2)',
            width: '100%',
            height: '100%'
        }}
        onClick={() => sdk.closeClock()}
    >
        <ClockH1Element clock={useClock} />
        <ClockH2Element clock={useClock} />
        <Container
            containerOption={{
                width: '100%',
                heightPx: 568,
                float: 'left',
                color: '#fff',
                textAlign: 'center',
                fontWeight: 'bold'
            }}
        >
            <Container
                containerOption={{
                    width: '20%',
                    height: '100%',
                    float: 'left',
                    color: '#fff',
                    bgColor: undefined,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    // bgColor: 'red'
                }}
            >
            </Container>
            <Container
                containerOption={{
                    width: '60%',
                    height: '100%',
                    float: 'left',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#fff',
                    bgColor: undefined,
                }}
            >
                <ClockMainElement
                    clock={useClock}
                    id={'timebreak'}
                    property={'timeBreakString'}
                    height={60}
                    color={'#fff'}
                    fontWeight={'bold'}
                    fontSize={50}
                />
                {/* <ClockMainElement
                    clock={useClock}
                    id={'time'}
                    property={'timeString'}
                    height={48}
                    // bgColor={'rgba(0, 0, 0, 0.4)'}
                    fontWeight={'bold'}
                    fontSize={40}
                /> */}
                <Container
                    containerOption={{
                        // bgColor: 'red',
                        bgImage: `url(${buildMediaUrlFromString(useClock.advertisingImage)})`,
                        bgRepeat: 'no-repeat',
                        bgPosition: 'center center',
                        bgImageSize: 'contain',
                        width: '100%',
                        heightPx: 432,
                        float: 'left'
                    }}
                    className={useClock.advertisingAnimation === 0 ? 'slide-in-right':'slide-in-right2'}
                >
            </Container>

            <ClockMainElement
                clock={useClock}
                id={'nextblind'}
                property={'displayNextBlinds'}
                disabled={useClock.nextBlinds === '0 / 0'}
                height={80}
                color={'#fff'}
                fontWeight={'bold'}
                fontSize={40}
            />
        </Container>
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                float: 'left',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff',
                bgColor: undefined,
            }}
        >
        </Container>
    </Container>
    </Container > : null;
});

const ClockView = observer(({ sdk }: any) => {
    const useClock = sdk?.watchClock;
    const useLeftView = useClock?.style?.['leftView'] || {};
    const useMiddleView = useClock?.style?.['middleView'] || {};
    const useRightView = useClock?.style?.['rightView'] || {};

    const useClockLayoutLeft = useClock?.style?.layout?.left || [
        'player',
        'rebuy',
        'totalchips',
        'avgstack',
        'nextbreak'
    ];
    const useClockLayoutRight = useClock?.style?.layout?.right || [
        'pricepool',
        'payout'
    ];
    const useClockShowMiddleBreak = useClock?.style?.layout?.nextBreakMain || false;

    return useClock ? <Container
        containerOption={{
            // bgColor: 'rgba(0, 0, 0, 0.2)',
            width: '100%',
            height: '100%'
        }}
        onClick={() => sdk.closeClock()}
    >
        <ClockH1Element clock={useClock} />
        <ClockH2Element clock={useClock} />
        <Container
            containerOption={{
                width: '100%',
                heightPx: 568,
                float: 'left',
                color: '#fff',
                textAlign: 'center',
                fontWeight: 'bold'
            }}
        >
            <Container
                containerOption={{
                    width: '20%',
                    height: '100%',
                    float: 'left',
                    color: useLeftView?.color || '#fff',
                    bgColor: useLeftView?.bgColor || undefined,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    // bgColor: 'red'
                }}
            >
                {useClockLayoutLeft.map((element: string, i: number) => {
                    switch (element) {
                        case 'player':
                            return useClock.showPlayer ? (
                                <ClockSideElement
                                    id={'player'}
                                    title={'Players'}
                                    clock={useClock}
                                    property={'displayPlayer'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'rebuy':
                            return useClock.showRebuy ? (
                                <ClockSideElement
                                    id={'rebuy'}
                                    title={'Re-Entries'}
                                    clock={useClock}
                                    property={'displayReEntry'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'totalchips':
                            return (useClock.showBuyIn || useClock.showRebuy || useClock.showEarlyBird || useClock.showAddOn) && useClock.chipsInPlay !== 0 ? (
                                <ClockSideElement
                                    id={'totalchips'}
                                    title={'Chips in Play'}
                                    clock={useClock}
                                    property={'displayChipsInPlay'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'avgstack':
                            return (useClock.showBuyIn || useClock.showRebuy || useClock.showEarlyBird || useClock.showAddOn) && useClock.averageStack !== 0 ? (<ClockSideElement
                                id={'avgstack'}
                                title={'Average Stack'}
                                clock={useClock}
                                property={'displayAverageStack'}
                                key={`left-element-${i}`}
                            />) : null;
                        case 'nextbreak':
                            return useClock.hasBreak ? (
                                <ClockSideElement
                                    id={'nextbreak'}
                                    clock={useClock}
                                    title={'Break in'}
                                    disabled={useClock?.break}
                                    property={'timeNextBreak'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        case 'earlybird':
                            return useClock.showEarlyBird ? (
                                <ClockSideElement
                                    id={'player'}
                                    title={'Early Bird'}
                                    clock={useClock}
                                    property={'displayEarlyBird'}
                                    key={`left-element-${i}`}
                                />
                            ) : null;
                        default:
                            break;
                    }
                    return null;
                })}
            </Container>
            <Container
                containerOption={{
                    width: '60%',
                    height: '100%',
                    float: 'left',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: useMiddleView?.color || '#fff',
                    bgColor: useMiddleView?.bgColor || undefined,
                }}
            >
                <ClockMainElement
                    clock={useClock}
                    id={'level'}
                    property={'displayCurrentLevel'}
                    height={64}
                    fontSize={28}
                    disabled={useClock?.break}
                />
                {useClock?.break ? (
                    <ClockMainElement
                        clock={useClock}
                        id={'break'}
                        property={'Break'}
                        height={96}
                        color={'#fff'}
                        fontWeight={'bold'}
                        fontSize={80}
                    />
                ) : (
                    <ClockMainElement
                        clock={useClock}
                        id={'blinds'}
                        property={'currentBlinds'}
                        height={96}
                        color={'#c6c6c6'}
                        fontWeight={'bold'}
                        fontSize={80}
                    />
                )}

                <ClockMainElement
                    clock={useClock}
                    id={'ante'}
                    property={'displayAnte'}
                    color={'#c6c6c6'}
                    height={32}
                    fontSize={24}
                />
                {(useClock.isMattMode && !useClock.break) ?
                    <ClockAvgStack
                        clock={useClock}
                        id={'time'}
                        property={'timeString'}
                        height={256}
                        bgColor={'rgba(0, 0, 0, 0.4)'}
                        fontWeight={'bold'}
                        fontSize={128}
                    /> :
                    <ClockMainElement
                        clock={useClock}
                        id={'time'}
                        property={'timeString'}
                        height={256}
                        bgColor={'rgba(0, 0, 0, 0.4)'}
                        fontWeight={'bold'}
                        fontSize={128}
                    />}

                {useClockShowMiddleBreak && useClock.hasBreak ? (
                    <ClockMainElement
                        clock={useClock}
                        id={'message'}
                        property={'timeNextBreakOneLine'}
                        disabled={useClock?.break}
                        height={32}
                        fontSize={24}
                    />
                ) : (
                    <ClockMainElement
                        clock={useClock}
                        id={'message'}
                        property={''}
                        height={32}
                        fontWeight={'bold'}
                        fontSize={40}
                    />
                )}
                <ClockMainElement
                    clock={useClock}
                    id={'nextblind'}
                    property={'displayNextBlinds'}
                    disabled={useClock.nextBlinds === '0 / 0'}
                    height={96}
                    color={'#fff'}
                    fontWeight={'bold'}
                    fontSize={40}
                />
            </Container>
            <Container
                containerOption={{
                    width: '20%',
                    height: '100%',
                    float: 'left',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: useRightView?.color || '#fff',
                    bgColor: useRightView?.bgColor || undefined,
                }}
            >
                {useClockLayoutRight.map((element: string, i: number) => {
                    switch (element) {
                        case 'pricepool':
                            return useClock.showPricePool ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'pricepool'}
                                    title={'Prize pool'}
                                    property={'displayPricePool'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'addon':
                            return useClock.showAddOn ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'addon'}
                                    title={'Add-On'}
                                    property={'displayAddon'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'payout':
                            return useClock.showPayout ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'payout'}
                                    title={'Payouts'}
                                    fntSize={22}
                                    fntLineHeight={30}
                                    heightSum={4}
                                    forceAlign={'left'}
                                    forceMargin={[0, 0, 0, 16]}
                                    property={'displayPayout'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'payout3':
                            return useClock.showPayout ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'payout'}
                                    title={'Payouts'}
                                    fntSize={22}
                                    fntLineHeight={30}
                                    heightSum={3}
                                    forceAlign={'left'}
                                    forceMargin={[0, 0, 0, 16]}
                                    property={'displayPayout'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        case 'payout2':
                            return useClock.showPayout ? (
                                <ClockSideElement
                                    clock={useClock}
                                    id={'payout'}
                                    title={'Payouts'}
                                    fntSize={22}
                                    fntLineHeight={30}
                                    heightSum={2}
                                    forceAlign={'left'}
                                    forceMargin={[0, 0, 0, 16]}
                                    property={'displayPayout'}
                                    key={`right-element-${i}`}
                                />
                            ) : null;
                        default:
                            break;
                    }
                    return null;
                })}
            </Container>
        </Container>
    </Container> : null;
});

export default ClockView;