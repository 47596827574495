
import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react'
import { TextArea, Container, TextField, ActionButton, IconButton } from '../../controls';
import { PAYOUT_TEMPLATES } from '../../../constants/payouttemplates';




const PayoutEdit = observer(({ sdk, store }: any) => {
    const [templateView, setTemplateView] = useState(false);

    return <>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                float: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Setup Payout
        </Container>
        {templateView ? (
            <>
                <ActionButton
                    id={'template-single-table'}
                    actionColor={'green'}
                    onClick={() => {
                        store.setTemplate('singletable');
                        setTemplateView(false);
                    }}
                    // disabled={useStore?.isValid ? false : true}
                    icon={'./data/icon/template.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Single Table ( 8-10 Player )
                </ActionButton>
                <ActionButton
                    actionColor={'red'}
                    onClick={() => setTemplateView(false)}
                    icon={'./data/icon/leave.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Back
                </ActionButton>
            </>
        ) : (
            <>
                <ActionButton
                    id={'load-payout'}
                    actionColor={'orange'}
                    enableDelay={null}
                    // onClick={() => setTemplateView(true)}
                    onClick={() => {
                        sdk.templateStore.openTemplate(
                            'payout',
                            (data: any) => store.setTemplate(data),
                            PAYOUT_TEMPLATES
                        )
                    }}
                    icon={'./data/icon/load.svg'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 80,
                        lineHeight: 64,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Use Payout template
                </ActionButton>
                <Container
                    containerOption={{
                        widthPx: 620,
                        heightPx: 96,
                        margin: [16, 0, 0, 32]
                    }}
                >
                    <IconButton
                        id={'delete'}
                        source={'./data/icon/delete.svg'}
                        onClick={() => store.deleteLevel()}
                        enableDelay={false}
                        disabled={!store.deletable}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            margin: [16, 0, 8, 16],
                            float: 'left',
                            border: 'none'
                        }}
                    />

                    <Container
                        containerOption={{
                            widthPx: 320,
                            float: 'left',
                            textAlign: 'center',
                            margin: [0, 0, 0, 80]
                        }}
                    >
                        {store.deletable ? (<>
                            <Container
                                containerOption={{
                                    fontSize: 20
                                }}
                            >
                                Entries
                            </Container>
                            <TextField
                                id={'payout-entries-label'}
                                maxLength={60}
                                inputType={'number'}
                                inputOption={{
                                    widthPx: 128,
                                    heightPx: 32,
                                    margin: [0, 0, 0, 48],
                                    float: 'left',
                                    borderSize: 2,
                                    border: 'solid #fff'
                                }}
                                store={store.currentLevel}
                                storeProperty={'payoutEntries'}
                            />
                        </>) : null}

                    </Container>
                    <IconButton
                        id={'save'}
                        source={'./data/icon/diskette.svg'}
                        onClick={() => sdk.templateStore.saveTemplate(sdk, 'payout', store.config)}
                        enableDelay={false}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            margin: [16, 16, 8, 16],
                            float: 'right',
                            border: 'none'
                        }}
                    />
                </Container>
                <Container>
                    <Container
                        containerOption={{
                            fontSize: 24
                        }}
                    >
                        {store.levelDisplay}
                    </Container>
                    <IconButton
                        source={'./data/icon/previous.png'}
                        buttonOption={{
                            widthPx: 80,
                            heightPx: 80,
                            float: 'left',
                            margin: [220, 0, 0, 0]
                        }}
                        disabled={!store.hasPreviousLevel}
                        onClick={() => store.previousLevel()}
                    />

                    <TextArea
                        id={'txtarea-entries'}
                        inputOption={{
                            float: 'left',
                            widthPx: 480,
                            heightPx: 480,
                            padding: [0, 0, 0, 0],
                            margin: [0, 16, 0, 16]
                        }}
                        store={store.currentLevel}
                        storeProperty={'payoutString'}
                    />
                    <IconButton
                        source={`./data/icon/${store.hasNextLevel ? 'next' : 'add'}.png`}
                        buttonOption={{
                            widthPx: 80,
                            heightPx: 80,
                            float: 'left',
                            margin: [220, 0, 0, 0]
                        }}
                        disabled={false}
                        onClick={() => store.hasNextLevel ? store.nextLevel() : store.addLevel()}
                    />
                </Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Incrementals
                </Container>
                <TextField
                    id={'general-h1-label'}
                    maxLength={60}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'payoutRound'}
                />
            </>
        )}

    </>
})

export default PayoutEdit;