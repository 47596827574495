import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react'
import { List, Container, Checkbox, ActionButton, TextField, IconButton } from '../../controls';

const truncateText = (words: string, maxlength: number) => {
    return words.length < maxlength ? words : `${words.slice(0, maxlength)}...`
}

const RoleCreator = observer(({ sdk, onButtonBack }: any) => {
    const store = sdk.user.account.roleForm;

    return <>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                // margin: [16, 0, 0, 64],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            E-Mail
        </Container>
        <Container>
            <TextField
                id={'mail-label'}
                maxLength={80}
                inputType={'text'}
                disabled={!store.isNewRole}
                inputOption={{
                    widthPx: store.isNewRole ? 500 : 420,
                    margin: [0, 0, 16, 50],
                    float: 'left',
                    borderSize: 2,
                    border: 'solid #fff'
                }}
                store={store}
                storeProperty={'mail'}
            />
            {(!store.isNewRole &&
                (sdk.user.hasPermission('DELETE.ROLE'))
            ) ? (
                <IconButton
                    id={'save'}
                    source={'./data/icon/delete.svg'}
                    onClick={() => {
                        sdk.openDeleteDialog('Delete Role?', 'DELETE', () => {
                            sdk.user.deleteRole(store.mail, store.role).then((success: boolean) => {
                                if (success) {
                                    onButtonBack();
                                    sdk.user.fetchRoleList();
                                }
                            })
                        })
                    }}
                    enableDelay={true}
                    disabled={sdk.user.account.mail === store.mail}
                    buttonOption={{
                        widthPx: 64,
                        heightPx: 64,
                        margin: [8, 0, 0, 0],
                        float: 'right',
                        border: 'none'
                    }}
                />
            ) : null}
        </Container>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                margin: [16, 0, 16, 0],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Permission
        </Container>
        {sdk.user.hasPermission('SUPERADMIN.ALL') ? (
            <Checkbox
                id={'superadmin'}
                store={store}
                storeProperty={'roleSuperAdmin'}
                label={'SuperAdmin'}
                containerOption={{
                    float: 'left',
                    widthPx: 170,
                    margin: [0, 0, 0, 48]
                }}
                labelOption={{
                    widthPx: 100
                }}
            />
        ) : null}
        {sdk.user.hasPermission('ADMIN.ALL') ? (
            <Checkbox
                id={'admin'}
                store={store}
                storeProperty={'roleAdmin'}
                label={'Admin'}
                containerOption={{
                    float: 'left',
                    widthPx: 170,
                    margin: [0, 0, 0, 48]
                }}
                labelOption={{
                    widthPx: 100
                }}
            />
        ) : null}
        <Checkbox
            id={'custom'}
            store={store}
            storeProperty={'roleCustom'}
            label={'Custom'}
            containerOption={{
                float: 'left',
                widthPx: 170,
                margin: [0, 0, 0, 48]
            }}
            labelOption={{
                widthPx: 100
            }}
        />
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                overflow: 'hidden',
                bgColor: '#4f4f4f'
            }}
        >
            <Container
                containerOption={{
                    width: '80%',
                    height: '100%',
                    float: 'left',
                    fontSize: 28
                }}
            >
                {'Permission'}
            </Container>
            <Container
                containerOption={{
                    width: '20%',
                    height: '100%',
                    float: 'left',
                    fontSize: 28
                }}
            >
                {''}
            </Container>
        </Container>
        <List
            components={sdk.user.roles.availableRolePermission.map((perm: any) => (
                <>
                    <Container
                        containerOption={{
                            width: '80%',
                            height: '100%',
                            float: 'left',
                            fontSize: 28,
                            opacity: store.hasPermission(perm) ? 1.0 : 0.4
                        }}
                    >
                        {perm}
                    </Container>
                    <Container
                        containerOption={{
                            width: '20%',
                            height: '100%',
                            float: 'left',
                            fontSize: 28
                        }}
                    >
                        <Checkbox
                            id={'perm-check'}
                            onChange={() => store.hasPermission(perm) ? store.removePermission(perm) : store.addPermission(perm)}
                            value={store.hasPermission(perm)}
                            disabled={true}
                            containerOption={{
                                float: 'left',
                                widthPx: 440,
                                margin: [20, 0, 0, 48]
                            }}
                            labelOption={{
                                widthPx: 310
                            }}
                        />
                    </Container>
                </>
            ))}
            containerOption={{
                widthPx: 680,
                heightPx: 400,
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            listOption={{
                heightPx: 80,
                widthPx: 680
            }}
        />
        <ActionButton
            actionColor={'red'}
            onClick={() => onButtonBack()}
            // disabled={useStore?.isValid ? false : true}
            icon={'./data/icon/load.svg'}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
            buttonOption={{
                float: 'left',
                widthPx: 240,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 30,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [32, 0, 16, 64],
                textAlign: 'center'
            }}
        >
            Cancel
        </ActionButton>
        <ActionButton
            actionColor={'green'}
            onClick={() => {
                if (store.isNewRole) {
                    sdk.user.createRole().then((created: boolean) => {
                        if (created) {
                            onButtonBack();
                            sdk.user.fetchRoleList();
                        }
                    })
                } else {
                    sdk.user.updateRole().then((created: boolean) => created === true ? onButtonBack() : null)
                }
            }}
            disabled={!store.isValid}
            icon={'./data/icon/load.svg'}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
            buttonOption={{
                float: 'left',
                widthPx: 240,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 30,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [32, 0, 16, 64],
                textAlign: 'center'
            }}
        >
            {store.isNewRole ? 'Create' : 'Save'}
        </ActionButton>
    </>
});

const RoleOverview = observer(({ onButtonNew, openNew, roleList, sdk }: any) => {
    return <>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                // margin: [16, 0, 0, 64],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Role Overview ({roleList.length}/{sdk.user?.account?.currentLicense?.config?.capacity?.roles})
        </Container>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                overflow: 'hidden',
                bgColor: '#4f4f4f'
            }}
        >
            <Container
                containerOption={{
                    width: '60%',
                    height: '100%',
                    float: 'left',
                    fontSize: 28
                }}
            >
                {'User'}
            </Container>
            <Container
                containerOption={{
                    width: '25%',
                    height: '100%',
                    float: 'left',
                    fontSize: 28
                }}
            >
                {'Role'}
            </Container>
            <Container
                containerOption={{
                    width: '15%',
                    height: '100%',
                    float: 'left',
                    fontSize: 28
                }}
            >

            </Container>
        </Container>
        <List
            components={roleList.map((role: any) => (
                <>
                    <Container
                        containerOption={{
                            width: '60%',
                            height: '100%',
                            float: 'left',
                            fontSize: 28,
                        }}
                    >
                        <Container
                            containerOption={{
                                heightPx: 60,
                                lineHeight: 60,
                                fontSize: 28
                            }}
                        >
                            {role.user.nickname}
                        </Container>
                        <Container
                            containerOption={{
                                heightPx: 60,
                                lineHeight: 60,
                                fontSize: 24
                            }}
                        >
                            {truncateText(role.user.email, 22)}
                        </Container>

                    </Container>
                    <Container
                        containerOption={{
                            width: '25%',
                            height: '100%',
                            float: 'left',
                            fontSize: 22,
                            lineHeight: 128,
                        }}
                    >
                        {role.role}
                    </Container>
                    <Container
                        containerOption={{
                            width: '15%',
                            height: '100%',
                            float: 'left',
                            fontSize: 28
                        }}
                    >
                        {sdk.user.account.mail !== role.user.email ? (
                            <IconButton
                                source={'./data/icon/pencil.svg'}
                                buttonOption={{
                                    widthPx: 48,
                                    heightPx: 48,
                                    float: 'right',
                                    margin: [40, 24, 0, 0]
                                }}
                                onClick={() => {
                                    sdk.user.fetchRoleForUser(null, role.user.email).then((result: any) => {
                                        if (result) {
                                            sdk.user.account.openRoleForm(result);
                                            openNew();
                                        }
                                    });
                                }}
                            />
                        ) : null}
                    </Container>
                </>
            ))}
            containerOption={{
                widthPx: 680,
                heightPx: 720,
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            listOption={{
                heightPx: 128,
                widthPx: 680,
                borderSize: 2,
                borderBottom: 'solid #e5e5e5'
            }}
        />
        <ActionButton
            actionColor={'green'}
            onClick={() => onButtonNew()}
            disabled={
                roleList.length >= sdk.user?.account?.currentLicense?.config?.capacity?.roles
            }
            icon={'./data/icon/load.svg'}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
            buttonOption={{
                widthPx: 480,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 30,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [16, 0, 16, 0],
                textAlign: 'center'
            }}
        >
            New role
        </ActionButton>
    </>
});

const RoleEditor = observer(({ sdk }: any) => {
    const [view, setView] = useState('index');
    const roleList = sdk.user.account?.currentLicense?.allRoles || [];

    return <>
        <Container
            containerOption={{
                widthPx: 700,
                margin: [50, 0, 16, 10],
                fontSize: 32,
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff'
            }}
        >
            Role Editor
        </Container>
        <Container
            id={'wizard-body'}
            containerOption={{
                widthPx: 700,
                heightPx: 1000,
                margin: [16, 0, 0, 10]
            }}
            className={'settingarea'}
        >
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 980,
                    margin: [10, 0, 0, 10],
                    textAlign: 'center',
                    float: 'left'
                }}
            >

                <Container
                    containerOption={{
                        width: '100%',
                        height: '100%',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}
                >
                    {view === 'index' ? <RoleOverview onButtonNew={() => {
                        sdk.user.account.openRoleForm();
                        setView('new');
                    }} openNew={() => setView('new')} roleList={roleList} sdk={sdk} /> : null}
                    {view === 'new' ? <RoleCreator sdk={sdk} onButtonBack={() => setView('index')} /> : null}
                </Container>
            </Container>
        </Container>
        <ActionButton
            actionColor={'red'}
            onClick={() => sdk.openMainMenu()}
            buttonOption={{
                widthPx: 220,
                heightPx: 96,
                heightToWidth: undefined,
                margin: [16, 16, 16, 16]
            }}
            icon={'./data/icon/leave.svg'}
        >
            Back
        </ActionButton>
    </>
})

export default RoleEditor;