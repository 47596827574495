import {
    makeObservable,
    observable,
    action,
    computed,
    toJS
} from 'mobx';

/**
 * Structure
 * [ EventId, EventType ( 0 = Sound ), EventValue = '0']
 */
export default class EventStore {

    public eventList: Array<any>;

    public adminMode: boolean;

    constructor(admin: boolean = false) {
        makeObservable(this, {
            eventList: observable,
            updateValue: action,
            load: action,
            reset: action,
            setEvent: action,
            adminMode: observable,
            isValid: computed,
            config: computed,
            storeValues: computed,
            changed: computed
        });

        this.adminMode = admin;
        this.eventList = [];
    }

    public load(clockData: any) {

        this.eventList = clockData?.config?.events || [];

        // Save in comparison store
        sessionStorage.setItem('comparestore', this.storeValues)
    }

    public getEventValue(eventName: string) {
        return this.eventList.find((event:any) => event[0] === eventName)?.[2] || null;
    }

    public setEvent(eventName: string, eventType: string, eventValue: any): void {
        const eventListCopy = toJS(this.eventList);
        let newEventList: any = [];
        if(eventValue === '') {
            this.eventList = this.eventList.filter((event:any) => event[0] !== eventName);
            return;
        }
        if (eventListCopy.find((event: any) => event[0] === eventName)) {
            newEventList = eventListCopy.map((event: any) => {
                if (event[0] === eventName) {
                    return [eventName, eventType, eventValue]
                }
                return event;
            })
        } else {
            newEventList = eventListCopy.map(x => x);
            newEventList.push([eventName, eventType, eventValue])
        }

        // Sort the event list
        newEventList = newEventList.sort((a: any, b: any) => b[0] - a[0])
        
        if (JSON.stringify(eventListCopy) !== JSON.stringify(newEventList)) {
            this.eventList = newEventList;
        }
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    get storeValues() {
        return JSON.stringify([
            this.eventList
        ]);
    }

    get changed() {
        let compareStore = '';
        try {
            compareStore = sessionStorage.getItem('comparestore') || '';
        } catch (e) {
        }
        return this.storeValues !== compareStore;
    }

    // Reset the store
    public reset(): void {
        this.eventList = [];
    }

    get isValid() {
        return true;
    }

    get updatedConfig() {
        return {
            events: this.eventList
        };
    }

    get config() {
        return this.eventList;
    }

    get storeId() {
        return 'event';
    }
}