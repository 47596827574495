import {
    makeObservable,
    observable,
    runInAction,
    action,
    computed
} from 'mobx';
import User from '../user';

export default class TemplateStore {

    public activeTemplate: any;

    public filename: string;

    constructor() {
        makeObservable(this, {
            activeTemplate: observable,
            filename: observable,
            saveTemplate: action,
            openTemplate: action,
            reset: action,
            updateValue: action,
            title: computed,
            isSaveAs: computed,
            active: computed
        });
        this.filename = '';
        this.activeTemplate = null;
    }

    public deleteTemplate(sdk: any, templateType: string, templateId: string): void {
        const that = this;
        sdk.openDeleteDialog('Really delete template?', 'DELETE', () => {
            sdk.user.deleteTemplate(templateType, templateId).then((success: boolean) => {
                if (success) {
                    that.reset()
                }
            })
        })
    }

    public saveTemplate(sdk: any, type: string, config: any): void {
        const useLicense = sdk.user?.account?.currentLicense;
        const that = this;
        if (useLicense) {
            const tmpList: any = useLicense.getTemplateList(type);
            // Case 1: No capacity is used at the moment
            if (tmpList.free > 0 && tmpList.used === 0) {
                sdk.openStringDialog('Save as', '', (name: string) => {
                    sdk.user.saveTemplate(type, name, config)
                }, 32);

                // Case 2: We already have templates. Try to save it
            } else {
                this.activeTemplate = {
                    type,
                    screen: 'save',
                    free: tmpList.free > 0,
                    freeCount: tmpList.free,
                    usedCount: tmpList.used,
                    totalCount: tmpList.total,
                    cbSave: (fileName: string | null = null, templateId: string | null = null) => {
                        sdk.openStringDialog('Save as', fileName || '', (name: string) => {
                            if (fileName && templateId) {
                                sdk.user.updateTemplate(type, templateId, config, fileName === name ? '' : name)
                                    .then((success: boolean) => {
                                        if (success) {
                                            that.reset()
                                        }
                                    })
                            } else {
                                sdk.user.saveTemplate(type, name, config)
                                    .then((success: boolean) => {
                                        if (success) {
                                            that.reset()
                                        }
                                    })
                            }
                        }, 32, false, {
                            allowSameValue: true
                        });
                    }
                }
            }
        } else {
            sdk.openMessageDialog(
                'Free Account required',
                'You can only save templates with an active free account',
                null,
                null,
                'account'
            )
        }
    }


    public openTemplate(type: string, cbLoad: any, defaultTemplates: any = [], screen: string = 'load'): void {
        this.activeTemplate = {
            type,
            screen,
            cbLoad,
            defaultTemplates
        }
    }

    public reset(): void {
        this.activeTemplate = null;
    }

    public updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    get title() {
        if (this.isSaveAs) {
            return `Save Template [${this.activeTemplate?.type || ''}] (${this.activeTemplate.usedCount}/${this.activeTemplate.totalCount})`
        }
        return `Load Template [${this.activeTemplate?.type || ''}]`
    }

    get isSaveAs() {
        return this.activeTemplate?.screen === 'save'
    }

    get active() {
        return !!this.activeTemplate;
    }

}