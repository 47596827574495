import {
    observer
} from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import {
    Container,
    IconButton,
    TextField
} from '../controls/index';

const NumberDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk?.dialog?.type === 'number' ? sdk.dialog : null;
    const dialogTitle = dialog?.title || '';
    const dialogMaxValue = dialog?.maxValue || 99999999;

    const [numberValue, setNumberValue] = useState(dialog?.value || 0);


    useEffect(() => {
        setNumberValue(dialog?.value ? dialog.value : 0)
    }, [dialog])

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_number_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_number_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    zIndex: 60,
                    bgColor: '#062860',
                    margin: [(1280 - 362) / 2, 0, 0, (720 - 640) / 2]
                }}
            >
                <Container
                    id={'gamedialog_number_message'}
                    containerOption={{
                        widthPx: 620,
                        heightPx: 48,
                        color: '#fff',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        role: 'alert'
                    }}
                >
                    {dialogTitle}
                </Container>
                <Container
                    containerOption={{
                        widthPx: 620,
                        heightPx: 32,
                        color: '#fff',
                        fontSize: 32,
                        float: 'left',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 32, 10],
                        role: 'alert'
                    }}
                >
                    <TextField
                        inputType={'number'}
                        onInput={(v: any) => {
                            let inputValue = null;
                            try {
                                inputValue = parseInt(v, 10)
                            } catch (e) {
                                inputValue = null;
                            }
                            if(v === '') {
                                inputValue = 0;
                            }
                    
                            if ((inputValue || inputValue === 0) && inputValue >= 0) {
                                if(inputValue > dialogMaxValue) {
                                    inputValue = dialogMaxValue;
                                }
                                setNumberValue(inputValue)
                            }
                        }}
                        value={`${numberValue}`}
                    />
                </Container>
                <IconButton
                    source={'./data/icon/no.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    onClick={() => {
                        sdk.closeDialog()
                    }}
                />
                <IconButton
                    source={'./data/icon/yes.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    disabled={numberValue === dialog.value}
                    onClick={() => {
                        if(dialog?.cbYes) {
                            dialog.cbYes(numberValue)
                        }
                        sdk.closeDialog();
                    }}
                />

            </Container>

        </div>
    </div > : null;
});

export default NumberDialog;