import {
  observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const List = observer(({
  id,
  components,
  containerOption,
  filterNullComponents,
  listOption,
  vertical,
  onClick,
  clickIds,
  listRef
}: any) => {
  const sdk = getSdk();
  const useStyles = sdk.layoutManager.styles

  const propsUl = useStyles({
    margin: [0, 0, 0, 0],
    textAlign: 'center',
    heightPx: 96,
    ...(containerOption || {})
  });

  const propsLi = useStyles({
    float: vertical ? undefined : 'left',
    widthPx: 320,
    heightPx: 96,
    lineHeight: 96,
    ...(listOption || {})
  });

  return <ul 
  id={id} 
  style={propsUl}
  ref={listRef}
  >{components.filter((comp:any) => filterNullComponents && comp === null ? false : true).map((comp: any, nEntry: number) => (
    <li
      id={`${id}-${nEntry}`}
      key={`${id}-${nEntry}`}
      style={propsLi}
      onClick={(event) => onClick ? onClick(clickIds ? clickIds[nEntry] : event) : null}
    >
      {comp}
    </li>
  ))}</ul>
});

export default List;