import {
    observer
} from 'mobx-react-lite';
import getSdk from '../../../api'

const SelectButton = observer(({
    id,
    options,
    selectOption,
    selected = '',
    onChange
    // valueOption
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles

    const propsSelect = useStyles({
        widthPx: 360,
        heightPx: 64,
        bgColor: '#808080',
        color: '#fff',
        fontSize: 24,
        float: 'left',
        margin: [0,0,0,160],
        ...(selectOption || {})
    });

    // const propsOption = useStyles({
    //     heightPx: 128,
    //     bgColor: '#b1b1b1',
    //     color: '#fff',
    //     fontSize: 24,
    //     ...(valueOption || {})
    // });

    return <select
        id={id}
        style={propsSelect}
        onChange={(e: any) => onChange ? onChange(e.target.value) : null}
    >
        {(options || []).map((option: any, i:any) => option.value === selected ? <option key={`${option.label}${i}`} value={option.value} selected>{option.label}</option> :
            <option key={`${option.label}${i}`} value={option.value}>{option.label}</option>)}
    </select>
});

export default SelectButton;