import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    ImageComponent,
    ActionButton,
    Icon,
    IconButton
} from '../../controls';
import { openMail, CLOCK_URL, openWebsite } from '../../../utils/urls';
import { onShare } from '../../../utils/sharing';
import QRCode from 'react-qr-code';


const ClockAdminTime = ({ sdk, useClock }: any) => {
    return <>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                // margin: [16, 0, 0, 64],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Time
        </Container>
        <ActionButton
            actionColor={'red'}
            onClick={() => useClock.skipTimeClock(-10)}
            buttonOption={{
                widthPx: 80,
                heightPx: 80,
                heightToWidth: undefined,
                margin: [8, 16, 16, 16],
                padding: [0, 0, 0, 10],
                lineHeight: 64,
                fontSize: 0
            }}
        >
            <Icon
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    padding: [0, 0, 0, 0],
                    margin: [0, 0, 0, 0]
                }}
                source={'./data/icon/prev10.svg'}
            />
        </ActionButton>
        <ActionButton
            actionColor={'red'}
            onClick={() => useClock.skipTimeClock(-5)}
            buttonOption={{
                widthPx: 80,
                heightPx: 80,
                heightToWidth: undefined,
                margin: [8, 16, 16, 16],
                padding: [0, 0, 0, 10],
                lineHeight: 64,
                fontSize: 0
            }}
        >
            <Icon
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    padding: [0, 0, 0, 0],
                    margin: [0, 0, 0, 0]
                }}
                source={'./data/icon/prev5.svg'}
            />
        </ActionButton>
        <ActionButton
            actionColor={'red'}
            onClick={() => useClock.skipTimeClock(-1)}
            buttonOption={{
                widthPx: 80,
                heightPx: 80,
                heightToWidth: undefined,
                margin: [8, 16, 16, 16],
                padding: [0, 0, 0, 10],
                lineHeight: 64,
                fontSize: 0
            }}
        >
            <Icon
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    padding: [0, 0, 0, 0],
                    margin: [0, 0, 0, 0]
                }}
                source={'./data/icon/prev1.svg'}
            />
        </ActionButton>
        <ActionButton
            actionColor={'green'}
            onClick={() => useClock.skipTimeClock(1)}
            buttonOption={{
                widthPx: 80,
                heightPx: 80,
                heightToWidth: undefined,
                margin: [8, 16, 16, 16],
                padding: [0, 0, 0, 10],
                lineHeight: 64,
                fontSize: 0
            }}
        >
            <Icon
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    padding: [0, 0, 0, 0],
                    margin: [0, 0, 0, 0]
                }}
                source={'./data/icon/skip1.svg'}
            />
        </ActionButton>
        <ActionButton
            actionColor={'green'}
            onClick={() => useClock.skipTimeClock(5)}
            buttonOption={{
                widthPx: 80,
                heightPx: 80,
                heightToWidth: undefined,
                margin: [8, 16, 16, 16],
                padding: [0, 0, 0, 10],
                lineHeight: 64,
                fontSize: 0
            }}
        >
            <Icon
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    padding: [0, 0, 0, 0],
                    margin: [0, 0, 0, 0]
                }}
                source={'./data/icon/skip5.svg'}
            />
        </ActionButton>
        <ActionButton
            actionColor={'green'}
            onClick={() => useClock.skipTimeClock(10)}
            buttonOption={{
                widthPx: 80,
                heightPx: 80,
                heightToWidth: undefined,
                margin: [8, 16, 16, 16],
                padding: [0, 0, 0, 10],
                lineHeight: 64,
                fontSize: 0
            }}
        >
            <Icon
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    padding: [0, 0, 0, 0],
                    margin: [0, 0, 0, 0]
                }}
                source={'./data/icon/skip10.svg'}
            />
        </ActionButton>


    </>
};

const ClockAdminParameter = ({ sdk, clock, title, id, parameter, permissionRequired }: any) => {
    const hasPermission = permissionRequired ? sdk?.user?.hasPermission(permissionRequired, true) || false : true;
    return hasPermission ? <Container
        containerOption={{
            float: 'left',
            width: '100%',
            heightPx: 192,
            borderSize: 2,
            borderTop: 'solid #9e9e9e'
        }}>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            {title}
        </Container>
        <Container>
            <ActionButton
                actionColor={'red'}
                onClick={() => clock.changeCount(id, 'sub')}
                buttonOption={{
                    widthPx: 80,
                    heightPx: 80,
                    heightToWidth: undefined,
                    margin: [16, 16, 16, 16],
                    // padding: [0, 0, 0, 0],
                    lineHeight: 64,
                    fontSize: 64
                }}
            >
                <Icon
                    iconOption={{
                        widthPx: 32,
                        heightPx: 32,
                        padding: [0, 0, 0, 0],
                        margin: [0, 0, 0, 0]
                    }}
                    source={'./data/icon/sub.png'}
                />
            </ActionButton>
            {parameter ? (
                <ActionButton
                    actionColor={'black'}
                    onClick={() => sdk.openNumberDialog(title, clock[parameter], (v: number) => clock.changeCount(id, 'set', v), 9999)}
                    buttonOption={{
                        widthPx: 80,
                        heightPx: 80,
                        heightToWidth: undefined,
                        margin: [16, 16, 16, 16],
                        // padding: [0, 0, 0, 0],
                        lineHeight: 64,
                        fontSize: 0
                    }}
                >
                    <Icon
                        iconOption={{
                            widthPx: 32,
                            heightPx: 32,
                            padding: [0, 0, 0, 0],
                            margin: [0, 0, 0, 0]
                        }}
                        source={'./data/icon/keyboard.png'}
                    />
                    {/* T */}
                </ActionButton>
            ) : null}
            <ActionButton
                actionColor={'green'}
                onClick={() => clock.changeCount(id, 'add')}
                buttonOption={{
                    widthPx: 80,
                    heightPx: 80,
                    heightToWidth: undefined,
                    margin: [16, 16, 16, 16],
                    // padding: [0, 0, 0, 0],
                    lineHeight: 64,
                    fontSize: 64
                }}
            >
                <Icon
                    iconOption={{
                        widthPx: 32,
                        heightPx: 32,
                        padding: [0, 0, 0, 0],
                        margin: [0, 0, 0, 0]
                    }}
                    source={'./data/icon/add.png'}
                />
            </ActionButton>
        </Container>
    </Container> : null;
}

const ClockCommandoStart = observer(({ sdk }: any) => {
    const useClock = sdk.currentClock;

    return <>
        <ActionButton
            actionColor={'green'}
            onClick={() => useClock.startTournament()}
            buttonOption={{
                lineHeight: 80,
                widthPx: 280,
                heightPx: 96,
                heightToWidth: undefined,
                fontSize: 28,
                padding: [0, 8, 0, 0],

                margin: [16, 16, 16, 32]
            }}
            icon={'./data/icon/playbutton.svg'}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
        >
            Start Clock
        </ActionButton>
        <ActionButton
            actionColor={'orange'}
            onClick={() => sdk.loadClock(useClock.code, useClock)}
            buttonOption={{
                lineHeight: 80,
                widthPx: 280,
                heightPx: 96,
                heightToWidth: undefined,
                fontSize: 28,
                padding: [0, 8, 0, 0],

                margin: [16, 16, 16, 32]
            }}
            icon={'./data/icon/tv.svg'}
            iconOption={{
                widthPx: 48,
                heightPx: 48,
                margin: [16, 8, 0, 8],
                bgSize: 'contain'
            }}
        >
            Show Clock
        </ActionButton>
    </>
});

const ClockCommandoOverview = observer(({ sdk }: any) => {
    const useClock = sdk?.currentClock;
    const useLicense = sdk?.user?.account?.currentLicense || null;
    const adCount = useLicense?.config?.capacity?.ads || 0;
    console.log('Ad Capacity', useLicense?.config?.capacity)
    return <>
        {/* <Container
            containerOption={{
                width: '100%',
                textAlign: 'center',
                fontSize: 28,
                fontWeight: 'bold',
                margin: [32, 0, 0, 0]
            }}
        >
            <u>How To:</u> Connect your clock
        </Container> */}
        <Container
            containerOption={{
                margin: [0, 0, 32, 0],
                float: 'left',
                bgColor: 'rgba(120,190,255,0.3)'
            }}
        >
            {/* <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                Clock Url: Open clock in browser
            </Container>
            <Container
                containerOption={{
                    heightPx: 32,
                    lineHeight: 32,
                    widthPx: 680,
                    float: 'left',
                    fontSize: 22
                }}
            >
                You can connect your clock to a TV or a second screen
            </Container> */}
            {useClock.connectHint ? <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Scan now with your Smartphone
                    <IconButton
                        id={'delete-view'}
                        source={'./data/icon/minimize.svg'}
                        onClick={() => useClock.hideConnectHint()}
                        enableDelay={true}
                        // disabled={!store.deletable}
                        buttonOption={{
                            widthPx: 48,
                            heightPx: 48,
                            margin: [8, 8, 8, 0],
                            float: 'right',
                            border: 'none'
                        }}
                    />
                </Container>
                <Container
                    containerOption={{
                        heightPx: 32,
                        lineHeight: 32,
                        widthPx: 680,
                        float: 'left',
                        fontSize: 22
                    }}
                >
                    to start and controll the clock
                </Container>
                <Container
                    containerOption={{
                        width: '100%',
                        heightPx: 280,
                        float: 'left'
                    }}
                >
                    <Container
                        containerOption={{
                            widthPx: 268,
                            heightPx: 268,
                            bgColor: '#fff',
                            float: 'left',
                            margin: [6, 0, 0, 200]
                        }}
                    >
                        <Container
                            containerOption={{
                                widthPx: 228,
                                heightPx: 228,
                                float: 'left',
                                margin: [20, 0, 0, 20]
                            }}
                        >
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={`${CLOCK_URL}?code=${useClock.code}&token=${useClock.authtoken}`}
                                viewBox={`0 0 256 256`}
                            />
                        </Container>
                    </Container>
                </Container>
            </> : null}
            <Container
                onClick={() => onShare('Open the poker clock on your device', 'Open the poker clock on your device', useClock.code)}
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 22,
                    borderSize: 2,
                    borderBottom: 'dotted #fff'
                }}
            >
                <u>{`${CLOCK_URL}?code=${useClock.code}`}</u>
                {useClock.connectHint ? null : <IconButton
                    id={'delete-view'}
                    source={'./data/icon/maximize.svg'}
                    onClick={(event: any) => {
                        event.stopPropagation();
                        event.preventDefault();
                        useClock.showConnectHint();
                    }}
                    enableDelay={true}
                    // disabled={!store.deletable}
                    buttonOption={{
                        widthPx: 48,
                        heightPx: 48,
                        margin: [8, 8, 8, 0],
                        float: 'right',
                        border: 'none'
                    }}
                />}

            </Container>
        </Container>
        {/* <Container>
            <ActionButton
                actionColor={'orange'}
                onClick={() => sdk.loadClock(useClock.code, useClock)}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 280,
                    heightPx: 96,
                    heightToWidth: undefined,
                    fontSize: 24,
                    padding: [0, 8, 0, 0],

                    margin: [16, 16, 16, 32]
                }}
                icon={'./data/icon/tv.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
            >
                Connect Admin
            </ActionButton>
            <ActionButton
                actionColor={'orange'}
                onClick={() => sdk.loadClock(useClock.code, useClock)}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 280,
                    heightPx: 96,
                    heightToWidth: undefined,
                    fontSize: 28,
                    padding: [0, 8, 0, 0],

                    margin: [16, 16, 16, 32]
                }}
                icon={'./data/icon/tv.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
            >
                Connect TV
            </ActionButton>
        </Container> */}
        <Container
            containerOption={{
                width: '100%',
                textAlign: 'center',
                fontSize: 32,
                fontWeight: 'bold',
                margin: [32, 0, 0, 0]
            }}
        >
            Clock Admin - Code:  {useClock.code}
        </Container>
        {useClock?.tournamentStarted ? <Container
            containerOption={{
                widthPx: 680,
                // heightPx: 980,
                margin: [10, 0, 0, 0],
                textAlign: 'center'
            }}
        >
            {useClock.running ? (
                <ActionButton
                    actionColor={'red'}
                    onClick={() => useClock.pauseClock()}
                    buttonOption={{
                        lineHeight: 80,
                        widthPx: 280,
                        heightPx: 96,
                        heightToWidth: undefined,
                        fontSize: 28,
                        padding: [0, 8, 0, 0],

                        margin: [16, 16, 16, 32]
                    }}
                    icon={'./data/icon/pausebutton.svg'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        margin: [16, 8, 0, 8],
                        bgSize: 'contain'
                    }}
                >
                    Pause Clock
                </ActionButton>
            ) : (
                <ActionButton
                    actionColor={'green'}
                    onClick={() => useClock.resumeClock()}
                    buttonOption={{
                        lineHeight: 80,
                        widthPx: 280,
                        heightPx: 96,
                        heightToWidth: undefined,
                        fontSize: 28,
                        padding: [0, 8, 0, 0],

                        margin: [16, 16, 16, 32]
                    }}
                    icon={'./data/icon/playbutton.svg'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        margin: [16, 8, 0, 8],
                        bgSize: 'contain'
                    }}
                >
                    Resume Clock
                </ActionButton>
            )}
            <ActionButton
                actionColor={'orange'}
                onClick={() => sdk.loadClock(useClock.code, useClock)}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 280,
                    heightPx: 96,
                    heightToWidth: undefined,
                    fontSize: 28,
                    padding: [0, 8, 0, 0],

                    margin: [16, 16, 16, 32]
                }}
                icon={'./data/icon/tv.svg'}
                iconOption={{
                    widthPx: 48,
                    heightPx: 48,
                    margin: [16, 8, 0, 8],
                    bgSize: 'contain'
                }}
            >
                Show Clock
            </ActionButton>
            <Container
                containerOption={{
                    bgColor: 'rgba(145, 145, 145, 0.1)'
                }}
            >
                <ClockAdminTime sdk={sdk} useClock={useClock} />
            </Container>
            <Container
                id={'admin.parameter'}
                containerOption={{
                    margin: [16, 0, 0, 0]
                }}
            >
                <ClockAdminParameter
                    id={'playercount'}
                    permissionRequired={'CLOCK.PLAYER'}
                    parameter={'countPlayer'}
                    title={'Players'}
                    clock={useClock}
                    sdk={sdk}
                />
                {useClock.showRebuy ? (<ClockAdminParameter
                    id={'rebuycount'}
                    permissionRequired={'CLOCK.REBUY'}
                    parameter={'countRebuy'}
                    title={'Re-Entries'}
                    clock={useClock}
                    sdk={sdk}
                />) : null}
                {useClock.showEarlyBird ? (<ClockAdminParameter
                    id={'earlybirdcount'}
                    permissionRequired={'CLOCK.EARLY'}
                    parameter={'countEarlybird'}
                    title={'Earlybird'}
                    clock={useClock}
                    sdk={sdk}
                />) : null}
                {useClock.showAddOn ? <ClockAdminParameter
                    id={'addoncount'}
                    parameter={'countAddon'}
                    permissionRequired={'CLOCK.ADDON'}
                    title={'Add-On'}
                    clock={useClock}
                    sdk={sdk}
                /> : null}
                <ClockAdminParameter
                    id={'bustedcount'}
                    parameter={'countBusted'}
                    permissionRequired={'CLOCK.BUSTED'}
                    title={'Busted'}
                    clock={useClock}
                    sdk={sdk}
                />
            </Container>
            <Container>
                <ActionButton
                    actionColor={'red'}
                    onClick={() => sdk.openDeleteDialog('Really reset time?', 'RESET', () => {
                        useClock.resetTime()
                    })}
                    buttonOption={{
                        widthPx: 160,
                        heightPx: 96,
                        heightToWidth: undefined,
                        margin: [16, 16, 16, 16]
                    }}
                >
                    Reset Time
                </ActionButton>
            </Container>
        </Container> :
            <ClockCommandoStart
                sdk={sdk}
            />}
        <Container
            containerOption={{
                bgColor: 'rgba(145, 145, 145, 0.1)'
            }}
        >
            <Container
                containerOption={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 28,
                    fontWeight: 'bold',
                    margin: [32, 0, 0, 0]
                }}
            >
                Edit Pokerclock
            </Container>
            <ActionButton
                actionColor={'green'}
                icon={'./data/icon/general.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
                onClick={() => useClock.editGeneral()}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 480,
                    fontSize: 28,
                    heightPx: 96,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 0],
                    margin: [16, 0, 16, 32]
                }}
            >
                Edit General
            </ActionButton>
            <ActionButton
                actionColor={'green'}
                onClick={() => useClock.editBlindlevel()}
                icon={'./data/icon/blind.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64,
                }}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 480,
                    fontSize: 28,
                    heightPx: 96,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 0],
                    margin: [16, 0, 16, 32]
                }}
            >
                Edit Blinds
            </ActionButton>
            <ActionButton
                actionColor={'green'}
                onClick={() => useClock.editEvent()}
                icon={'./data/icon/event.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 480,
                    fontSize: 28,
                    heightPx: 96,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 0],
                    margin: [16, 0, 16, 32]
                }}
            >
                Edit Event
            </ActionButton>
            {adCount > 0 ? (
                 <ActionButton
                     actionColor={'yellow'}
                     onClick={() => useClock.editAds()}
                     icon={'./data/icon/megaphone.svg'}
                     iconOption={{
                         widthPx: 64,
                         heightPx: 64
                     }}
                     buttonOption={{
                         lineHeight: 80,
                         widthPx: 480,
                         fontSize: 28,
                         heightPx: 96,
                         heightToWidth: undefined,
                         padding: [0, 8, 0, 0],
                         margin: [16, 0, 16, 32]
                     }}
                 >
                     Edit Advertising
                 </ActionButton>
             ) : null}
            <ActionButton
                actionColor={'green'}
                onClick={() => useClock.editPay()}
                icon={'./data/icon/pay.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 480,
                    heightPx: 96,
                    fontSize: 28,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 0],
                    margin: [16, 0, 16, 32]
                }}
            >
                Edit Pay
            </ActionButton>
            <ActionButton
                actionColor={'green'}
                onClick={() => useClock.editPayout()}
                icon={'./data/icon/payout.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 480,
                    fontSize: 28,
                    heightPx: 96,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 0],
                    margin: [16, 0, 16, 32]
                }}
            >
                Edit Payout
            </ActionButton>
            <ActionButton
                actionColor={'green'}
                onClick={() => useClock.editLayout()}
                icon={'./data/icon/layout.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
                buttonOption={{
                    lineHeight: 80,
                    widthPx: 480,
                    fontSize: 28,
                    heightPx: 96,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 0],
                    margin: [16, 0, 16, 32]
                }}
            >
                Edit Layout
            </ActionButton>
            {useClock?.tournamentStarted ? (
                <ActionButton
                    actionColor={'red'}
                    onClick={() => sdk.openDeleteDialog('Really reset tournament?', 'RESET', () => {
                        useClock.resetTournament()
                    })}
                    icon={'./data/icon/undo.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        lineHeight: 80,
                        widthPx: 480,
                        fontSize: 28,
                        heightPx: 96,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 0],
                        margin: [16, 0, 16, 32]
                    }}
                >
                    Reset Tournament
                </ActionButton>
            ) : null}
        </Container>
        {sdk.user.premiumUser ? null : (
            <ImageComponent
                source={`./data/ads/clockpro.png`}
                imageOption={{
                    widthPx: 620,
                    // heightPx: 418,
                    margin: [0, 0, 0, 0]
                }}
                onClick={() => {
                    openWebsite(process.env.REACT_APP_WEBSITE || '')
                    // openMail('marc@bullets.poker')
                }}
            />
        )}
        <Container
            containerOption={{
                width: '100%',
                textAlign: 'center',
                fontSize: 28,
                heightPx: 96,
                lineHeight: 96,
                fontWeight: 'bold'
            }}
        >
            {`${useClock.code} (${useClock.admin || '-'})`}
        </Container>
    </>;
});

const ClockCommandoCentral = observer(({ sdk }: any) => {

    const useClock = sdk.currentClock;

    return <Container
        containerOption={{
            widthPx: 680,
            // heightPx: 980,
            margin: [10, 0, 0, 5],
            textAlign: 'center'
        }}
    >
        {useClock?.inited ? <ClockCommandoOverview sdk={sdk} /> : <Container
            containerOption={{
                fontSize: 24,
                fontWeight: 'bold',
                margin: [350, 0, 0, 0]
            }}>
            Please wait
        </Container>}
    </Container>;
});

export default ClockCommandoCentral;