import {
    observer
} from 'mobx-react-lite';
import {
    Container,
    IconButton,
    SelectButton
} from '../../controls';
import { EVENTS, EVENT_TYPE } from '../../../constants/events';
import { SOUNDS } from '../../../constants/sounds';

const SoundList = [
    {
        value: '',
        label: '- Disabled -'
    },
    ...(SOUNDS || [])
]
const EventEdit = observer(({ sdk, store, editMode }: any) => {

    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 48,
                    fontWeight: 'bold'
                }}
            >
                Event Editor
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Sound Next-Level
                </Container>
                <SelectButton
                    options={SoundList}
                    selected={store.getEventValue(EVENTS.EVENT_NEXTLEVEL)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_NEXTLEVEL, EVENT_TYPE.EVENT_SOUND, n)}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_NEXTLEVEL))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_NEXTLEVEL)}
                />
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Sound Break Start
                </Container>
                <SelectButton
                    options={SoundList}
                    selected={store.getEventValue(EVENTS.EVENT_BREAK_START)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_BREAK_START, EVENT_TYPE.EVENT_SOUND, n)}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_BREAK_START))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_BREAK_START)}
                />
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Sound Break End
                </Container>
                <SelectButton
                    options={SoundList}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                    selected={store.getEventValue(EVENTS.EVENT_BREAK_END)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_BREAK_END, EVENT_TYPE.EVENT_SOUND, n)}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_BREAK_END))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_BREAK_END)}
                />
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Next-Level in 5s
                </Container>
                <SelectButton
                    options={SoundList}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                    selected={store.getEventValue(EVENTS.EVENT_NEXTLEVEL_5)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_NEXTLEVEL_5, EVENT_TYPE.EVENT_SOUND, n)}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_NEXTLEVEL_5))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_NEXTLEVEL_5)}
                />
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Next-Level in 10s
                </Container>
                <SelectButton
                    options={SoundList}
                    selected={store.getEventValue(EVENTS.EVENT_NEXTLEVEL_10)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_NEXTLEVEL_10, EVENT_TYPE.EVENT_SOUND, n)}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_NEXTLEVEL_10))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_NEXTLEVEL_10)}
                />
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Next-Level in 30s
                </Container>
                <SelectButton
                    options={SoundList}
                    selected={store.getEventValue(EVENTS.EVENT_NEXTLEVEL_30)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_NEXTLEVEL_30, EVENT_TYPE.EVENT_SOUND, n)}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_NEXTLEVEL_30))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_NEXTLEVEL_30)}
                />
            </Container>
            <Container>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        margin: [16, 0, 0, 0],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Next-Level in 60s
                </Container>
                <SelectButton
                    options={SoundList}
                    selected={store.getEventValue(EVENTS.EVENT_NEXTLEVEL_60)}
                    onChange={(n: any) => store.setEvent(EVENTS.EVENT_NEXTLEVEL_60, EVENT_TYPE.EVENT_SOUND, n)}
                    selectOption={{
                        float: 'left',
                        margin: [0, 0, 0, 120]
                    }}
                />
                <IconButton
                    source={'./data/icon/play.png'}
                    iconOption={{
                        widthPx: 48,
                        heightPx: 48,
                        float: 'left'
                    }}
                    className={'playbutton'}
                    onClick={() => {
                        sdk.sounds.playSound(store.getEventValue(EVENTS.EVENT_NEXTLEVEL_60))
                    }}
                    enableDelay={2000}
                    disabled={!store.getEventValue(EVENTS.EVENT_NEXTLEVEL_60)}
                />
            </Container>
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    margin: [16, 0, 0, 0],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
            </Container>
        </Container>
    </>;
});

export default EventEdit;