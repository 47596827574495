
import {
    observer
} from 'mobx-react-lite';
import { useState } from 'react'
import {
    List,
    Container,
    TextField,
    ActionButton,
    Checkbox,
    IconButton,
    ImageComponent
} from '../../controls';
import { openWebsite } from '../../../utils/urls';

const LicenseTableHead = observer(({ sdk }: any) => {
    return <>
        <Container
            containerOption={{
                width: '10%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
        </Container>
        <Container
            containerOption={{
                width: '35%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {'Account'}
        </Container>
        <Container
            containerOption={{
                width: '35%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {'Role'}
        </Container>
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
        </Container>
    </>
});

const LicenseTableRow = observer(({ sdk, license, activeLicense }: any) => {
    const licenseActive = license?.id === activeLicense?.licenseId;
    return <>
        <Container
            containerOption={{
                width: '10%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >

        </Container>
        <Container
            containerOption={{
                width: '35%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {license?.name}
        </Container>
        <Container
            containerOption={{
                width: '35%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {license?.role}
        </Container>
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {license?.invite ? (
                <>
                    <IconButton
                        source={'./data/icon/yes.svg'}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            float: 'right',
                            margin: [16, 0, 0, 0]
                        }}
                        onClick={() => {
                            sdk.user.acceptInvite(license?.id, license?.role).then((success: boolean) => {
                                if (success) {
                                    if (!sdk.user.account.currentLicense) {
                                        sdk.user.changeUserLicense(license.id)
                                    } else {
                                        sdk.user.fetchUser(true);
                                    }
                                }
                            })
                        }}
                    />
                    <IconButton
                        source={'./data/icon/no.svg'}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            float: 'right',
                            margin: [16, 8, 0, 0]
                        }}
                        onClick={() => {
                            sdk.openDeleteDialog('Decline invite?', 'DECLINE', () => {
                                sdk.user.declineInvite(license?.id, license?.role).then((success: boolean) => {
                                    if (success) {
                                        sdk.user.fetchUser(true);
                                    }
                                })
                            })
                        }}
                    />
                </>
            ) : (<>
                <Checkbox
                    id={'invite'}
                    value={licenseActive ? 1 : 0}
                    onChange={() => {
                        if (!licenseActive) {
                            sdk.user.changeUserLicense(license.id)
                        }
                    }}
                    containerOption={{
                        float: 'left',
                        widthPx: 440,
                        margin: [20, 0, 0, 48]
                    }}
                    labelOption={{
                        widthPx: 310
                    }}
                />
            </>)}

        </Container>
    </>
});

/**
 * The component with all account details:
 * email, nickname, licenses, activeLicense
 */
const AccountDetails = observer(({ sdk, user }: any) => {
    return <Container
        containerOption={{
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto'
        }}
    >
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                // margin: [16, 0, 0, 64],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            E-Mail
        </Container>
        <TextField
            id={'mail-label'}
            maxLength={6}
            inputType={'text'}
            disabled={true}
            inputOption={{
                widthPx: 500,
                margin: [0, 0, 16, 50],
                float: 'left',
                borderSize: 2,
                border: 'solid #fff'
            }}
            value={user.account.mail}
        />
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                // margin: [16, 0, 0, 64],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Nickname
        </Container>
        <TextField
            id={'nickname-label'}
            maxLength={6}
            // disabled={true}
            inputType={'text'}
            onClick={() => {
                user.openChangeNickname()
            }}
            readOnly={true}
            inputOption={{
                widthPx: 500,
                margin: [0, 0, 16, 50],
                float: 'left',
                borderSize: 2,
                border: 'solid #fff'
            }}
            value={user.account.nickname}
        />
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                // margin: [16, 0, 0, 64],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Licenses
        </Container>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                overflow: 'hidden',
                bgColor: '#4f4f4f'
            }}
        >
            <LicenseTableHead sdk={sdk} />
        </Container>
        <List
            components={user.account.licenseList.map((license: any, i: number) => <LicenseTableRow sdk={sdk} license={license} activeLicense={user.account.currentLicense} key={`key-t-${i}`} />)}
            containerOption={{
                widthPx: 680,
                heightPx: 480,
                overflowY: 'auto',
                overflowX: 'hidden'
            }}
            listOption={{
                heightPx: 96,
                widthPx: 680
            }}
        />
    </Container>
})

const AccountMask = observer(({ sdk, user }: any) => {
    const accountStore = user.account.loginForm;

    return <>
        <Container
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            {accountStore.isActivationForm ? (
                <>
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        Activationcode
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={6}
                        inputType={'text'}
                        upperCase={true}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                        store={accountStore}
                        storeProperty={'activationcode'}
                    />
                </>
            ) : null}
            {(accountStore.isLoginForm || accountStore.isRegisterForm) ? (
                <>
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        E-Mail:
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={128}
                        inputType={'text'}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                        store={accountStore}
                        storeProperty={'mail'}
                    />
                </>
            ) : null}
            {accountStore.isRegisterForm ? (
                <>
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        E-Mail repeat:
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={128}
                        inputType={'text'}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                        store={accountStore}
                        storeProperty={'mail2'}
                    />
                </>
            ) : null}
            {accountStore.isActivationForm ? (
                <ActionButton
                    actionColor={'green'}
                    onClick={() => user.loginUser()}
                    // disabled={useStore?.isValid ? false : true}
                    icon={'./data/icon/load.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Activate
                </ActionButton>
            ) : null}
            {accountStore.isLoginForm ? (
                <ActionButton
                    actionColor={'green'}
                    onClick={() => user.loginUser()}
                    disabled={!accountStore.isEmailValid}
                    icon={'./data/icon/load.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Login
                </ActionButton>
            ) : null}
            {accountStore.isRegisterForm ? (
                <>
                    <Container
                        containerOption={{
                            float: 'left',
                            fontSize: 26,
                            margin: [40, 0, 0, 64]
                        }}
                    >
                        <a href={`https://couchgames.wtf/agb/`} target={'_blank'} style={{ color: '#fff', float: 'left' }} rel={'noreferrer'}>Terms: https://couchgames.wtf/agb/</a>
                    </Container>
                    <Checkbox
                        id={'term'}
                        store={accountStore}
                        storeProperty={'term'}
                        label={'Accept Term'}
                        containerOption={{
                            float: 'left',
                            widthPx: 440,
                            margin: [40, 0, 0, 32]
                        }}
                        labelOption={{
                            widthPx: 310
                        }}
                    />

                    <Checkbox
                        id={'newsletter'}
                        store={accountStore}
                        storeProperty={'newsletter'}
                        label={'Register for newsletter'}
                        containerOption={{
                            float: 'left',
                            widthPx: 440,
                            margin: [40, 0, 40, 32]
                        }}
                        labelOption={{
                            widthPx: 310
                        }}
                    />

                </>
            ) : null}
            {(accountStore.isLoginForm || accountStore.isRegisterForm) ? (
                <ActionButton
                    actionColor={'orange'}
                    onClick={() => {
                        if (accountStore.isRegisterForm) {
                            user.registerUser()
                        }
                        if (accountStore.isLoginForm) {
                            accountStore.switchToRegister()
                        }

                    }}
                    // disabled={useStore?.isValid ? false : true}
                    icon={'./data/icon/load.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    disabled={accountStore.isLoginForm ?
                        !accountStore.isEmailValid :
                        !accountStore.isRegisterPossible
                    }
                    buttonOption={{
                        widthPx: 480,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Register
                </ActionButton>
            ) : null}
            {(accountStore.isRegisterForm || accountStore.isActivationForm) ? (
                <ActionButton
                    actionColor={'red'}
                    onClick={() => accountStore.switchBack()}
                    // disabled={useStore?.isValid ? false : true}
                    icon={'./data/icon/load.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 320,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    Go back
                </ActionButton>
            ) : null}
            {accountStore.isLoginForm ? <ImageComponent
                source={'./data/cg.png'}
                imageOption={{
                    margin: [260, 0, 0, 0],
                    widthPx: 320
                }}
                onClick={() => openWebsite('https://www.couchgames.wtf')}
            /> : null}


        </Container>
    </>;
});

const Account = observer(({ sdk }: any) => {
    const accountUser = sdk.user;
    // const accountStore = accountUser?.account?.loginForm;

    const onButtonBack = () => {
        return sdk.closeAccount();
    }
    // The list of all clocks
    return <>
        <Container
            containerOption={{
                widthPx: 700,
                margin: [50, 0, 16, 10],
                fontSize: 32,
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#fff'
            }}
        >
            {`Account`}
        </Container>
        <Container
            id={'wizard-body'}
            containerOption={{
                widthPx: 700,
                heightPx: 1000,
                margin: [16, 0, 0, 10]
            }}
            className={'settingarea'}
        >
            <Container
                containerOption={{
                    widthPx: 680,
                    heightPx: 980,
                    margin: [10, 0, 0, 10],
                    textAlign: 'center',
                    float: 'left'
                }}
            >
                {accountUser.loggedIn ?
                    <AccountDetails sdk={sdk} user={accountUser} /> :
                    <AccountMask sdk={sdk} user={accountUser} />
                }

            </Container>
        </Container>
        <ActionButton
            actionColor={'red'}
            onClick={() => onButtonBack()}
            buttonOption={{
                widthPx: 220,
                heightPx: 96,
                heightToWidth: undefined,
                margin: [16, 16, 16, 16]
            }}
            icon={'./data/icon/leave.svg'}
        >
            Back
        </ActionButton>
        <ActionButton
            actionColor={'red'}
            disabled={!sdk.user.loggedIn}
            onClick={() => sdk.user.logoutUser()}
            buttonOption={{
                widthPx: 220,
                heightPx: 96,
                float: 'right',
                heightToWidth: undefined,
                margin: [16, 16, 16, 16]
            }}
            icon={'./data/icon/leave.svg'}
        >
            Logout
        </ActionButton>
    </>;
});

export default Account;