import {
    observer
} from 'mobx-react-lite';
// import {
//     translateKey
// } from '../../../utils/helper'
import getSdk from '../../../api'
import Icon from './Icon';

const TabArea = observer(({
    id,
    width,
    height,
    children,
    tabList,
    tabColor,
    tabIcon,
    activeTab,
    onClickTab,
    disabledButtons,
    containerOption,
    onClickCloseButton,
    contentOption
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    let useWidth = width;
    const tabs = tabList || [];
    const tabLength = tabs.length;

    if (onClickCloseButton) {
        useWidth = useWidth - 112;
        tabs.push({
            txt: 'X',
            config: {
                bgColor: '#B40000',
                color: '#fff',
                fontSize: 38,
                fontWeight: 'bold',
                widthPx: 96,
                margin: [0, 0, 0, 12],
                opacity: 1.0
            },
            onClick: () => onClickCloseButton()
        })
    }

    const tabWidth = tabLength > 0 ? (useWidth - (8 * tabLength - 1)) / tabLength : 0;
    const tabMargin = tabLength > 0 ? (useWidth - (tabLength * tabWidth)) / (tabLength - 1) : 0

    let activeColor = 'rgb(252, 170, 103)';

    if (tabColor?.length && typeof activeTab === 'number' && tabColor?.[activeTab]) {
        activeColor = tabColor[activeTab][0]
    }

    const propsContainer = useStyles({
        widthPx: width,
        heightPx: height,
        margin: [0, 0, 0, (1334 - useWidth) / 2],
        float: 'left',
        ...(containerOption || {})
    })

    const propsTabsContainer = useStyles({
        widthPx: width,
        heightPx: 48
    })

    const propsContent = useStyles({
        widthPx: width,
        heightPx: height - 48,
        borderSize: 6,
        borderTop: `solid ${activeColor}`,
        ...(contentOption || {})
    })
    return <div id={`${id}-container`} style={propsContainer}>
        <div id={`${id}-tabs`} style={propsTabsContainer} >
            {tabs.map((tab: any, i: number) => (
                <button
                    id={`${id}-tab-${i}`}
                    key={`${id}-tab-${i}`}
                    onClick={() => {
                        if (tab.onClick) {
                            tab.onClick();
                        }
                        if (onClickTab) {
                            onClickTab(i)
                        }
                    }}
                    disabled={disabledButtons?.[i] === true ? true : false}
                    style={useStyles({
                        float: 'left',
                        widthPx: tabWidth - 1,
                        heightPx: 48,
                        // className: i === activeRadioButton ? 'settingarea_tab_active' : 'settingarea_tab_inactive',
                        margin: [0, 0, 0, i > 0 ? tabMargin : 0],
                        borderCorner: [16, 16, 0, 0],
                        fontWeight: 'bold',
                        fontSize: 24,
                        ...(disabledButtons && disabledButtons[i] ? {
                            opacity: 0.3,
                        } : {}),
                        ...(i === activeTab ? {
                            bgColor: activeColor,
                            color: '#000'
                        } : {
                            bgColor: disabledButtons?.[i] === true ? '#666666' : '#000',
                            color: '#fff',
                            opacity: disabledButtons?.[i] === true ? 0.3 : 0.7
                        }),
                        ...(tab?.config || {})
                    })}
                // className={i === activeTab ? 'settingarea_tab_active' : 'settingarea_tab_inactive'}
                >
                    {tabIcon?.[i] ? (
                        <Icon
                            iconOption={{
                                widthPx: 32,
                                heightPx: 32,
                                float: 'left'
                            }}
                            source={tabIcon[i]}
                        />
                    ) : null}
                    {typeof tab === 'string' ? tab : tab.txt }
                </button>
            ))}
        </div>
        <div id={`${id}-content`} style={propsContent} className={'settingarea'}>
            {children}
        </div>
    </div>
});

export default TabArea;