import {
    observer
} from 'mobx-react-lite';
import { List, ImageComponent, ActionButton, IconButton, Container } from '../controls';
import { openBulletsPokerApp } from '../../utils/urls';

const ClockMainMenu = observer(({ sdk }: any) => {
    const clockUser = sdk.user;

    return <>
        <Container
            containerOption={{
                widthPx: 600,
                heightPx: 80,
                lineHeight: 80,
                bgColor: 'rgba(0, 0, 0, 0.4)',
                margin: [16, 0, 0, 64],
                color: '#e5e5e5',
                fontSize: 28,
                fontWeight: 'bold',
                textAlign: 'center'
            }}
            onClick={() => sdk.openAccount()}
        >
            {clockUser.label}
        </Container>
        <ImageComponent
            id={'mainmenu_logo'}
            imageOption={{
                widthPx: 480,
                margin: [40, 0, 0, 140]
            }}
            className={'bounce-top'}
            source={'./data/logo.png'}
            onClick={() => {
                try {
                    //@ts-ignore
                    document.getElementById('mainmenu_logo').classList.remove("bounce-top");

                } catch (e) { }
                setTimeout(() => {
                    try {
                        //@ts-ignore
                        document.getElementById('mainmenu_logo').classList.add("bounce-top");
                    } catch (e) { }
                }, 10)
            }}
        />
        <Container
            containerOption={{
                heightPx: 620,
                width: '100%'
            }}
        >
            <ActionButton
                actionColor={'green'}
                icon={'./data/icon/create.svg'}
                permissionRequired={'CLOCK.CREATE'}
                onClick={() => sdk.openWizard()}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64,
                    position: 'absolute'
                }}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    fontSize: 30,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 8],
                    margin: [28, 0, 16, 120],
                    textAlign: 'center'
                }}
            >
                Create clock
            </ActionButton>
            <ActionButton
                actionColor={'black'}
                onClick={() => sdk.openClockLogin()}
                icon={'./data/icon/show.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64,
                    position: 'absolute'
                }}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    fontSize: 30,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 8],
                    margin: [16, 0, 16, 120],
                    textAlign: 'center'
                }}
            >
                Show clock
            </ActionButton>

            <ActionButton
                actionColor={'black'}
                onClick={() => sdk.openRoleEditor()}
                icon={'./data/icon/role.svg'}
                permissionRequired={'ROLE.ALL'}
                premiumRequired={true}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64,
                    position: 'absolute'
                }}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    fontSize: 30,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 8],
                    margin: [16, 0, 16, 120],
                    textAlign: 'center'
                }}
            >
                Role Editor
            </ActionButton>
            <ActionButton
                actionColor={'black'}
                onClick={() => sdk.openMediaEditor()}
                icon={'./data/icon/media.svg'}
                // permissionRequired={'ROLE.ALL'}
                premiumRequired={true}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64,
                    position: 'absolute'
                }}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    fontSize: 30,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 8],
                    margin: [16, 0, 16, 120],
                    textAlign: 'center'
                }}
            >
                Media Editor
            </ActionButton>
            {/* <ActionButton
            actionColor={'black'}
            onClick={() => sdk.openAdmin()}
            icon={'./data/icon/admin.png'}
            iconOption={{
                widthPx: 64,
                heightPx: 64,
                position: 'absolute'
            }}
            buttonOption={{
                widthPx: 480,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 30,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [16, 0, 16, 120],
                textAlign: 'center'
            }}
        >
            Screen Editor
        </ActionButton> */}
            <ActionButton
                actionColor={'black'}
                onClick={() => sdk.openAdmin()}
                icon={'./data/icon/admin.png'}
                permissionRequired={'CLOCK.ADMIN'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64,
                    position: 'absolute'
                }}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    fontSize: 30,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 8],
                    margin: [16, 0, 16, 120],
                    textAlign: 'center'
                }}
            >
                Clock Admin
            </ActionButton>
            {sdk.user.premiumUser ? null : (
                <ImageComponent
                    source={`./data/ads/bulletspoker.png`}
                    imageOption={{
                        widthPx: 720,
                        heightPx: 120,
                        margin: [100, 0, 0, 0]
                    }}
                    onClick={() => {
                        openBulletsPokerApp()
                    }}
                />
            )}
        </Container>
        <List
            id={'mainmenu_linklist'}
            components={[
                <IconButton
                    id={'btn_website'}
                    source={'./data/social/bp.png'}
                    openWebsite={process.env.REACT_APP_WEBSITE}
                    buttonOption={{
                        margin: [20, 0, 0, 20],
                        widthPx: 80,
                        heightPx: 80
                    }}
                />,
                <IconButton
                    id={'btn_tiktok'}
                    source={'./data/social/tiktok.png'}
                    openWebsite={process.env.REACT_APP_TIKTOK}
                    buttonOption={{
                        margin: [20, 0, 0, 20],
                        widthPx: 80,
                        heightPx: 80
                    }}
                />,
                <IconButton
                    id={'btn_facebook'}
                    source={'./data/social/facebook.png'}
                    openWebsite={process.env.REACT_APP_FACEBOOK}
                    buttonOption={{
                        margin: [20, 0, 0, 20],
                        widthPx: 80,
                        heightPx: 80
                    }}
                />,
                <IconButton
                    id={'btn_instagram'}
                    source={'./data/social/instagram.png'}
                    openWebsite={process.env.REACT_APP_INSTAGRAM}
                    buttonOption={{
                        margin: [20, 0, 0, 20],
                        widthPx: 80,
                        heightPx: 80
                    }}
                />,
                <IconButton
                    id={'btn_contact'}
                    source={'./data/icon/contact.svg'}
                    openWebsite={process.env.REACT_APP_SUPPORT}
                    buttonOption={{
                        margin: [20, 0, 0, 20],
                        widthPx: 80,
                        heightPx: 80
                    }}
                />
            ]}
            listOption={{
                widthPx: 120,
                heightPx: 100,
                lineHeight: 80
            }}
            containerOption={{
                float: 'left',
                widthPx: 600,
                heightPx: 100,
                margin: [60, 0, 0, 40]
            }}
        />
    </>;
});

export default ClockMainMenu;