import { useEffect } from 'react';
import getSdk from './api';
import GameCanvas from './components/GameCanvas';
import {
  observer
} from 'mobx-react-lite';

const App = observer(() => {
  const sdk = getSdk();
  const useClockStyle = sdk?.watchClock?.bodyStyle || null

  useEffect(() => {
    if (sdk?.watchClock?.bodyStyle) {
      //@ts-ignore
      document.body.style.backgroundColor = sdk.watchClock.bodyStyle.bgColor;
      //@ts-ignore
      document.body.style.backgroundImage = sdk.watchClock.bodyStyle.bgImage;
    } else {
      sdk.layoutManager.updateBackground();
    }
  }, [sdk?.watchClock, sdk?.watchClock?.bodyStyle, sdk.layoutManager, sdk.layoutManager.hasChanged]);

  return (
    <div
      className={'container'}
      style={useClockStyle || {}}
    >
      <GameCanvas sdk={sdk} />
    </div>
  );
})

export default App;