import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'

export default class PayStore {

    public adminMode: boolean;

    public countPlayer: string;
    public buyInCash: string;
    public buyInStack: string;
    public rebuyCash: string;
    public rebuyStack: string;
    public addonCash: string;
    public addonStack: string;
    public earlybirdCash: string;
    public earlybirdStack: string;
    public prizepoolGuarantee: string;
    public allowBuyIn: number;
    public allowRebuy: number;
    public allowAddOn: number;
    public allowEarlyBird: number;
    public allowPrizepoolGuarantee: number;

    constructor(admin: boolean = false) {
        makeObservable(this, {
            countPlayer: observable,
            adminMode: observable,
            buyInCash: observable,
            buyInStack: observable,
            rebuyCash: observable,
            rebuyStack: observable,
            addonCash: observable,
            addonStack: observable,
            earlybirdCash: observable,
            earlybirdStack: observable,
            allowBuyIn: observable,
            allowRebuy: observable,
            allowAddOn: observable,
            changed: computed,
            storeValues: computed,
            allowEarlyBird: observable,
            prizepoolGuarantee: observable,
            allowPrizepoolGuarantee: observable,
            reset: action,
            load: action,
            updateValue: action,
            isValid: computed
        })

        this.adminMode = admin;
        this.countPlayer = '0';
        this.buyInCash = '0';
        this.buyInStack = '0';
        this.rebuyCash = '0';
        this.rebuyStack = '0';
        this.addonCash = '0';
        this.addonStack = '0';
        this.earlybirdCash = '0';
        this.earlybirdStack = '0';
        this.prizepoolGuarantee = '0';
        this.allowPrizepoolGuarantee = 0;
        this.allowBuyIn = 0;
        this.allowRebuy = 0;
        this.allowAddOn = 0;
        this.allowEarlyBird = 0;
    }

    load(clockData: any, password: string = "") {

        // BuyIN
        if (clockData?.config?.buyin?.length === 3) {
            this.allowBuyIn = clockData.config.buyin[0];
            this.buyInCash = `${clockData.config.buyin[1]}`;
            this.buyInStack = `${clockData.config.buyin[2]}`;
        }

        // Add-On
        if (clockData?.config?.addon?.length === 3) {
            this.allowAddOn = clockData.config.addon[0];
            this.addonCash = `${clockData.config.addon[1]}`;
            this.addonStack = `${clockData.config.addon[2]}`;
        }

        // Rebuy
        if (clockData?.config?.rebuy?.length === 3) {
            this.allowRebuy = clockData.config.rebuy[0];
            this.rebuyCash = `${clockData.config.rebuy[1]}`;
            this.rebuyStack = `${clockData.config.rebuy[2]}`;
        }

        // Early Bird
        if (clockData?.config?.earlybird?.length === 3) {
            this.allowEarlyBird = clockData.config.earlybird[0];
            this.earlybirdCash = `${clockData.config.earlybird[1]}`;
            this.earlybirdStack = `${clockData.config.earlybird[2]}`;
        }

        // Prizepool
        if (clockData?.config?.prize?.length === 2) {
            this.allowPrizepoolGuarantee = clockData.config.prize[0];
            this.prizepoolGuarantee = `${clockData.config.prize[1]}`;
        }

        // Save in comparison store
        sessionStorage.setItem('comparestore', this.storeValues)
    }

    updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    // Reset the store
    public reset(): void {
        this.countPlayer = '0';
        this.buyInCash = '0';
        this.buyInStack = '0';
        this.rebuyCash = '0';
        this.rebuyStack = '0';
        this.addonCash = '0';
        this.addonStack = '0';
        this.earlybirdCash = '0';
        this.earlybirdStack = '0';
        this.prizepoolGuarantee = '0';
        this.allowBuyIn = 0;
        this.allowRebuy = 0;
        this.allowAddOn = 0;
        this.allowEarlyBird = 0;
        this.allowPrizepoolGuarantee = 0;
    }

    get isValid() {
        return true;
        // return this.isCurrencyValid &&
        //     this.h1Label &&
        //     (this.password === '' || this.password.length >= 3);
    }

    get rebuyConfig() {
        return [
            this.allowRebuy,
            parseFloat(this.rebuyCash),
            parseInt(this.rebuyStack, 10)
        ]
    }

    get buyInConfig() {
        return [
            this.allowBuyIn,
            parseFloat(this.buyInCash),
            parseInt(this.buyInStack, 10)
        ]
    }
    get earlybirdConfig() {
        return [
            this.allowEarlyBird,
            parseFloat(this.earlybirdCash),
            parseInt(this.earlybirdStack, 10)
        ]
    }

    get addonConfig() {
        return [
            this.allowAddOn,
            parseFloat(this.addonCash),
            parseInt(this.addonStack, 10)
        ]
    }

    get prizepoolConfig() {
        return [
            this.allowPrizepoolGuarantee,
            parseInt(this.prizepoolGuarantee, 10)
        ]
    }

    get storeId() {
        return 'pay'
    }

    get storeValues() {
        return JSON.stringify([
            this.rebuyConfig,
            this.buyInConfig,
            this.earlybirdConfig,
            this.addonConfig,
            this.prizepoolConfig
        ]);
    }

    get changed() {
        let compareStore = '';
        try {
            compareStore = sessionStorage.getItem('comparestore') || '';
        } catch (e) {
        }
        return this.storeValues !== compareStore;
    }

    get updatedConfig() {
        return {
            addon: this.addonConfig,
            earlybird: this.earlybirdConfig,
            rebuy: this.rebuyConfig,
            buyin: this.buyInConfig,
            prize: this.prizepoolConfig
        }
    }
}