const currentLanguage = () => {
    return 'en'
}
function getRandomInt(min: any, max: any) {
    const useMin = Math.ceil(min);
    const useMax = Math.floor(max);
    return Math.floor(Math.random() * (useMax - useMin + 1)) + useMin;
}

const get1000Seperator = () => {
    if (currentLanguage() === 'en') return ',';
    return '.';
}

const replacePayouts = (payoutText: string, prizeFloating: boolean = false, prizePool = 0, prizeRound = 10, formatter: any = null) => {
    let usePayoutText = `${payoutText}`;
    let usePrizePool = prizePool;
    //eslint-disable-next-line
    const simpleMatch = usePayoutText.match(/\%f=[+-]?([0-9]*[.|,])?[0-9]+\%/g) || [];
    //eslint-disable-next-line
    const winnerMatch = usePayoutText.match(/\%f1=[+-]?([0-9]*[.|,])?[0-9]+\%/g) || [];

    simpleMatch.forEach((x, i) => {
        const flValue = parseFloat(x.replace('%f=', '').replace('%', ''));
        let rVal = prizePool;
        if (!isNaN(flValue)) {
            rVal = rVal * (flValue / 100);
            if (prizeRound > 0) {
                const rValDiv = Math.floor(rVal / prizeRound);

                //@ts-ignore
                rVal = parseFloat(rValDiv * prizeRound);
            } else {
                //@ts-ignore
                rVal = parseFloat(rVal.toFixed(2));
            }

            if(!prizeFloating) {
                rVal = parseInt(`${rVal}`, 10)
            }
            //@ts-ignore
            //return that.formatToCurrency(parseInt(rVal, 10), 0);
            //return rVal;
            usePayoutText = usePayoutText.replace(x, `${formatter ? formatter(rVal) : rVal}`);
            usePrizePool -= rVal;
            if (usePrizePool < 0) {
                usePrizePool = 0;
            }
        }
    });
console.log('WINNER', usePrizePool)
    winnerMatch.forEach((x) => {
        usePayoutText = usePayoutText.replace(x.replace(',', '.'), `${formatter ? formatter(usePrizePool) : usePrizePool}`);
    });

    return usePayoutText;
};

const generatePin = (length: number) => {
    let pin = Array.from({ length }, () => (1));
    return pin.map(() => getRandomInt(1, 10) - 1).join('');
}

const replace1k = (num: any) => {
    if (num === undefined) return undefined;

    const useNum = `${num}`
    const useNumLength = useNum.length;
    if (useNumLength > 3 && useNum.slice(-3) === '000') {
        return `${convertNumberToString(useNum.slice(0, useNumLength - 3))}k`
    }
    return num;
}
const getFloatSeperator = () => {
    if (currentLanguage() === 'en') return '.';
    return ',';
}
function getXpForLevel(lvl: any, overwriteX: any = undefined, overwriteY: any = undefined, overwriteZ: any = undefined) {
    let x = overwriteX || 1000;
    let y = overwriteY || 1.2;
    let z = overwriteZ || 100;
    return Math.ceil(Math.round(x + (z * Math.pow(lvl, y))) / 100) * 100;
}

function convertXpString(xp: any): any {
    const nuller = '00000000000000000000000';

    const splitXp = xp.split('n');
    if (splitXp.length === 2) {
        return parseInt(`${splitXp[0]}${nuller.slice(parseInt(splitXp[1], 10) * -1)}`)
    }
    return parseInt(xp, 10);
};
const timestampMs = () => {
    return new Date().getTime();
}

const timestampSec = () => {
    return Math.floor(new Date().getTime() / 1000);
}

const unixTime = () => {
    return Math.round(Date.now() / 1000);
}

const radianToDegrees = (radians: number) => {
    var pi = Math.PI;
    return radians * (180 / pi);
}

const distance = (x1: number, y1: number, x2: number, y2: number) => {
    let xDistance = x2 - x1;
    let yDistance = y2 - y1;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
}

function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxx3xxxxxxx'.replace(/[xy]/g, function (c) {
        //eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
const getFontSize = (length: number) => {
    if (length <= 10) {
        return 22;
    } else if (length <= 15) {
        return 20;
    } else if (length <= 19) {
        return 18;
    } else {
        return 16;
    }
}
const convertNumberToString = (numb: any, hideThousandSep = false) => {
    const numberSplit = `${numb}`.split('.');
    const useNumber = `${numberSplit[0]}` || '';
    const numbArray = useNumber.split('').reverse();
    let newNumb = '';

    numbArray.forEach((digit, i) => {
        if (i > 0 && i % 3 === 0) {
            if (!hideThousandSep) {
                newNumb = get1000Seperator() + newNumb;
            }
        }
        newNumb = digit + newNumb;
    });

    if (numberSplit.length > 1) {
        newNumb = `${newNumb}${getFloatSeperator()}${numberSplit[1]}`;
    }
    return newNumb;
}

// const translateKey = (key: string, options: any = null, replacement: any = []) => {
//     let translatedString = options?.default || key;

//     if (TRANSLATIONS[key] && TRANSLATIONS[key][currentLanguage()]) {
//         translatedString = TRANSLATIONS[key][currentLanguage()];
//     }
//     if (typeof translatedString === 'string') {
//         if (replacement.length > 0) {
//             replacement.forEach((rep: any) => {
//                 const replacements = translatedString.split(rep[0]).length - 1;
//                 for (let i = 0; i < replacements; i++) {
//                     translatedString = translatedString.replace(rep[0], rep[1]);
//                 }
//             })
//         }

//         return options?.upperSize ? translatedString.toUpperCase() : translatedString;
//     }
//     return options?.default || key;
// }

const replaceKey = (key: string, replacement: any) => {
    let translatedString = key;
    if (typeof translatedString === 'string') {
        if (replacement.length > 0) {
            replacement.forEach((rep: any) => {
                translatedString = translatedString.replace(rep[0], rep[1]);
            })
        }
        return translatedString;
    }
    return key;
}

const convertHMS = (value: string, format = 'hh:mm:ss') => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60) + (format === 'mm:ss' ? hours * 60 : 0); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02

    return format
        .replace('hh', hours < 10 ? `0${hours}` : `${hours}`)
        .replace('mm', minutes < 10 ? `0${minutes}` : `${minutes}`)
        .replace('ss', seconds < 10 ? `0${seconds}` : `${seconds}`);
}

const numberInvalid = (v: any, allowEmptyString = false) => {
    if (allowEmptyString && v === '') {
        v = '0'
    }
    if (v !== 0 && !v) {
        return true;
    }
    if (isNaN(parseInt(v, 10))) {
        return true;
    }
    return false;
}

// function convertTimestampToTime(timestamp: any) {
//     const aNewDate = new Date(timestamp);
//     const splittedTime = aNewDate.toLocaleTimeString('de-de').split(':');
//     return translateKey('text.common.clockformat', {}, [
//         ['$hour', splittedTime[0]],
//         ['$min', splittedTime[1]]
//     ])
// }

function convertWinnerInfo(inData: any) {
    const data = `${inData}`;
    let cards: any = [];
    let rankType = 0;

    if (data?.length === 8) {
        cards = [
            {
                suit: parseInt(data.slice(2, 3), 10),
                value: parseInt(data.slice(3, 5), 10)
            },
            {
                suit: parseInt(data.slice(5, 6), 10),
                value: parseInt(data.slice(6, 8), 10)
            }
        ]
        rankType = parseInt(data[1], 10)
    }

    return {
        cards,
        rankType
    }
}


function convertDealerCard(inData: any) {
    const data = `${inData}`;
    const cardSum = data?.length > 0 ? parseInt(data[0] || '0', 10) : 0;
    let cards = [];

    if (cardSum > 0) {
        cards.push({
            suit: parseInt(data.slice(1, 2), 10),
            value: parseInt(data.slice(2, 4), 10)
        })
    }

    if (cardSum > 1) {
        cards.push({
            suit: parseInt(data.slice(4, 5), 10),
            value: parseInt(data.slice(5, 7), 10)
        })
    }

    if (cardSum === 3) {
        cards.push({
            suit: parseInt(data.slice(7, 8), 10),
            value: parseInt(data.slice(8, 10), 10)
        })
    }

    return cards;
}

const convertCurrency = (value: any) => {
    const currency = ''; // TODO: Add currency
    return `${value} ${currency}`.trim();
}


function convertBigCardField(inData: any) {
    const data = `${inData}`;
    if (data.length !== 17) return undefined;
    let cards = [];

    cards.push({
        suit: parseInt(data.slice(2, 3), 10),
        value: parseInt(data.slice(3, 5), 10)
    })
    cards.push({
        suit: parseInt(data.slice(5, 6), 10),
        value: parseInt(data.slice(6, 8), 10)
    })
    cards.push({
        suit: parseInt(data.slice(8, 9), 10),
        value: parseInt(data.slice(9, 11), 10)
    })
    cards.push({
        suit: parseInt(data.slice(11, 12), 10),
        value: parseInt(data.slice(12, 14), 10)
    })
    cards.push({
        suit: parseInt(data.slice(14, 15), 10),
        value: parseInt(data.slice(15, 17), 10)
    })

    return {
        type: parseInt(data[1], 10),
        cards
    }
}

const convertFileType = (type: string) => {
    if (type === 'png') {
        return 'png';
    } else if (type === 'jpeg') {
        return 'jpg';
    } else if (type === 'mpeg') {
        return 'mp3';
    }
    return type;
}

const buildMediaUrlFromString = (uri: string) => {
    if ((uri || '').includes('media::')) {
        const newUri = uri.replace('media::', '');
        const splittedUri = newUri.split('>');
        const fileType: string = splittedUri[0];
        const fileSrc: string = splittedUri[1];
        return `${process.env.REACT_APP_MEDIA_API}res/${fileSrc}.${convertFileType(fileType)}`;
    }
    return uri;
}

const buildMediaUrl = (media: any, preview: boolean = false, color: boolean = false) => {
    let fileType: string = '';

    if (!media) {
        return null;
    }

    if (media.type.includes('image')) {
        if (media.type === 'image/png') {
            fileType = 'png';
        }
        return `${process.env.REACT_APP_MEDIA_API}res/${media.url}${preview ? '_small' : ''}.${fileType}`
    } else if (media.type.includes('audio')) {
        if (preview) {
            if (color) {
                return './data/icon/audio2.svg';
            }
            return './data/icon/audio.svg';
        }
        return `${process.env.REACT_APP_MEDIA_API}res/${media.url}.mp3`
    }
    return null;
}


export {
    currentLanguage,
    convertCurrency,
    convertDealerCard,
    convertBigCardField,
    timestampSec,
    timestampMs,
    convertHMS,
    convertWinnerInfo,
    unixTime,
    uuidv4,
    emailIsValid,
    replaceKey,
    convertNumberToString,
    distance,
    radianToDegrees,
    getFloatSeperator,
    get1000Seperator,
    numberInvalid,
    getFontSize,
    getXpForLevel,
    convertXpString,
    replace1k,
    generatePin,
    replacePayouts,
    buildMediaUrl,
    buildMediaUrlFromString
}