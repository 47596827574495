export const PAYOUT_TEMPLATES: any = [
    {
        name: 'Up to 60 entries',
        value: [
            [
                "0",
                "1. - %f1=65%\n2. - %f=35%"
            ],
            [
                "10",
                "1. - %f1=50%\n2. - %f=30%\n3. - %f=20%"
            ],
            [
                "21",
                "1. - %f1=46%\n2. - %f=27%\n3. - %f=17%\n4. - %f=10%"
            ],
            [
                "31",
                "1. - %f1=43%\n2. - %f=25%\n3. - %f=15.5%\n4. - %f=9.5%\n5. - %f=7%"
            ],
            [
                "41",
                "1. - %f1=41%\n2. - %f=23.5%\n3. - %f=15%\n4. - %f=9%\n5. - %f=6.5%\n6. - %f=5%"
            ],
            [
                "51",
                "1. - %f1=40%\n2. - %f=22.5%\n3. - %f=14.5%\n4. - %f=8.5%\n5. - %f=6.25%\n6. - %f=4.75%\n7. - %f=3.5%"
            ]
        ]
    }
]