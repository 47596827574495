import {
    makeObservable,
    observable,
    action,
    computed,
    runInAction
} from 'mobx'
import User from './user';
import LayoutManager from "./layoutmanager";
import PokerClock from "./gameclock";
import BlindStore from "./stores/blindstore";
import GeneralStore from './stores/generalstore';
import PayStore from './stores/paystore';
import EventStore from './stores/eventstore';
import PayoutStore from './stores/payoutstore';
import TemplateStore from './stores/templatestore';
import AdStore from './stores/adstore';
import { SoundManager } from './events';

const writeAppConfig = (data: string) => {
    return new Promise((resolve, reject) => {
        //@ts-ignore
        window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (dirpar: any) => {
            dirpar.getDirectory('bulletspokerclockcache', { create: true }, (dir: any) => {
                dir.getFile('user', { create: true, exclusive: false }, (fileEntry: any) => {
                    fileEntry.createWriter((fileWriter: any) => {
                        fileWriter.onwriteend = resolve
                        fileWriter.onerror = reject
                        fileWriter.write(new Blob([data], { type: 'text/plain' }));
                    });
                }, reject);
            }, reject);
        }, reject);
    });
}

const readAppConfig = () => {
    return new Promise((resolve, reject) => {
        //@ts-ignore
        window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (dirpar: any) => {
            dirpar.getDirectory('bulletspokerclockcache', { create: true }, (dir: any) => {
                dir.getFile('user', { create: true, exclusive: false }, (fileEntry: any) => {
                    fileEntry.file((file: any) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            resolve(reader.result)
                        };
                        reader.onerror = reject;
                        reader.readAsText(file);
                    });
                }, reject);
            }, reject);
        }, reject);
    });
}

const getAppName = (): string => {
    return process.env.REACT_APP_GAMENAME || '';
}

const getLocalStorage = (forceLocalstorage: boolean = false): Storage => {
    // Mobile Games only use the localStorage
    if (process.env.REACT_APP_MOBILE !== "false" || forceLocalstorage) {
        return localStorage;
    }
    // this is only for the websessions
    return sessionStorage || localStorage;
}

const wait = (ms: number) => new Promise(
    (res, rej) => setTimeout(
        () => rej(new Error(`timed out after ${ms} ms`)),
        ms
    )
);

function findClockParameter() {
    const parameters = findAllGetParameter();

    //eslint-disable-next-line
    if (history && history.pushState) {
        try {
            //eslint-disable-next-line
            const uri = new URL(location.href);

            parameters.forEach((p: any) => uri.searchParams.delete(p.id));

            //eslint-disable-next-line
            history.pushState({}, '', uri);
        } catch (e) {
        }
    }

    return parameters;
}

function findAllGetParameter() {
    let results: any = [];
    let tmp: any = null

    //eslint-disable-next-line
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp.length === 2) {
                results.push({
                    id: tmp[0],
                    value: decodeURIComponent(tmp[1])
                })
            }
        });

    return results;
}

enum VIEWS {
    Account = 99,
    Mainmenu = 100,
    ClockLogin = 101,
    Clock = 102,
    Admin = 103,
    Editor = 104,
    Wizard = 105,
    RoleEditor = 110,
    MediaEditor = 120
}
class CouchgamesSdk {
    public layoutManager: LayoutManager;
    public user: User;
    public view: VIEWS;

    public clockCode: string | null;

    public activeClock: number;
    public clockList: Array<PokerClock>;
    public watchClock: PokerClock | null;

    public spinner: any;
    public dialog: any;

    public clockWizard: any;
    public clockWizardData: any;

    public clockBlindEditor: BlindStore;
    public clockGeneralEditor: GeneralStore;
    public clockPayEditor: PayStore;
    public clockPayoutEditor: PayoutStore;
    public clockEventEditor: EventStore;
    public clockAdEditor: AdStore;
    public templateStore: TemplateStore;

    public latestClockTmp: any;

    public openedWithParameter: any;

    public sounds: SoundManager;

    // publick clock
    constructor() {
        // @ts-ignore
        window.sdk = this;

        makeObservable(this, {
            user: observable,
            templateStore: observable,
            view: observable,
            clockList: observable,
            activeClock: observable,
            watchClock: observable,
            spinner: observable,
            clockBlindEditor: observable,
            clockGeneralEditor: observable,
            clockPayEditor: observable,
            clockEventEditor: observable,
            clockAdEditor: observable,
            clockPayoutEditor: observable,
            clockWizard: observable,
            clockWizardData: observable,
            latestClockTmp: observable,
            dialog: observable,
            openMediaDialog: action,
            openMediaEditor: action,
            openMessageDialog: action,
            openClockDialog: action,
            openNumberDialog: action,
            openStringDialog: action,
            openDeleteDialog: action,
            closeDialog: action,
            openAdmin: action,
            openRoleEditor: action,
            openAccount: action,
            closeAccount: action,
            openMainMenu: action,
            leaveAdmin: action,
            openWizard: action,
            openClockLogin: action,
            closeWizard: action,
            loadClock: action,
            closeClock: action,
            createClock: action,
            openClock: action,
            createSpinner: action,
            closeSpinner: action,
            nextWizardStep: action,
            previousWizardStep: action,
            // postToApi: action,
            currentClock: computed,
            listClock: computed
        })

        this.layoutManager = new LayoutManager(this);
        this.user = new User(this);
        this.clockBlindEditor = new BlindStore();
        this.clockGeneralEditor = new GeneralStore();
        this.clockPayEditor = new PayStore();
        this.clockEventEditor = new EventStore();
        this.clockAdEditor = new AdStore(this);
        this.clockPayoutEditor = new PayoutStore();
        this.templateStore = new TemplateStore();;
        this.sounds = new SoundManager();
        this.watchClock = null;
        this.clockList = [];
        this.view = VIEWS.Mainmenu;
        this.clockCode = null;
        this.spinner = null;
        this.activeClock = 0;
        this.clockWizard = null;
        this.clockWizardData = null;
        this.dialog = null;
        this.openedWithParameter = findClockParameter() || null;
        this.latestClockTmp = null;

        // Try to open a clock if the parameter is set
        if (this.openedWithParameter) {
            const openCode: string = this.openedWithParameter.find((p: any) => p.id === 'code')?.value || '';
            let openToken: string = this.openedWithParameter.find((p: any) => p.id === 'token')?.value || '';

            if (openToken) {
                openToken = atob(openToken);
            }

            if (openCode.length === 4) {
                if (openToken) {
                    this.addClock(openCode, openToken, true);
                } else {
                    setTimeout(() => this.loadClock(openCode), 500)
                }
            }
        }
    }

    get currentClock() {
        if (this.activeClock === 0) {
            return null;
        }
        return this.clockList[this.activeClock - 1]
    }

    get listClock() {
        return this.clockList.map((clock, i) => `Clock ${i}`)
    }

    public openNumberDialog(title: string, value: number, cbYes: any, maxValue: null | number = null): void {
        this.dialog = {
            type: 'number',
            value,
            title,
            maxValue,
            cbYes
        }
    }

    public openDeleteDialog(title: string, keyword: string, cbYes: any): void {
        this.dialog = {
            // ...options,
            type: 'string',
            delete: true,
            // value,
            title,
            keyword,
            // maxLength,
            // disableUpperCase,
            cbYes
        }
    }

    public openStringDialog(title: string, value: string, cbYes: any, maxLength: number = 256, disableUpperCase: boolean = false, options: any = {}): void {
        this.dialog = {
            ...options,
            type: 'string',
            value,
            title,
            maxLength,
            disableUpperCase,
            cbYes
        }
    }

    public openMessageDialog(title: string, message: string, cbYes: any, cbNo: any, link: string = '', couchgamesLogo: boolean = false): void {
        this.dialog = {
            type: 'message',
            message,
            link,
            title,
            cbYes,
            cbNo,
            couchgames: couchgamesLogo
        }
    }

    public openClockDialog(cbYes: any, cbNo: any): void {
        this.dialog = {
            type: 'message',
            roleSelect: true,
            message: 'Please select how you want to open the clock. As an admin or as a second screen/tv?',
            title: 'How to connect to the clock?',
            cbYes,
            cbNo
        }
    }

    public async openMediaDialog(upload: boolean, onSelected: any = null, initialUrl: string = '', defaultMedia: any = [], mediaType: string | null = null, allowUri: boolean = false): Promise<void> {
        if (this.user) {
            await this.user.requestMedia();
        }

        console.log('MEDIA', initialUrl)
        this.dialog = {
            type: 'media',
            mediaType,
            allowUri,
            defaultMedia,
            uri: initialUrl,
            view: upload ? 'upload' : 'pick',
            cbSelected: (data: any) => {
                if (onSelected) {
                    onSelected(data);
                }
            },
            cbFailed: () => null,
            cbUpload: (file: any, fileName: string) => this.user.uploadMedia(file, fileName)
        };


        // onClick={() => sdk.openMediaDialog(false,(file:any, fileName:string) => {
        //     console.log(file,fileName)
        //     // Use file and name to upload to server
        //     // sdk.user.uploadMedia(file, fileName)
        // }, () => null)}

    }

    public closeDialog(): void {
        this.dialog = null;
    }

    public async openMediaEditor(): Promise<void> {
 
        // // Request session list if necessary
        if (this.user.loggedIn && this.user.account.currentLicense) {
            this.user.requestMedia();
        }

        this.view = VIEWS.MediaEditor;
    }
    
    public previousWizardStep(): void {
        this.clockWizard -= 1;
    }

    public nextWizardStep(): void {
        this.clockWizard += 1;

        if (this.clockWizard > 5) {
            this.createClock()
        }
    }

    public openMainMenu(): void {
        this.view = VIEWS.Mainmenu;
    }

    public async openAdmin(): Promise<void> {

        // Request session list if necessary
        if (this.user.loggedIn && this.user.account.currentLicense) {
            this.user.fetchSessionList()
        }

        this.view = VIEWS.Admin;
    }

    public async openRoleEditor(): Promise<void> {

        // // Request session list if necessary
        if (this.user.loggedIn && this.user.account.currentLicense) {
            this.user.fetchRoleList()
        }

        this.view = VIEWS.RoleEditor;
    }

    public openAccount(): void {
        this.user.fetchUser();
        this.view = VIEWS.Account;
    }

    public closeAccount(): void {
        this.openMainMenu();
    }

    public leaveAdmin(): void {
        this.view = VIEWS.Mainmenu;
        (this.clockList || []).forEach((clock: any) => {
            clock.destroy();
        })

        this.clockList = [];
    }

    public openClockLogin(): void {
        // Request session list if necessary
        if (this.user.loggedIn && this.user.account.currentLicense) {
            this.user.fetchSessionList()
        }

        this.view = VIEWS.ClockLogin;
    }

    public openWizard(): void {
        this.view = VIEWS.Wizard;
        this.clockWizard = (this.user?.account?.hasLicense && this.user?.account?.currentLicense?.hasTemplateTournament) ? 0 : 1;
        this.clockWizardData = {
            code: '',
            auth: ''
        }
        // reset all editors
        this.clockGeneralEditor.reset();
        this.clockBlindEditor.reset();
        this.clockEventEditor.reset();
    }

    public closeWizard(): void {
        this.view = VIEWS.Mainmenu;
        this.clockWizard = 1;

        // reset all editors
        this.clockGeneralEditor.reset();
        this.clockBlindEditor.reset();
        this.clockEventEditor.reset();
    }

    /**
     * Open the clock 
     */
    public async openClock(code: string, auth: string): Promise<any> {
        const newClock = new PokerClock(this, code, {
            auth
        });
        this.clockList.push(newClock)
        this.activeClock = this.clockList.length;

        runInAction(() => {
            this.view = VIEWS.Admin;
        });
    }

    public async addClock(code: string, auth: string, executedOnStart:boolean = false): Promise<any> {
        if (this.clockList.find(clock => clock.code === code)) {
            return true;
        }

        if (this.clockList.length >= 2) {
            return false;
        }

        const result = await this.fetchApi({
            action: 'editclock',
            code,
            authcode: auth,
            userToken: this.user?.account?.token || null,
            userSecret: this.user?.account?.secret || null,
            licenseToken: this.user?.account?.currentLicense?.licenseId || null,
            deviceId: this.user?.deviceId || null,
            trigger: executedOnStart ? 'showmode' : null
        }, 'PUT')

        // Zuerst wird geprüft, ob die Clock aufgerufen werden darf...
        if (result?.status === 200) {
            // Lade die Clock und initialisiere sie
            this.openClock(code, auth);
            
            this.user.requestMedia();
            return true;
        } else if (result?.status === 401 || result?.status === 204) {
            this.openMessageDialog(
                'Not authenticated',
                'The clock is not available or you entered a wrong authentification code',
                null,
                null
            )
        }

        return false;
    }

    public init(): void {
        //
    }

    public createSpinner(timeout: number = 9000): any {
        this.closeSpinner();

        this.spinner = setTimeout(() => {
            this.closeSpinner();
        }, timeout)

        return {
            close: () => this.closeSpinner()
        }
    }

    public closeSpinner(): void {
        if (this.spinner) {
            clearTimeout(this.spinner);
        }
        this.spinner = undefined;
    }

    public async postBlindUpdate(smallBlind: number, bigBlind: number): Promise<any> {
        return this.fetchApi({
            action: 'updateblind',
            clockCode: this.clockCode,
            options: {
                smallBlind,
                bigBlind
            }
        })
    }

    public async fetchApiOrClose(data: any = {}, method: string = 'POST', timeout: number = 4000): Promise<any> {
        const getFetchedResult = await this.fetchApi(data, method, '', timeout);

        // Close the clock if necessary
        if (getFetchedResult?.status === 204) {
            this.closeClock(true);
            this.openMessageDialog(
                'Clock not available',
                'The clock was closed or is not available',
                null,
                null
            )
            return null;
        }
        return getFetchedResult;
    }

    public async fetchApi(data: any = {}, method: string = 'POST', path: string = '', timeout: number = 8000, overwriteUrl: string | null = null): Promise<any> {
        let useUrl = overwriteUrl ?
            overwriteUrl :
            `https://gamingapi.couchgames.wtf/tournamentclock/${path}`;

        this.createSpinner(timeout);

        const isGet = method === 'GET';

        if (isGet) {
            const reqQuery = Object.entries({
                ...data,
                service: process.env.REACT_APP_GAMESERVICE
            }).map((c, i) => `${i > 0 ? '&' : ''}${c[0]}=${c[1]}`).join('')
            useUrl = `${useUrl}?${reqQuery}`
        }

        return new Promise((resolve, reject) => {
            const abortController = new AbortController();
            const fetchData = fetch(useUrl, {
                method,
                signal: abortController.signal,
                headers: data?.res === 'formData' ? undefined : { 'Content-Type': 'application/json' },
                 body: isGet ? undefined : data?.res === 'formData' ? data.body : JSON.stringify({
                    ...data,
                    service: process.env.REACT_APP_GAMESERVICE
                })
            });

            const fetchOrTimeout = Promise.race([fetchData, wait(timeout)]);

            fetchOrTimeout
                .then(async (response: any) => {
                    this.closeSpinner();

                    let jsonResult = null;
                    try {
                        jsonResult = await response?.json()
                    } catch (e) {
                    }
                    resolve({
                        json: jsonResult || null,
                        status: response.status
                    })
                })
                .catch((error: any) => {
                    this.closeSpinner();
                    abortController.abort()
                    resolve(undefined)
                });
        })

    }

    public async loadClock(code: string, fromClock: PokerClock | null = null): Promise<boolean> {
        const clockResult = fromClock ?
            {
                status: 200
            }
            : await this.fetchApi({
                code
            }, 'GET')

        if (clockResult?.status === 200) {
            this.watchClock = fromClock ?
                fromClock :
                new PokerClock(this, code, {}, true);
            this.view = VIEWS.Clock;
            if (process.env.REACT_APP_ROTATESCREEN === 'false') {
                this.layoutManager.enableLandscape()
            }
        } else if (clockResult?.status === 204) {
            this.openMessageDialog(
                'Clock not available',
                'The clock was closed or is not available',
                null,
                null
            )
        }

        return true
    }

    public async closeClock(force: boolean = false): Promise<void> {
        const returnToAdmin = this.watchClock?.returnToAdmin || false;

        if (!returnToAdmin && force === false) {
            const that = this;
            this.openMessageDialog('Really close the clock?', 'Do you want to close the clock and return to the mainmenu?', () => {
                that.closeClock(true);
            }, () => null);

            return;
        }
        if (this.watchClock) {
            if (!returnToAdmin) {
                this.watchClock?.destroy();
                this.view = VIEWS.Mainmenu;
            } else {
                this.view = VIEWS.Admin;
            }
            this.watchClock = null;
        } else {
            this.view = VIEWS.Mainmenu;
        }

        if (process.env.REACT_APP_ROTATESCREEN === 'false') {
            this.layoutManager.enablePortrait()
        }
    }

    public async loadClockWithTemplate(template: any): Promise<boolean> {
        const loadedTemplate: any = await this.user.loadTemplate('tournament', template.id, template.hash)

        if (loadedTemplate?.config) {
            this.clockGeneralEditor.load(loadedTemplate);
            this.clockBlindEditor.load(loadedTemplate);
            this.clockPayEditor.load(loadedTemplate);
            this.clockPayoutEditor.load(loadedTemplate);
            this.clockEventEditor.load(loadedTemplate);

            // Deprecated
            if (loadedTemplate?.config?.stylecode) {
                this.clockGeneralEditor.updateStyleCode(loadedTemplate.config.stylecode)
            }

            this.nextWizardStep();
            return true;
        }

        return false;
    }

    public async createClock(): Promise<boolean> {
        let hasLicense: boolean = false;

        let buildClockConfig: any = {
            config: {
                events: this.clockEventEditor.config,
                level: this.clockBlindEditor.config,
                lblH1: this.clockGeneralEditor.h1Label || null,
                lblH2: this.clockGeneralEditor.h2Label || null,
                logo: this.clockGeneralEditor.logo || null,
                country: this.clockGeneralEditor.country,
                currency: this.clockGeneralEditor.currency,
                stream: [this.clockGeneralEditor.allowStream, this.clockGeneralEditor.streamUrl],
                stylecode: this.clockGeneralEditor.stylecode || '',
                flagCurrency: this.clockGeneralEditor.allowCurrency,
                buyin: this.clockPayEditor.buyInConfig,
                rebuy: this.clockPayEditor.rebuyConfig,
                addon: this.clockPayEditor.addonConfig,
                earlybird: this.clockPayEditor.earlybirdConfig,
                prize: this.clockPayEditor.prizepoolConfig,
                countPlayer: parseInt(this.clockPayEditor.countPlayer, 10) || 0,
                payouts: this.clockPayoutEditor.config || '',
                payoutRound: parseInt(this.clockPayoutEditor.payoutRound, 10) || 10,
                floating: this.clockGeneralEditor.floating || 0
            }
        }
        let buildClockData: any = {
            ...buildClockConfig
        }

        if (this.user.loggedIn && this.user.account.currentLicense) {
            buildClockData['licenseToken'] = this.user.account.currentLicense.licenseId;
            buildClockData['userToken'] = this.user.account.token;
            buildClockData['userSecret'] = this.user.account.secret;
            buildClockData['userNickname'] = this.user.account.nickname;
            buildClockData['sessionName'] = this.clockGeneralEditor.h1Label || 'Uknown';
            hasLicense = true;
        } else {
            buildClockData['licenseToken'] = 'nolicense';
        }

        buildClockData['deviceId'] = this.user?.deviceId || null;
        buildClockData['authcode'] = this.clockGeneralEditor.password || undefined;

        const clock = await this.fetchApi(buildClockData, 'POST', 'session/')

        if (clock?.status === 400) {
            if (clock.json.errorName === 'NoCapacity') {
                this.openMessageDialog(
                    'No capacity',
                    'You reached the maximum count of clocks. Please delete a session or update your license',
                    null,
                    null
                )
            }
            return false;
        }

        // The clock was created.
        if (clock?.json?.clock?.code) {
            this.clockWizardData = {
                code: clock.json.clock.code,
                auth: clock.json.clock.authcode,
                config: buildClockData,
                tmp: buildClockConfig
            }

            // Open the clock directly, if the user has no license
            if (!hasLicense) {
                this.latestClockTmp = {
                    code: clock.json.clock.code,
                    auth: clock.json.clock.authcode
                };

                this.closeWizard();
                this.openClock(clock.json.clock.code, clock.json.clock.authcode);
            }
        }

        return false;
    }

    public async saveToLocalStorage(key: string, value: any) {
        const useKey = `${getAppName()}${key}`;
        if (getLocalStorage(true)) {
            if (typeof value === 'object') {
                getLocalStorage(true).setItem(btoa(useKey), btoa(JSON.stringify(value)));
            } else if (typeof value === 'string') {
                getLocalStorage(true).setItem(btoa(useKey), btoa(value));
            } else if (typeof value === 'boolean') {
                //@ts-ignore
                getLocalStorage(true).setItem(btoa(useKey), value);
            }
        }
    }

    public async loadFromLocalStorage(key: string) {
        return this.loadFromStorage(key, true)
    }

    public async saveToUserStorage(key: string, value: any, forceLocalstorage: boolean = false) {
        if (process.env.REACT_APP_MOBILE !== "false") {
            // go the new way
            await writeAppConfig(JSON.stringify(value));
        } else {
            this.saveInStorage(key, value, forceLocalstorage)
        }
        return true;
    }

    public saveInStorage(key: string, value: any, forceLocalstorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;

        if (getLocalStorage(forceLocalstorage)) {
            if (typeof value === 'object') {
                getLocalStorage(forceLocalstorage).setItem(btoa(useKey), btoa(JSON.stringify(value)));
            } else if (typeof value === 'string') {
                getLocalStorage(forceLocalstorage).setItem(btoa(useKey), btoa(value));
            } else if (typeof value === 'boolean') {
                //@ts-ignore
                getLocalStorage(forceLocalstorage).setItem(btoa(useKey), value);
            }
        }
    }

    public deleteFromStorage(key: string) {
        const useKey = `${getAppName()}${key}`;
        //@ts-ignore
        getLocalStorage(true).removeItem(btoa(useKey))
    }

    public async loadFromUserStorage(key: string, forceLocalstorage: boolean = false) {
        if (process.env.REACT_APP_MOBILE !== "false") {
            // go the new way
            const data: any = await readAppConfig();

            if (data) {
                let rData: any = null;
                try {
                    rData = JSON.parse(data);
                } catch (e) {
                }
                return rData;
            }

        }

        return this.loadFromStorage(key, forceLocalstorage)
    }

    public loadFromStorage(key: string, forceLocalstorage: boolean = false) {
        const useKey = `${getAppName()}${key}`;

        if (getLocalStorage(forceLocalstorage)) {
            const data = getLocalStorage(forceLocalstorage).getItem(btoa(useKey));
            if (data) {
                try {
                    if (typeof data === 'boolean') return data;
                    if (data === 'false') return false;
                    return JSON.parse(atob(data));
                } catch (e) {
                    return atob(data);
                }
            }
        }
        return undefined;
    }


    public onPauseMobile(): void { }

    public onResumeMobile(): void {
        if (this.watchClock) {
            this.watchClock.onResume()
        } else if (this.currentClock) {
            this.currentClock.onResume()
        } else {
            this.user.fetchUser();
        }
    }

    public onResumeWeb(): void {
        this.user.fetchUser();
    }
}

let CGSdk: CouchgamesSdk | undefined = undefined;

const getSdk = () => {
    if (!CGSdk) {
        CGSdk = new CouchgamesSdk();
        CGSdk.init();
    }

    return CGSdk;
}

export default getSdk;
export {
    CouchgamesSdk,
    VIEWS
}