import {
    observer
} from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import {
    Container,
    IconButton,
    TextField,
    Icon,
    List
} from '../controls/index';

function filterUrl(url: string): string {
    if (url.includes('media::')) {
        return '';
    }
    return url;
}

function convertMediaToString(media: any): string | null {
    if (media?.type && media?.url) {
        return `media::${media.type.split('/')[1]}>${media.url}`
    }
    return null;
}

function isValidHttpUrl(n: string) {
    let url;

    try {
        url = new URL(n);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const supportedFormat = (format: string) => {
    if (format === 'audio/mpeg') {
        return true;
    } else if (format === 'image/jpeg') {
        return true;
    } else if (format === 'image/png') {
        return true;
    } else if (format === 'image/webp') {
        return true;
    }
    return false;
}

const MediaDialogEntry = ({ icon, label, onClick, path, type }: any) => {
    return <Container
        containerOption={{
            width: '100%',
            height: '100%'
        }}
        onClick={() => onClick ? onClick(path) : null}
    >
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                float: 'left'
            }}
        >
            <Icon
                // source={'https://www.pocket-manager.de/wp-content/uploads/2020/05/wie-werde-ich-spieleprogrammierer-teil2-604x270.png'}
                source={icon ? icon : './data/icon/audio.svg'}
                iconOption={{
                    widthPx: 80,
                    heightPx: 80,
                    margin: [8, 0, 0, 24]
                }}
            />
        </Container>
        <Container
            containerOption={{
                width: '80%',
                height: '100%',
                float: 'left',
                fontSize: 28,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                color: '#fff'
            }}
            onClick={() => null}
        >
            {label || 'Filename'}

        </Container>
    </Container>
}

const MediaDialogList = observer(({ sdk, dialog }: any) => {
    const [view, setView]: any = useState('');
    const [url, setUrl]: any = useState(filterUrl(dialog?.uri || ''));

    const useMediaType = dialog?.mediaType || null;
    const defaultMedia = dialog?.defaultMedia || [];
    const useLicense = sdk?.user?.account?.currentLicense || null;
    const additionalComponent: any = [];


    if (defaultMedia.length) {
        defaultMedia.forEach((media: any) => {
            additionalComponent.push({
                name: media.label,
                mediaPreviewUrl: './data/icon/next.svg',
                onClick: () => handleSelectMedia(media.value)
            })
        })
    }

    if (dialog?.allowUri) {
        additionalComponent.push({
            name: 'Enter your own Url',
            mediaPreviewUrl: './data/icon/link.svg',
            onClick: () => setView('url')
        })
    }

    let mediaFiles = useLicense?.media?.allMedia || [];

    if (useMediaType === 'image') {
        mediaFiles = useLicense?.media?.allImage || [];
    } else if (useMediaType === 'audio') {
        mediaFiles = useLicense?.media?.allAudio || [];
    }

    const handleSelectMedia = (data: any = null) => {
        if ((data || data === '') && dialog?.cbSelected) {
            console.log('handle', data, typeof data)
            if (typeof data === 'string') {
                dialog.cbSelected(data);
            } else {
                dialog.cbSelected(convertMediaToString(data))
            }
            // dialog.cbSelected()
        }
        sdk.closeDialog();

    }
    return <>
        <Container
            id={'mediadialog_title'}
            containerOption={{
                widthPx: 620,
                heightPx: 48,
                color: '#fff',
                fontSize: 32,
                fontWeight: 'bold',
                textAlign: 'center',
                margin: [32, 0, 0, 10],
                role: 'alert'
            }}
        >
            {view === 'url' ? 'Enter your url' : 'Select Media'}
        </Container>
        <Container
            id={'mediadialog_body'}
            containerOption={{
                widthPx: 620,
                heightPx: 580,
                float: 'left',
                margin: [32, 0, 0, 10]
            }}
        >
            {view === 'url' ? <Container
                containerOption={{
                    width: '100%',
                    heightPx: 32,
                    color: '#fff',
                    fontSize: 32,
                    float: 'left',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    margin: [32, 0, 32, 5],
                    role: 'alert'
                }}
            >
                <TextField
                    inputType={'text'}
                    inputOption={{
                        widthPx: 512
                    }}
                    // upperCase={dialogDisableUpperCase ? false : true}
                    maxLength={256}
                    placeholder={'Enter url'}
                    onInput={(v: any) => {
                        setUrl(v)
                    }}
                    value={url}
                />
            </Container>
                : <>
                    <List
                        components={[
                            ...additionalComponent,
                            ...mediaFiles
                        ].map((media: any) => <MediaDialogEntry
                            label={media.name}
                            icon={media.mediaPreviewUrl}
                            onClick={() => media.onClick ? media.onClick() : handleSelectMedia(media)}
                        />)}
                        containerOption={{
                            width: '100%',
                            heightPx: 560,
                            bgColor: 'rgba(214,214,214,0.2)',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                        listOption={{
                            width: '100%',
                            heightPx: 96,
                            borderSize: 2,
                            borderBottom: 'solid #fff'
                        }}
                    />
                </>}
        </Container>
        <IconButton
            source={'./data/icon/yes.svg'}
            buttonOption={{
                widthPx: 96,
                heightPx: 96,
                float: 'right',
                margin: [8, 32, 0, 0]
            }}
            disabled={view === 'url' ? isValidHttpUrl(url) === false : false}
            onClick={() => {
                if (view === 'url') {
                    handleSelectMedia(url)
                } else {
                    handleSelectMedia();
                }
            }}
        />
        {view === 'url' ?
            <IconButton
                source={'./data/icon/no.svg'}
                buttonOption={{
                    widthPx: 96,
                    heightPx: 96,
                    float: 'right',
                    margin: [8, 32, 0, 0]
                }}
                onClick={() => {
                    setView('')
                }}
            />
            : null}

    </>
});

const MediaDialogUpload = observer(({ sdk, dialog }: any) => {
    const [fileName, setFileName] = useState(dialog?.value || '');
    const [fileBlob, setFileBlob]: any = useState(null);

    useEffect(() => {
        setFileName(dialog?.value ? dialog.value : '');
        setFileBlob(null);
    }, [dialog])

    return <>
        <Container
            id={'gamedialog_string_message'}
            containerOption={{
                widthPx: 620,
                heightPx: 48,
                color: '#fff',
                fontSize: 32,
                fontWeight: 'bold',
                textAlign: 'center',
                margin: [32, 0, 0, 10],
                role: 'alert'
            }}
        >
            {'Upload Media'}
        </Container>
        <Container
            containerOption={{
                widthPx: 620,
                heightPx: 32,
                color: '#fff',
                fontSize: 32,
                float: 'left',
                fontWeight: 'bold',
                textAlign: 'center',
                margin: [32, 0, 32, 10],
                role: 'alert'
            }}
        >
            <TextField
                inputType={'text'}
                upperCase={false}
                maxLength={48}
                disabled={fileBlob ? false : true}
                placeholder={'Media Name'}
                onInput={(v: any) => {
                    setFileName(v)
                }}
                value={fileName}
            />
        </Container>
        <Container
            containerOption={{
                widthPx: 620,
                heightPx: 64,
                color: '#fff',
                fontSize: 32,
                float: 'left',
                fontWeight: 'bold',
                textAlign: 'center',
                margin: [32, 0, 32, 10],
                role: 'alert'
            }}
        >
            <input
                type="file"
                name="myImage"
                onChange={(event) => {
                    if (event?.target?.files?.[0]) {
                        setFileName(event.target.files[0].name)
                        setFileBlob(event.target.files[0])
                    }
                }}
            />
        </Container>
        <Container
            containerOption={{
                color: '#fff',
                width: '100%',
                fontSize: 22,
                textAlign: 'center'
            }}
        >
            <u>Best resolution for ADs:</u><br/><span style={{ color: '#ed5850' }}>1920x1080 (Full-HD)</span>
        </Container>
        <Container
            containerOption={{
                color: '#fff',
                width: '100%',
                textAlign: 'center',
                margin: [16, 0, 0, 0]
            }}
        >
            <u>Allowed formats:</u><br/>jpg, png, webp, mp3
        </Container>
        <Container
            containerOption={{
                color: '#fff',
                width: '100%',
                fontSize: 22,
                textAlign: 'center',
                margin: [16, 0, 0, 0]
            }}
        >
            <u>Max Size:</u><br/>10MB
        </Container>
        <IconButton
            source={'./data/icon/no.svg'}
            buttonOption={{
                widthPx: 96,
                heightPx: 96,
                float: 'right',
                margin: [64, 32, 0, 0]
            }}
            onClick={() => {
                sdk.closeDialog()
            }}
        />
        <IconButton
            source={'./data/icon/yes.svg'}
            buttonOption={{
                widthPx: 96,
                heightPx: 96,
                float: 'right',
                margin: [64, 32, 0, 0]
            }}
            disabled={!supportedFormat(fileBlob?.type) || !fileName}
            onClick={() => {
                if (dialog?.cbUpload) {
                    dialog.cbUpload(fileBlob, fileName)
                }
                sdk.closeDialog();
            }}
        />

    </>
})

const MediaDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk?.dialog?.type === 'media' ? sdk.dialog : null;

    const [mediaView, setMediaView] = useState(dialog?.view || '');

    useEffect(() => {
        setMediaView(dialog?.view || '')
    }, [dialog]);

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 800,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    zIndex: 60,
                    bgColor: '#062860',
                    margin: [(1280 - 800) / 2, 0, 0, (720 - 640) / 2]
                }}
            >
                {mediaView === 'upload' ?
                    <MediaDialogUpload
                        sdk={sdk}
                        dialog={dialog}
                    />
                    : null}
                {mediaView === 'pick' ?
                    <MediaDialogList
                        sdk={sdk}
                        dialog={dialog}
                    />
                    : null}
            </Container>
        </div>
    </div > : null;
});

export default MediaDialog;