import {
    observer
} from 'mobx-react-lite';
import { TextField, Container, Checkbox, PickMedia, IconButton } from '../../controls';

const AdsEditMediaNavigation = observer(({ sdk, store, i }: any) => {
    return <Container
        id={`ad-${i}-container`}
        containerOption={{
            width: '100%',
            heightPx: 80,
            margin: [16, 0, 0, 0],
            float: 'left'
        }}

    >
        <IconButton
            id={'remove'}
            source={'./data/icon/up.svg'}
            onClick={() => store.moveUp(i)}
            enableDelay={false}
            disabled={i === 0}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [8, 24, 0, 0],
                float: 'right',
                border: 'none'
            }}
        />
        <IconButton
            id={'remove'}
            source={'./data/icon/down.svg'}
            onClick={() => store.moveDown(i)}
            enableDelay={false}
            disabled={i === store.maxAdCount - 1}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [16, 24, 0, 0],
                float: 'right',
                border: 'none'
            }}
        />
    </Container>
});

const AdsEditMedia = observer(({ sdk, store, i }: any) => {
    return <Container
        id={`ad-${i}`}
        containerOption={{
            width: '100%',
            heightPx: 96,
            margin: [16, 0, 0, 0],
            float: 'left'
        }}

    >
        <Checkbox
            id={`ad-${i}-checkbox`}
            value={store.getAdState(i)}
            onChange={() => store.toggleAdState(i)}
            // value={store.selectedAd === i}
            // onChange={() => store.selectAd(i)}
            containerOption={{
                float: 'left',
                widthPx: 80,
                margin: [8, 0, 0, 16]
            }}
            labelOption={{
                widthPx: 16
            }}
            buttonOption={{
                widthPx: 64,
                heightPx: 64
            }}
        />
        <PickMedia
            id={`ad-${i}-select`}
            containerOption={{
                widthPx: 460,
                margin: [0, 0, 8, 16],
                float: 'left',
                borderSize: 2,
                border: 'solid #fff'
            }}
            value={store.getAdImage(i)}
            onStoreValue={(val: any) => store.setAdImage(i, val)}
            allowUri={true}
            mediaType={'image'}
            // defaultMedia={SoundList}
            defaultValue={'- Disabled -'}
        />
        <Checkbox
            id={`ad-${i}-enabled`}
            value={store.selectedAd === i}
            onChange={() => store.selectAd(i)}
            containerOption={{
                float: 'left',
                widthPx: 60,
                margin: [22, 0, 0, 12]
            }}
            checkboxImages={['icon/unmove', 'icon/move']}
            labelOption={{
                widthPx: 16
            }}
            buttonOption={{
                widthPx: 48,
                heightPx: 48
            }}
        />
    </Container>
});

const AdsEdit = observer(({ sdk, store }: any) => {

    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 48,
                    fontWeight: 'bold'
                }}
            >
                Advertising Editor
            </Container>
            <Checkbox
                id={'rebuy'}
                store={store}
                storeProperty={'enableAds'}
                label={'Enable Ads'}
                containerOption={{
                    float: 'left',
                    widthPx: 440
                }}
                labelOption={{
                    widthPx: 310
                }}
            />
            {store.enableAds ? (
                <>
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        Slideshow Speed ( seconds )
                    </Container>
                    <TextField
                        id={'general-h1-label'}
                        maxLength={60}
                        inputType={'number'}
                        inputOption={{
                            widthPx: 500,
                            margin: [0, 0, 16, 50],
                            float: 'left',
                            borderSize: 2,
                            border: 'solid #fff'
                        }}
                        store={store}
                        numberCleanFloat={true}
                        storeProperty={'adRotateTime'}
                    />
                    <Container
                        containerOption={{
                            heightPx: 64,
                            lineHeight: 64,
                            widthPx: 680,
                            // margin: [16, 0, 0, 64],
                            float: 'left',
                            // textAlign: 'left',
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}
                    >
                        Ads
                    </Container>
                    {Array.from({ length: store.maxAdCount }).map((_o: any, i: number) => (
                        <>
                            {store.selectedAd === i ? <AdsEditMediaNavigation sdk={sdk} i={i} store={store} key={`ad-${i}-navigation`} /> : null}
                            <AdsEditMedia sdk={sdk} i={i} store={store} key={`ad-${i}`} />
                        </>
                    ))}
                </>
            ) : null}

        </Container>
    </>

});

export default AdsEdit;