import {
    observer
} from 'mobx-react-lite';
import {
    buildMediaUrl
} from '../../../utils/helper'
import getSdk from '../../../api'
import Icon from './Icon';

const PickMedia = observer(({
    id,
    containerOption,
    iconOption,
    store = undefined,
    storeProperty = undefined,
    allowUri = false,
    mediaType = null,
    onStoreValue = null,
    value = null,
    defaultValue = '',
    defaultMedia = []
}: any) => {
    const sdk = getSdk();
    const useStyles = sdk.layoutManager.styles
    const containerProps = useStyles({
        // padding: [16, 48, 16, 48],
        fontSize: 32,
        float: 'left',
        lineHeight: 80,
        widthPx: 590,
        heightPx: 80,
        bgColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: 16,
        wordWrap: 'break-all',
        overflow: 'hidden',
        textAlign: 'left',
        ...(containerOption || {})
    });
    let pickerValue = defaultValue || '';

    let initialValue = '';
    let pickerIcon = './data/icon/select.svg';

    if (store && storeProperty) {
        if (Array.isArray(storeProperty)) {
            pickerValue = store[storeProperty[0]][storeProperty[1]];
        } else {
            pickerValue = store[storeProperty];
        }

        initialValue = pickerValue;
    }

    if (value) {
        pickerValue = value;
        initialValue = value;
    }

    // Convert Data
    if (pickerValue.includes('media::')) {
        pickerValue = pickerValue.replace('media::', '');
        const splittedValue = pickerValue.split('>');
        const useLicense = sdk?.user?.account?.currentLicense || null;
        if (useLicense?.media) {
            const foundMedia = useLicense.media.getMedia(splittedValue[1])
            pickerValue = foundMedia?.name || splittedValue[1];

            pickerIcon = buildMediaUrl(foundMedia, true, true) || pickerIcon;
        }
    }

    return <div
        id={id}
        style={containerProps}
        onClick={() => sdk.openMediaDialog(
            false,
            (file: string) => {
                if (file || file === '') {
                    // Update the statepath
                    if (store && storeProperty) {
                        if (Array.isArray(storeProperty)) {
                            if (store?.updateArrayValue) {
                                store.updateArrayValue(storeProperty[0], storeProperty[1], file)
                            }
                        } else {
                            if (store?.updateValue) {
                                store.updateValue(storeProperty, file)
                            }
                        }
                    }

                    if (onStoreValue) {
                        onStoreValue(file);
                    }
                }
            },
            initialValue,
            defaultMedia,
            mediaType,
            allowUri
        )}
    >
        <Icon
            source={pickerIcon}
            iconOption={{
                widthPx: 64,
                heightPx: 64,
                float: 'left',
                margin: [8, 16, 0, 8],
                ...(iconOption || {})
            }}
        />
        {pickerValue || defaultValue}
    </div>
});


export default PickMedia;