export const BLIND_TEMPLATES: any = [
    {
        name: '50K Startstack',
        value: {
            "anteType": 2,
            "data": [
                [
                    0,
                    20,
                    100,
                    200,
                    200,
                    ""
                ],
                [
                    0,
                    20,
                    100,
                    300,
                    300,
                    ""
                ],
                [
                    0,
                    20,
                    200,
                    400,
                    400,
                    ""
                ],
                [
                    0,
                    20,
                    300,
                    600,
                    600,
                    ""
                ],
                [
                    0,
                    20,
                    400,
                    800,
                    800,
                    ""
                ],
                [
                    0,
                    20,
                    500,
                    1000,
                    1000,
                    ""
                ],
                [
                    0,
                    20,
                    500,
                    1500,
                    1500,
                    ""
                ],
                [
                    0,
                    20,
                    1000,
                    2000,
                    2000,
                    ""
                ],
                [
                    0,
                    20,
                    1500,
                    3000,
                    3000,
                    ""
                ],
                [
                    0,
                    20,
                    2000,
                    4000,
                    4000,
                    ""
                ],
                [
                    0,
                    20,
                    3000,
                    6000,
                    6000,
                    ""
                ],
                [
                    0,
                    20,
                    4000,
                    8000,
                    8000,
                    ""
                ],
                [
                    0,
                    20,
                    5000,
                    10000,
                    10000,
                    ""
                ],
                [
                    0,
                    20,
                    6000,
                    12000,
                    12000,
                    ""
                ],
                [
                    0,
                    20,
                    8000,
                    16000,
                    16000,
                    ""
                ],
                [
                    0,
                    20,
                    10000,
                    20000,
                    20000,
                    ""
                ],
                [
                    0,
                    20,
                    15000,
                    30000,
                    30000,
                    ""
                ],
                [
                    0,
                    20,
                    20000,
                    40000,
                    40000,
                    ""
                ],
                [
                    0,
                    20,
                    25000,
                    50000,
                    50000,
                    ""
                ],
                [
                    0,
                    20,
                    30000,
                    60000,
                    60000,
                    ""
                ],
                [
                    0,
                    20,
                    40000,
                    80000,
                    80000,
                    ""
                ],
                [
                    0,
                    20,
                    50000,
                    100000,
                    100000,
                    ""
                ]
            ]
        }
    },
    {
        name: 'Savage Average 50k',
        value: {
            "anteType": 2,
            "mattMode": true,
            "data": [
                [
                    0,
                    10,
                    500,
                    1000,
                    1000,
                    "",
                    60000
                ],
                [
                    0,
                    10,
                    600,
                    1200,
                    1200,
                    "",
                    80000
                ],
                [
                    0,
                    10,
                    800,
                    1600,
                    1600,
                    "",
                    100000
                ],
                [
                    0,
                    10,
                    1000,
                    2000,
                    2000,
                    "",
                    125000
                ],
                [
                    0,
                    10,
                    1500,
                    2500,
                    2500,
                    "",
                    150000
                ],
                [
                    0,
                    10,
                    1500,
                    3000,
                    3000,
                    "",
                    200000
                ],
                [
                    0,
                    10,
                    2000,
                    4000,
                    4000,
                    "",
                    250000
                ],
                [
                    0,
                    10,
                    2500,
                    5000,
                    5000,
                    "",
                    300000
                ],
                [
                    0,
                    10,
                    3000,
                    6000,
                    6000,
                    "",
                    400000
                ],
                [
                    0,
                    10,
                    4000,
                    8000,
                    8000,
                    "",
                    500000
                ],
                [
                    0,
                    10,
                    5000,
                    10000,
                    10000,
                    "",
                    600000
                ],
                [
                    0,
                    10,
                    6000,
                    12000,
                    12000,
                    "",
                    800000
                ],
                [
                    0,
                    10,
                    8000,
                    16000,
                    16000,
                    "",
                    1000000
                ],
                [
                    0,
                    10,
                    10000,
                    20000,
                    20000,
                    "",
                    1250000
                ],
                [
                    0,
                    10,
                    15000,
                    25000,
                    25000,
                    "",
                    1500000
                ],
                [
                    0,
                    10,
                    15000,
                    30000,
                    30000,
                    "",
                    2000000
                ],
                [
                    0,
                    10,
                    20000,
                    40000,
                    40000,
                    "",
                    2500000
                ],
                [
                    0,
                    10,
                    25000,
                    50000,
                    50000,
                    "",
                    3000000
                ],
                [
                    0,
                    10,
                    30000,
                    60000,
                    60000,
                    "",
                    4000000
                ],
                [
                    0,
                    10,
                    40000,
                    80000,
                    80000,
                    "",
                    5000000
                ],
                [
                    0,
                    10,
                    50000,
                    100000,
                    100000,
                    "",
                    6250000
                ],
                [
                    0,
                    10,
                    75000,
                    125000,
                    125000,
                    "",
                    7500000
                ],
                [
                    0,
                    10,
                    75000,
                    150000,
                    150000,
                    "",
                    10000000
                ],
                [
                    0,
                    10,
                    100000,
                    200000,
                    200000,
                    "",
                    12500000
                ],
                [
                    0,
                    10,
                    125000,
                    250000,
                    250000,
                    "",
                    15000000
                ],
                [
                    0,
                    10,
                    150000,
                    300000,
                    300000,
                    "",
                    20000000
                ],
                [
                    0,
                    10,
                    200000,
                    400000,
                    400000,
                    "",
                    25000000
                ],
                [
                    0,
                    10,
                    250000,
                    500000,
                    500000,
                    "",
                    30000000
                ],
                [
                    0,
                    10,
                    300000,
                    600000,
                    600000,
                    "",
                    40000000
                ],
                [
                    0,
                    10,
                    400000,
                    800000,
                    800000,
                    "",
                    50000000
                ],
                [
                    0,
                    10,
                    500000,
                    1000000,
                    1000000,
                    "",
                    60000000
                ],
                [
                    0,
                    10,
                    600000,
                    1200000,
                    1200000,
                    "",
                    80000000
                ]
            ],
            "pause": [
                [
                    20,
                    120
                ]
            ]
        }
    }
]