import {
    makeObservable,
    observable,
    action,
    computed
} from 'mobx'
import type {
    CouchgamesSdk
} from './index';
import { buildMediaUrl, timestampMs, uuidv4 } from '../utils/helper';

const shouldUpdate = (latestTime: number, duration: number) => {
    if ((timestampMs() - latestTime) >= duration) {
        return [true, timestampMs()];
    }

    return [false, latestTime];
}

const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}
class AccountRoleForm {
    public newrole: boolean;

    public mail: string;
    public nickname: string;
    public roleSuperAdmin: number;
    public roleAdmin: number;
    public roleCustom: number;
    public rolePermList: Array<string>;

    // public role: string;
    public permission: Array<string>;

    constructor(role: any, user: User) {
        makeObservable(this, {
            newrole: observable,
            mail: observable,
            nickname: observable,
            roleSuperAdmin: observable,
            roleAdmin: observable,
            roleCustom: observable,
            permission: observable,
            reset: action,
            updateValue: action,
            removePermission: action,
            addPermission: action,
            role: computed,
            allPermission: computed,
            isNewRole: computed,
            isValid: computed
        })

        this.newrole = !role;
        this.mail = role?.user?.email;
        this.nickname = role?.user?.nickname;

        this.rolePermList = user.roles.availableRolePermission;

        this.roleAdmin = role?.role === 'ADMIN' ? 1 : 0;
        this.roleSuperAdmin = role?.role === 'SUPER_ADMIN' ? 1 : 0;
        this.roleCustom = (!role || role?.role) === 'CUSTOM' || (!user.hasPermission('SUPERADMIN.ALL') && !user.hasPermission('ADMIN.ALL')) ? 1 : 0;

        // this.role = '';
        this.permission = (role?.permission || []).filter((x: any) => x !== 'SUPERADMIN.ALL' || x !== 'ADMIN.ALL');
    }

    public removePermission(perm: string): boolean {
        if (this.hasPermission(perm)) {
            this.permission = this.permission.filter((p: string) => p !== perm);
        }
        return false;
    }

    public addPermission(perm: string): boolean {
        const splittedPerm: Array<string> = perm.split('.');

        if (splittedPerm?.[1] === 'ALL' && this.rolePermList) {
            this.rolePermList.forEach((pp: string) => {
                if (pp.includes(`${splittedPerm[0]}.`) && !pp.includes('ALL')) {
                    this.addPermission(pp)
                }
            })

            if (!this.hasPermission(perm)) {
                this.permission.push(perm);
            }

        } else if (!this.hasPermission(perm)) {
            this.permission.push(perm);
        }
        return false;
    }

    public hasPermission(perm: string): boolean {
        if (this.roleAdmin === 1 || this.roleSuperAdmin === 1) return true;
        return !!this.permission.find((p: string) => p === perm)
    }

    public updateRole(valueName: string): void {
        if (valueName === 'roleSuperAdmin') {
            this.roleAdmin = 0;
            this.roleCustom = 0;
        } else if (valueName === 'roleAdmin') {
            this.roleSuperAdmin = 0;
            this.roleCustom = 0;
        } else if (valueName === 'roleCustom') {
            this.roleAdmin = 0;
            this.roleSuperAdmin = 0;
        }

        if (this.roleAdmin === 0 && this.roleSuperAdmin === 0 && this.roleCustom === 0) {
            this.roleCustom = 1;
        }
    }

    public reset(): void {
        this.mail = '';
        this.nickname = '';
        // this.role = '';
        this.permission = [];
        this.roleAdmin = 0;
        this.roleSuperAdmin = 0;
        this.roleCustom = 0;
    }

    public updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
        this.updateRole(valueName);
    }

    get allPermission() {
        if (this.roleCustom === 1) {
            return this.permission;
        }
        return undefined;
    }

    get isValid() {
        return (this.roleAdmin === 1 || this.roleSuperAdmin === 1 || this.roleCustom === 1) &&
            emailIsValid(this.mail) &&
            (this.roleCustom === 0 ? true : this.permission.length > 0)
    }

    get isNewRole() {
        return this.newrole;
    }

    get role() {
        if (this.roleSuperAdmin) {
            return 'SUPER_ADMIN'
        }
        if (this.roleAdmin) {
            return 'ADMIN'
        }
        return 'CUSTOM';
    }
}

class AccountForm {
    public mail: string;
    public mail2: string;
    public activationcode: string;
    public term: number;
    public newsletter: number;
    public form: string;

    constructor() {
        makeObservable(this, {
            form: observable,
            mail: observable,
            mail2: observable,
            activationcode: observable,
            term: observable,
            newsletter: observable,
            updateValue: action,
            switchBack: action,
            reset: action,
            switchToRegister: action,
            isEmailValid: computed,
            isRegisterPossible: computed,
            isLoginForm: computed,
            isRegisterForm: computed,
            isActivationForm: computed,
            label: computed
        })

        this.form = 'login';
        this.mail = '';
        this.mail2 = '';
        this.activationcode = '';
        this.term = 0;
        this.newsletter = 0;
        this.form = 'login';
    }

    public reset(): void {
        this.mail = '';
        this.mail2 = '';
        this.activationcode = '';
        this.term = 0;
        this.newsletter = 0;
        this.form = 'login';
    }

    public updateValue(valueName: string, value: any): void {
        // @ts-ignore
        this[valueName] = value;
    }

    public switchToRegister(): void {
        this.form = 'register';
    }

    public switchBack(): void {
        this.form = 'login';
    }

    get isEmailValid() {
        return emailIsValid(this.mail)
    }

    get isRegisterPossible() {
        return this.isEmailValid && this.mail2 === this.mail && this.term === 1;
    }

    get isLoginForm() {
        return this.form === 'login';
    }

    get isActivationForm() {
        return this.form === 'activate';
    }

    get isRegisterForm() {
        return this.form === 'register';
    }

    get label() {
        if (this.form === 'login') {
            return 'Login'
        }
        if (this.form === 'register') {
            return 'Register'
        }
        return ''
    }
}

class LicenseMedia {
    public mediaList: Array<any>;
    public mediaHash: string | null;

    constructor() {
        makeObservable(this, {
            mediaList: observable,
            mediaHash: observable,
            updateMediaList: action,
            allImage: computed,
            allMedia: computed,
            allAudio: computed
        })
        this.mediaList = [];
        this.mediaHash = null;
    }

    public getMedia(mediaUri: string): any {
        return this.mediaList.find((media: any) => media.url === mediaUri);
    }

    get allMedia() {
        return this.mediaList.map((file: any) => ({
            ...file,
            mediaUrl: buildMediaUrl(file),
            mediaPreviewUrl: buildMediaUrl(file, true)
        }))
    }

    get allImage() {
        return this.allMedia.filter((file: any) => file.type.includes('image'));
    }

    get allAudio() {
        return this.allMedia.filter((file: any) => file.type.includes('audio'));
    }

    public updateMediaList(mediaResponse: any): void {
        if (mediaResponse?.hash) {
            this.mediaHash = mediaResponse.hash;
        }
        if (mediaResponse?.media) {
            this.mediaList = mediaResponse.media;
        }
    }
}
class License {
    public config: any;
    public roles: any;
    public media: LicenseMedia;

    constructor(config: any) {
        makeObservable(this, {
            config: observable,
            roles: observable,
            updateRoleList: action,
            updateLicense: action,
            deleteTemplate: action,
            updateTemplate: action,
            updateTemplateName: action,
            licenseId: computed,
            secret: computed,
            allRoles: computed,
            permission: computed,
            hashMedia: computed
        })
        this.config = config;
        this.media = new LicenseMedia();
    }

    get hashMedia() {
        return this.config?.hashMedia || null;
    }

    public deleteTemplate(templateType: string, templateId: string): void {
        if (this.config?.templates?.[templateType]?.[templateId]) {
            let newObj: any = {};
            Object.entries(this.config?.templates?.[templateType]).forEach(([id, value]) => {
                if (id !== templateId) {
                    newObj[id] = value;
                }
            })
            this.config = {
                ...this.config,
                templates: {
                    ...this.config.templates,
                    [templateType]: newObj
                }
            }
        }
    }

    public updateTemplate(type: string, data: any): void {
        this.config.templates[type] = data;
    }

    public updateTemplateName(templateType: string, templateId: string, templateName: string): void {
        this.config = {
            ...this.config,
            templates: {
                ...this.config.templates,
                [templateType]: {
                    ...this.config.templates[templateType],
                    [templateId]: {
                        id: templateId,
                        name: templateName
                    }
                }
            }
        }
    }

    public getTemplateList(type: string): any {
        const tmpList: any = Object.entries(this.config?.templates?.[type] || {}).map(([templateId, template]: any): any => {
            return {
                id: templateId,
                name: template.name,
                hash: template.hash
            }
        })
        const totalCapacity: number = this.config.capacity[type];
        const usedCapacity: number = tmpList.length;

        return {
            templates: tmpList,
            total: totalCapacity,
            free: totalCapacity - usedCapacity,
            used: usedCapacity
        }
    }

    public updateLicense(config: any): void {
        this.config = config;
    }

    public updateRoleList(roles: any): void {
        this.roles = roles;
    }

    public isSameLicense(licenseId: string): boolean {
        return this.licenseId === licenseId;
    }

    get hasTemplateTournament() {
        return this.getTemplateList('tournament')?.used > 0;
    }

    get allRoles() {
        return this.roles || [];
    }

    get licenseName() {
        return this.config?.licenseName || '';
    }

    get secret() {
        return this.config?.secret || '';
    }

    get licenseId() {
        return this.config?.licenseId || '';
    }

    get permission() {
        return this.config?.permission || [];
    }

}

class Account {
    public sdk: CouchgamesSdk;
    public nickname: string;
    public mail: string;
    public token: string | null;
    public loginForm: any;
    public roleForm: any;
    public currentLicense: License | null;
    public licenseList: Array<any>;
    public sessionList: Array<any>;

    constructor(sdk: CouchgamesSdk) {
        makeObservable(this, {
            nickname: observable,
            mail: observable,
            sessionList: observable,
            loginForm: observable,
            roleForm: observable,
            token: observable,
            licenseList: observable,
            currentLicense: observable,
            openRoleForm: action,
            changeLicense: action,
            changeUserName: action,
            removeSessionFromList: action,
            updateSessionList: action,
            reset: action,
            updateUser: action,
            hasLicense: computed,
            label: computed,
            store: computed,
            loggedIn: computed,
            secret: computed,
            permission: computed
        })

        this.sdk = sdk;

        this.nickname = '';
        this.mail = '';
        this.token = null;
        this.currentLicense = null;
        this.licenseList = [];
        this.sessionList = [];
        this.loginForm = new AccountForm();
        this.roleForm = null;
    }

    get secret() {
        return this.currentLicense?.secret || '';
    }

    get permission() {
        return this.currentLicense?.permission || [];
    }


    public changeLicense(license: any): void {
        this.currentLicense = new License(license);
        this.sessionList = [];
        this.licenseList = this.licenseList.map((license: any) => {
            if (license?.id === this.currentLicense?.licenseId) {
                return {
                    ...license,
                    invite: undefined
                }
            }
            return license;
        })
    }

    public changeUserName(name: string): void {
        this.nickname = name;
    }

    public updateUser(data: any): void {
        this.nickname = data?.user?.nickname;
        this.mail = data?.user?.email;
        this.token = data?.userToken;
        this.licenseList = data?.user?.licenses || [];

        // Check if the license is active and update it if necessary
        if (data?.activeLicense) {
            if (this.currentLicense?.isSameLicense(data.activeLicense.licenseId)) {
                this.currentLicense.updateLicense(data.activeLicense)
            } else {
                this.currentLicense = null;
                this.currentLicense = new License(data.activeLicense)
            }
        } else {
            this.currentLicense = null;
        }
    }

    public updateSessionList(sessions: any): void {
        this.sessionList = sessions;
    }

    public removeSessionFromList(code: string): void {
        this.sessionList = this.sessionList.filter((session: any) => session.code !== code)
    }

    public openRoleForm(role: any = null): void {
        this.roleForm = new AccountRoleForm(role, this.sdk.user);
    }

    public reset(): void {
        this.nickname = '';
        this.mail = '';

        this.token = null;
        this.currentLicense = null;
        this.licenseList = [];
        this.sessionList = [];
        this.loginForm = new AccountForm();
        this.roleForm = null;
    }

    get loggedIn() {
        return this.token && this.mail;
    }

    get hasLicense() {
        return !!this.currentLicense;
    }

    get label() {
        return `${this.nickname} ${this.currentLicense?.licenseName ? `(${this.currentLicense?.licenseName})` : ''}`
    }

    get store() {
        return {
            user: {
                nickname: this.nickname,
                email: this.mail,
                licenses: this.licenseList
            },
            userToken: this.token,
            activeLicense: this.currentLicense?.config || null
        }
    }

}

class RoleConfig {
    public rolePermission: Array<string>;

    constructor() {
        makeObservable(this, {
            rolePermission: observable,
            availableRolePermission: computed
        })
        this.rolePermission = [];
    }

    public updateRolePermissionList(list: any): void {
        this.rolePermission = list;
    }

    get availableRolePermission() {
        return this.rolePermission;
    }
}
class TemplateStorage {
    public templates: Map<string, any>;

    constructor() {
        this.templates = new Map();
    }
}

export default class User {
    public sdk: CouchgamesSdk;
    public account: Account;
    public tmpStorage: TemplateStorage;

    public roles: RoleConfig;
    public latestSessionRequest: number;
    public latestUserRequest: number;
    public latestMediaRequest: number;

    public deviceId: string;

    constructor(sdk: CouchgamesSdk) {
        makeObservable(this, {
            account: observable,
            roles: observable,
            label: computed,
            loggedIn: computed
        })

        this.roles = new RoleConfig();
        this.account = new Account(sdk);
        this.sdk = sdk;
        this.latestSessionRequest = 0;
        this.latestMediaRequest = 0;
        this.latestUserRequest = 0;
        this.tmpStorage = new TemplateStorage();
        this.deviceId = this.createClientToken();

        this.load();
    }

    createClientToken(): string {
        let uuidAuthToken = this.sdk.loadFromStorage('auth')?.token || '';

        if (!uuidAuthToken) {
            uuidAuthToken = uuidv4();
            this.sdk.saveInStorage('auth', {
                token: uuidAuthToken
            });
        }
        return uuidAuthToken;
    }

    get loggedIn() {
        return this.account?.loggedIn;
    }

    get premiumUser() {
        return this.loggedIn && this.account.currentLicense;
    }

    get label() {
        return this.loggedIn ?
            this.account?.label :
            'Login';
    }

    public async fetchUser(force: boolean = false): Promise<void> {
        if (!this.loggedIn) {
            return;
        }
        const updatePossible: any = shouldUpdate(this.latestUserRequest, 20000)
        this.latestUserRequest = updatePossible[1];
        if (updatePossible[0] === false && force !== true) {
            return;
        }

        const getUser = await this.sdk.fetchApi({
            userToken: this.account.token || ''
        }, 'GET', 'user/')

        if (getUser?.status === 200) {
            this.account.updateUser(getUser.json)
            this.save()
        }
    }

    public async logoutUser(): Promise<boolean> {

        // Reset Account
        this.account.reset();

        // Clear storage
        this.save(true);

        return true;
    }

    public async loginUser(): Promise<boolean> {
        if (this.account.loginForm.mail) {
            const loginResult = await this.sdk.fetchApi({
                email: this.account.loginForm.mail,
                activationcode: this.account.loginForm.activationcode || undefined
            }, 'PUT', 'user/')

            if (loginResult?.status === 200) {
                this.account.loginForm.reset();
                this.account.updateUser(loginResult.json)
                this.save()
                return true;
            } else if (loginResult?.status === 201) {
                this.account.loginForm.form = 'activate';
                this.onHandleSuccess();
                return true;
            } else if (loginResult?.status === 400) {
                this.account.loginForm.reset();

                this.onHandleError(loginResult?.json?.errorName || 'UnknownError')
                return true;
            }
        }
        return false;
    }

    public async registerUser(): Promise<void> {
        // 1) Check if emal is the same like email 2
        // 2) Check if term is enabled
        const registerResult = await this.sdk.fetchApi({
            email: this.account.loginForm.mail,
            term: this.account.loginForm.term === 1,
            newsletter: this.account.loginForm.newsletter === 1
        }, 'POST', 'user/')

        // There is already a mail registered, try to login
        if (registerResult?.status === 200) {

            // Try to login
            if (await this.loginUser() === false) {
                this.account.loginForm.reset();
            }
        } else if (registerResult?.status === 201) {
            this.account.loginForm.form = 'activate';
            this.onHandleSuccess();
        }

    }

    public async changeUserName(nickname: string): Promise<void> {
        // 1) Check if emal is the same like email 2
        // 2) Check if term is enabled
        const changeResult = await this.sdk.fetchApi({
            userToken: this.account.token,
            action: 'nickname',
            value: nickname
        }, 'PATCH', 'user/')

        // There is already a mail registered, try to login
        if (changeResult?.status === 200) {
            if (changeResult?.json?.nickname) {
                this.account.changeUserName(changeResult.json.nickname)
            }
        }

    }

    public async changeUserLicense(licenseId: string): Promise<void> {
        const changeResult = await this.sdk.fetchApi({
            userToken: this.account.token,
            action: 'selectlicense',
            value: licenseId
        }, 'PATCH', 'user/')

        // There is already a mail registered, try to login
        if (changeResult?.status === 200) {
            if (changeResult?.json?.activeLicense) {
                this.account.changeLicense(changeResult.json.activeLicense)
            }
        }
    }

    public async deleteSession(code: string): Promise<boolean> {
        const deleteResult = await this.sdk.fetchApi({
            userToken: this.account.token,
            userSecret: this.account.secret,
            licenseToken: this.account?.currentLicense?.licenseId || undefined,
            code
        }, 'DELETE', 'session/')

        // There is already a mail registered, try to login
        if (deleteResult?.status === 200) {
            this.account.removeSessionFromList(code);
            return true;
        }
        return false;
    }

    /**
     * Fetch the current list and cache it for x seconds
     * @param licenseId 
     */
    public async fetchSessionList(licenseId: string | undefined = undefined, force: boolean = false): Promise<void> {
        const updatePossible: any = shouldUpdate(this.latestSessionRequest, 5000)
        this.latestSessionRequest = updatePossible[1];
        if (updatePossible[0] === false && force !== true) {
            return;
        }

        const fetchResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: licenseId || this.account?.currentLicense?.licenseId || undefined
        }, 'GET', 'session/')

        if (fetchResult?.status === 200) {
            if (fetchResult?.json?.sessions) {
                this.account.updateSessionList(fetchResult.json.sessions);
            }
        }
    }

    public async fetchRoleForUser(licenseId: string, targetUser: string): Promise<any> {
        const fetchResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: licenseId || this.account?.currentLicense?.licenseId || undefined,
            targetUser
        }, 'GET', 'role/')

        if (fetchResult?.status === 200) {
            return fetchResult?.json?.role || null;
        }
        return null;
    }

    public async loadTemplateWithCode(templateType: string, code: string): Promise<any> {
        const fetchTemplate = await this.sdk.fetchApi({
            userToken: this.account?.token || 'kslaopyfkgilmfvveyxlvwrglkksdyvnuttlzzkrxfheoeho',
            licenseToken: 'templatecode',
            templateId: code,
            templateType
        }, 'GET', 'template/');

        if (fetchTemplate?.status === 200 && fetchTemplate?.json?.template) {
            return fetchTemplate?.json?.template || null;
        }
        return null;
    }

    public async loadTemplate(templateType: string, templateId: string, templateHash: any): Promise<any> {
        const tmpName = `${templateType}${templateId}`;
        const tmpFromStorage = await this.sdk.loadFromLocalStorage(tmpName);

        if (tmpFromStorage && tmpFromStorage?.hash === templateHash && tmpFromStorage.template) {
            return tmpFromStorage.template;
        }

        const fetchTemplate = await this.sdk.fetchApi({
            userToken: this.account?.token,
            licenseToken: this.account?.currentLicense?.licenseId || undefined,
            templateId,
            templateType
        }, 'GET', 'template/');

        if (fetchTemplate?.status === 200 && fetchTemplate?.json?.template) {
            this.sdk.saveToLocalStorage(tmpName, {
                hash: templateHash,
                template: fetchTemplate.json.template
            });
            return fetchTemplate?.json?.template || null;
        }
        return null;
    }

    public async deleteTemplate(templateType: string, templateId: string): Promise<boolean> {
        const deleteResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: this.account?.currentLicense?.licenseId || undefined,
            templateType,
            templateId
        }, 'DELETE', 'template/')

        if (deleteResult?.status === 200) {
            if (this.account.currentLicense) {
                this.account.currentLicense.deleteTemplate(templateType, templateId)
            }
            this.sdk.deleteFromStorage(`${templateType}${templateId}`);
            this.sdk.openMessageDialog(
                'Template deleted',
                'You deleted the template successfully',
                null,
                null
            )
            return true;
        } else if (deleteResult?.status === 400) {
            this.onHandleError(deleteResult.json.errorName)
        }
        return false;
    }

    public async saveTemplate(type: string, filename: string, data: any): Promise<boolean> {
        const saveResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: this.account?.currentLicense?.licenseId || undefined,
            templateType: type,
            templateName: filename,
            templateData: data
        }, 'POST', 'template/')

        if (saveResult?.status === 201 && this.account.currentLicense) {
            this.account.currentLicense.updateTemplate(type, saveResult.json.updatedData)
            this.sdk.openMessageDialog(
                'Template saved',
                'You saved the template successfully',
                null,
                null
            )
            return true;
        } else if (saveResult?.status === 400) {
            this.onHandleError(saveResult.json.errorName)
        }
        return false;
    }

    public async updateTemplate(templateType: string, templateId: string, templateData: any, newFileName: string): Promise<boolean> {
        const saveResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: this.account?.currentLicense?.licenseId || undefined,
            templateId,
            templateType,
            templateName: newFileName || undefined,
            templateData
        }, 'PUT', 'template/')

        if (saveResult?.status === 200) {
            if (saveResult?.json?.templateName && saveResult?.json?.templateId && this.account.currentLicense) {
                this.account.currentLicense.updateTemplateName(templateType, saveResult.json.templateId, saveResult.json.templateName) // here
            }
            this.sdk.deleteFromStorage(`${templateType}${templateId}`);
            this.sdk.openMessageDialog(
                'Template updated',
                'You updated the template successfully',
                null,
                null
            )
            return true;
        } else if (saveResult?.status === 400) {
            this.onHandleError(saveResult.json.errorName)
        }
        // return null;
        return false;
    }

    public async fetchRoleList(licenseId: string | undefined = undefined): Promise<void> {
        const fetchResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: licenseId || this.account?.currentLicense?.licenseId || undefined
        }, 'GET', 'role/')

        if (fetchResult?.status === 200) {
            if (fetchResult?.json?.permissions) {
                this.roles.updateRolePermissionList(fetchResult.json.permissions)
            }
            if (fetchResult?.json?.roles) {
                this.account?.currentLicense?.updateRoleList(fetchResult.json.roles)
            }
        }
    }

    public async acceptInvite(licenseId: string | undefined = undefined, role: string | undefined = undefined): Promise<boolean> {
        const acceptResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            licenseToken: licenseId,
            role
        }, 'PATCH', 'role/')

        // If the decline result was successfull, remove this license from the list
        if (acceptResult?.status === 200) {
            return true;
        } else if (acceptResult?.status === 400) {
            this.onHandleError(acceptResult.json.errorName);
        }
        return false;
    }

    public async declineInvite(licenseId: string | undefined = undefined, role: string | undefined = undefined): Promise<boolean> {
        const declineResult = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: licenseId,
            role
        }, 'DELETE', 'role/')

        // If the decline result was successfull, remove this license from the list
        if (declineResult?.status === 200) {
            return true;
        } else if (declineResult?.status === 400) {
            this.onHandleError(declineResult.json.errorName);
        }
        return false;
    }

    public async deleteRole(targetUser: string, role: string | undefined = undefined): Promise<boolean> {
        const deleteRole = await this.sdk.fetchApi({
            userToken: this.account?.token,
            userSecret: this.account?.secret,
            licenseToken: this.account?.currentLicense?.licenseId || undefined,
            role,
            targetUser
        }, 'DELETE', 'role/')

        // If the decline result was successfull, remove this license from the list
        if (deleteRole?.status === 200) {
            return true;
        } else if (deleteRole?.status === 400) {
            this.onHandleError(deleteRole.json.errorName);
        }
        return false;
    }

    public async updateRole(): Promise<boolean> {
        if (this.account?.roleForm?.isValid) {
            const updateRoleResult = await this.sdk.fetchApi({
                userToken: this.account?.token,
                userSecret: this.account?.secret,
                licenseToken: this.account?.currentLicense?.licenseId || undefined,
                mail: this.account.roleForm.mail,
                role: this.account.roleForm.role,
                permission: this.account.roleForm.allPermission
            }, 'PUT', 'role/')

            if (updateRoleResult?.status === 201) {
                this.sdk.openMessageDialog(
                    'Role updated',
                    'You updated the role successfully',
                    null,
                    null
                )

                return true;
            } else if (updateRoleResult?.status === 400) {
                this.onHandleError(updateRoleResult.json.errorName);
            }
        }
        return false;
    }

    public async createRole(): Promise<boolean> {
        if (this.account?.roleForm?.isValid) {
            const addRoleResult = await this.sdk.fetchApi({
                userToken: this.account?.token,
                userSecret: this.account?.secret,
                licenseToken: this.account?.currentLicense?.licenseId || undefined,
                mail: this.account.roleForm.mail,
                role: this.account.roleForm.role,
                permission: this.account.roleForm.allPermission
            }, 'POST', 'role/')

            if (addRoleResult?.status === 201) {
                this.sdk.openMessageDialog(
                    'Role created',
                    'You created the role successfully',
                    null,
                    null
                )

                return true;
            } else if (addRoleResult?.status === 400) {
                this.onHandleError(addRoleResult.json.errorName);
            }
        }

        return false;
    }

    public blobToFile(theBlob: any, fileName: any) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    public async requestMedia(force:boolean = false): Promise<void> {
        if (this.account?.currentLicense) {
            const updatePossible: any = shouldUpdate(this.latestMediaRequest, 20000)
            this.latestMediaRequest = updatePossible[1];
            if (updatePossible[0] === false && force !== true) {
                return;
            }

            if (this.account.currentLicense.hashMedia !== this.account.currentLicense.media.mediaHash) {
                const fetchMedia = await this.sdk.fetchApi({
                    userToken: this.account?.token,
                    userSecret: this.account?.secret,
                    licenseToken: this.account?.currentLicense?.licenseId
                }, 'GET', 'media/')

                if (fetchMedia?.status === 200) {
                    this.account.currentLicense.media.updateMediaList(fetchMedia.json);
                }
            }
        }
    }

    public async updateMedia(media: any, newName: string): Promise<boolean> {
        if (this.loggedIn && this.account?.currentLicense) {
            const updateResult = await this.sdk.fetchApi({
                userToken: this.account?.token,
                userSecret: this.account?.secret,
                licenseToken: this.account?.currentLicense?.licenseId,
                mediaId: media.id,
                mediaType: media.type,
                mediaName: newName
            }, 'PUT', 'media/', 4000)

            if (updateResult?.status === 200) {
                this.account.currentLicense.media.updateMediaList(updateResult.json);
            }
        }
        return false;
    }

    public async deleteMedia(media: any): Promise<boolean> {
        if (this.loggedIn && this.account?.currentLicense) {
            const deleteResult = await this.sdk.fetchApi({
                res: 'formData',
                body: ''
            }, 'DELETE', '', 8000, `${process.env.REACT_APP_MEDIA_API}?userToken=${this.account?.token || ''}&userSecret=${this.account?.secret || ''}&licenseId=${this.account?.currentLicense?.licenseId || ''}&mediaType=${media.type}&mediaId=${media.id}`)

            if (deleteResult?.status === 200) {
                this.account.currentLicense.media.updateMediaList(deleteResult.json);
            }
        }
        return false;
    }

    public async uploadMedia(file: any, fileName: string): Promise<boolean> {
        if (this.loggedIn && this.account?.currentLicense) {

            const formData = new FormData();

            // formData.append('File', this.blobToFile(file, "avatar.jpg"));
            formData.append('File', file);
            formData.append('userToken', this.account?.token || '');
            formData.append('userSecret', this.account?.secret || '');
            formData.append('mediaName', fileName);
            formData.append('licenseId', this.account?.currentLicense?.licenseId);

            const uploadResult = await this.sdk.fetchApi(
                {
                    res: 'formData',
                    body: formData
                },
                'POST',
                '',
                30000,
                process.env.REACT_APP_MEDIA_API
            );

            if (uploadResult?.status === 201) {
                // this.sdk.openMessageDialog(
                //     'Role created',
                //     'You created the role successfully',
                //     null,
                //     null
                // )
                this.account.currentLicense.media.updateMediaList(uploadResult.json);
                return true;
            } else if (uploadResult?.status === 400) {
                this.onHandleError(uploadResult.json.errorName);
            }
        }
        return false;
    }

    public onHandleSuccess(type = 'activation'): void {
        if (type === 'activation') {
            this.sdk.openMessageDialog(
                'You got a mail from CouchGames.wtf',
                'Please check your email. you got a mail with a verification code',
                null,
                null,
                '',
                true
            )
        }
    }
    /**
     * Handle an error , getting from the api
     */
    public onHandleError(errorName: string): void {
        let title = '';
        let message = '';

        switch (errorName) {
            case 'UserNotCreated':
                title = 'User not created';
                message = 'There was an error during creating the user. Please try again!';
                break;
            case 'LoginFailed':
                title = 'Login failed';
                message = 'Your login failed, try again!';
                break;
            case 'RoleAlreadyExist':
                title = 'Role Exist';
                message = 'A role already exist for this user';
                break;
            case 'NoAccountFound':
                title = 'No Account found';
                message = 'There is no account for this email';
                break;
            case 'NoUserForAccount':
                title = 'No User';
                message = 'There is no user for this email. Please register this account first';
                break;
            case 'RoleCapacityReached':
                title = 'No role free';
                message = 'You reached the role capacity for this license';
                break;
            default:
                break;
        }

        this.sdk.openMessageDialog(
            title,
            message,
            null,
            null
        )
        return;
    }

    public openChangeNickname(): void {
        this.sdk.openStringDialog('Neuer Name', this.account.nickname, (newName: string) => {
            this.changeUserName(newName)
        }, 40, true)
    }

    public save(reset: boolean = false): void {
        this.sdk.saveToUserStorage('user', {
            account: reset ? null : this.account.store
        });
    }

    public async load() {
        const readUser = await this.sdk.loadFromUserStorage('user');
        if (readUser) {
            this.account.updateUser(readUser?.account);
            this.fetchUser(true);
        }
    }

    public hasPermission(perm: string, allowFreeUsage: boolean = false): boolean {
        if (this.loggedIn && this.account.permission) {
            if (perm === 'SUPERADMIN.ALL') {
                if ((this.account.permission || []).find((l: string) => l === perm)) {
                    return true;
                }
            } else if ((this.account.permission || []).find((l: string) => l === perm || l === 'ADMIN.ALL' || l === 'SUPERADMIN.ALL')) {
                return true;
            }

            // Maybe there is a all permission?
            const splittedRole: Array<string> = perm.split('.');
            if ((this.account.permission || []).find((l: string) => l === `${splittedRole[0]}.ALL`)) {
                return true;
            }
        } else if (!this.loggedIn && allowFreeUsage === true) {
            return true;
        }
        return false;
    }
}