import {
    observer
} from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import {
    Container,
    IconButton,
    TextField
} from '../controls/index';

const StringDialog = observer(({ sdk }: any) => {
    const {
        layoutManager
    } = sdk;

    const dialog = sdk?.dialog?.type === 'string' ? sdk.dialog : null;
    const dialogDelete = !!sdk?.dialog?.delete;
    const dialogKeyword = sdk?.dialog?.keyword;
    const dialogTitle = dialog?.title || '';
    const dialogDisableUpperCase = dialog?.disableUpperCase || false;
    const dialogMaxLength = dialog?.maxLength || 256;
    const allowSameValue = dialog?.allowSameValue || false;
    const [stringValue, setStringValue] = useState(dialog?.value || '');

    useEffect(() => {
        setStringValue(dialog?.value ? dialog.value : '')
    }, [dialog])

    return dialog ? <div
        id={'gamedialog'}
        onClick={() => null}
        style={{}}
    >
        <div
            id={'gamedialog_string_wrapper'}
            key={1}
            style={layoutManager.calculateCanvas}
        >
            <Container
                id={'gamedialog_string_content'}
                containerOption={{
                    widthPx: 640,
                    heightPx: 362,
                    borderRadius: 16,
                    border: 'solid #fff',
                    borderSize: 4,
                    zIndex: 60,
                    bgColor: dialogDelete ? '#601806' : '#062860',
                    margin: [(1280 - 362) / 2, 0, 0, (720 - 640) / 2]
                }}
            >
                <Container
                    id={'gamedialog_string_message'}
                    containerOption={{
                        widthPx: 620,
                        heightPx: 48,
                        color: '#fff',
                        fontSize: 32,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 0, 10],
                        role: 'alert'
                    }}
                >
                    {dialogTitle}
                </Container>
                <Container
                    containerOption={{
                        widthPx: 620,
                        heightPx: 32,
                        color: '#fff',
                        fontSize: 32,
                        float: 'left',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        margin: [32, 0, 32, 10],
                        role: 'alert'
                    }}
                >
                    <TextField
                        inputType={'text'}
                        upperCase={dialogDisableUpperCase ? false : true}
                        maxLength={dialogMaxLength}
                        placeholder={dialogKeyword || ''}
                        onInput={(v: any) => {
                            setStringValue(v)
                        }}
                        value={stringValue}
                    />
                </Container>
                <IconButton
                    source={'./data/icon/no.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    onClick={() => {
                        sdk.closeDialog()
                    }}
                />
                <IconButton
                    source={'./data/icon/yes.svg'}
                    buttonOption={{
                        widthPx: 96,
                        heightPx: 96,
                        float: 'right',
                        margin: [64, 32, 0, 0]
                    }}
                    disabled={dialogDelete ? stringValue !== dialogKeyword : allowSameValue ? false : stringValue === dialog.value}
                    onClick={() => {
                        if (dialog?.cbYes) {
                            dialog.cbYes(stringValue)
                        }
                        sdk.closeDialog();
                    }}
                />

            </Container>

        </div>
    </div > : null;
});

export default StringDialog;