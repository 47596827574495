import {
    observer
} from 'mobx-react-lite';
import { ActionButton, Container, IconButton, List } from '../../controls';

const TemplateFile = observer(({ sdk }: any) => {
    const store = sdk.templateStore;
    const allowTemplateDelete = sdk.user.hasPermission('DELETE.TEMPLATE');
    const templateComponents = [
        ...(store.isSaveAs ? [] : store?.activeTemplate?.defaultTemplates || []),
        ...(sdk.user?.account?.currentLicense?.getTemplateList(store?.activeTemplate?.type)?.templates || [])
    ]
        .map((template: any) => (
            <>
                <ActionButton
                    id={'template-single-table'}
                    actionColor={(store.isSaveAs || template.value) ? 'blue' : 'green'}
                    onClick={() => {
                        if (store.activeTemplate.cbSave) {
                            store.activeTemplate.cbSave(template.name, template.id)
                        } else {
                            if (template?.value) {
                                store.activeTemplate.cbLoad(template.value);
                                store.reset();
                            } else {
                                sdk.user.loadTemplate(store.activeTemplate.type, template.id, template.hash).then((x: any) => {
                                    store.activeTemplate.cbLoad(x);
                                    store.reset();
                                })
                            }
                        }
                        // store.setTemplate('singletable');
                        // setTemplateView(false);
                    }}
                    icon={`./data/icon/${store.isSaveAs ? 'diskette' : 'template'}.svg`}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 532,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 26,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 40],
                        textAlign: 'center',
                        float: 'left'
                    }}
                >
                    {template.name}
                </ActionButton>
                {(template.value || !allowTemplateDelete) ? null : (
                    <IconButton
                        id={'save'}
                        source={'./data/icon/delete.svg'}
                        onClick={() => store.deleteTemplate(sdk, store.activeTemplate.type, template.id)}
                        enableDelay={true}
                        // disabled={!store.deletable}
                        buttonOption={{
                            widthPx: 64,
                            heightPx: 64,
                            margin: [32, 0, 8, 0],
                            float: 'right',
                            border: 'none'
                        }}
                    />
                )}

            </>
        ))

    // Show a load from code button
    if (store.activeTemplate.screen === 'load') {
        templateComponents.push(<ActionButton
            id={'template-single-table'}
            actionColor={'yellow'}
            onClick={() => {
                sdk.openStringDialog('Enter template code', '', (code: string) => {
                    sdk.user.loadTemplateWithCode(store.activeTemplate.type, code).then((data: any) => {
                        if(data) {
                            store.activeTemplate.cbLoad(data);
                            store.reset();
                        }
                    })
                }, 32);
                // sdk.openDeleteDialog('Really delete template?', 'DELETE', () => {
                //     sdk.user.deleteTemplate(templateType, templateId).then((success: boolean) => {
                //         if (success) {
                //             that.reset()
                //         }
                //     })
                // })
                // if (store.activeTemplate.cbSave) {
                //     store.activeTemplate.cbSave(template.name, template.id)
                // } else {
                //     if (template?.value) {
                //         store.activeTemplate.cbLoad(template.value);
                //         store.reset();
                //     } else {
                //         sdk.user.loadTemplate(store.activeTemplate.type, template.id, template.hash).then((x: any) => {
                //             store.activeTemplate.cbLoad(x);
                //             store.reset();
                //         })
                //     }
                // }
                // // store.setTemplate('singletable');
                // // setTemplateView(false);
            }}
            icon={`./data/icon/template.svg`}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
            buttonOption={{
                widthPx: 532,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 26,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [16, 0, 16, 40],
                textAlign: 'center',
                float: 'left'
            }}
        >
            Use Template Code
        </ActionButton>)
    }

    // Update the list with a new file button
    if (store.activeTemplate.screen === 'save' && store.activeTemplate.free) {
        templateComponents.push(<ActionButton
            id={'template-single-table'}
            actionColor={'orange'}
            onClick={() => {
                store.activeTemplate.cbSave(null);
            }}
            icon={'./data/icon/diskette.svg'}
            iconOption={{
                widthPx: 64,
                heightPx: 64
            }}
            buttonOption={{
                widthPx: 600,
                heightPx: 96,
                lineHeight: 80,
                fontSize: 30,
                heightToWidth: undefined,
                padding: [0, 8, 0, 8],
                margin: [16, 0, 16, 0],
                textAlign: 'center'
            }}
        >
            {'Save as new template'}
        </ActionButton>)

        if (store.activeTemplate.freeCount > 1) {
            Array.from({ length: 1 }).forEach((t: any, i: number) => {
                templateComponents.push(<ActionButton
                    id={`template-${i}`}
                    actionColor={'orange'}
                    disabled={true}
                    icon={'./data/icon/diskette.svg'}
                    iconOption={{
                        widthPx: 64,
                        heightPx: 64
                    }}
                    buttonOption={{
                        widthPx: 600,
                        heightPx: 96,
                        lineHeight: 80,
                        fontSize: 30,
                        heightToWidth: undefined,
                        padding: [0, 8, 0, 8],
                        margin: [16, 0, 16, 0],
                        textAlign: 'center'
                    }}
                >
                    {'Free Slot'}
                </ActionButton>)
            })
        }
    }
    // The list of all clocks
    return <>
        <Container
            containerOption={{
                width: '100%',
                height: '100%',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Container
                containerOption={{
                    heightPx: 64,
                    lineHeight: 64,
                    widthPx: 680,
                    // margin: [16, 0, 0, 64],
                    float: 'left',
                    // textAlign: 'left',
                    fontSize: 32,
                    fontWeight: 'bold'
                }}
            >
                {store.title}
            </Container>
            <List
                components={templateComponents}
                containerOption={{
                    widthPx: 680,
                    heightPx: 800,
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}
                listOption={{
                    heightPx: 128,
                    widthPx: 680
                }}
            />
            <Container
                containerOption={{
                    margin: [32, 0, 0, 0]
                }}
            >No free slots? Contact us for more</Container>
        </Container>
    </>
});

export default TemplateFile;