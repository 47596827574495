// import { useState } from 'react';

import {
    observer
} from 'mobx-react-lite';
import { List, ActionButton, TextField, Container, Checkbox, IconButton } from '../../controls';
import { BLIND_TEMPLATES } from '../../../constants/blindtemplates';

const PauseElementHead = observer(({ sdk, level, store }: any) => {
    return <Container
        containerOption={{
            bgColor: '#4f4f4f',
            textAlign: 'center',
            width: '100%',
            height: '100%'
        }}
    >
        <Container
            containerOption={{
                width: '10%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {''}
        </Container>
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {'DURATION'}
        </Container>
        <Container
            containerOption={{
                width: '40%',
                height: '100%',
                float: 'left',
                fontSize: 22
            }}
        >
            {'AFTER X PLAYTIME'}
        </Container>
        <Container
            containerOption={{
                width: '30%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}

        >
            Break

            <IconButton
                source={'./data/icon/help1.svg'}
                buttonOption={{
                    widthPx: 48,
                    heightPx: 48,
                    float: 'right',
                    margin: [8, 16, 0, 0]
                }}
                onClick={() => null}
            />
        </Container>
    </Container>
});

const LevelElementHead = observer(({ sdk, level, store }: any) => {
    return <>
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {'LVL'}
        </Container>
        <Container
            containerOption={{
                width: '18%',
                height: '100%',
                float: 'left',
                fontSize: 20
            }}
        >
            {store.allowMattmode ? 'END OF LVL' : 'MIN'}
        </Container>
        <Container
            containerOption={{
                width: '22%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {'SB'}
        </Container>
        <Container
            containerOption={{
                width: '22%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}
        >
            {'BB'}
        </Container>
        <Container
            containerOption={{
                width: '18%',
                height: '100%',
                float: 'left',
                fontSize: 28
            }}

        >
            {store.isCustomAnte ? 'Ante' : null}
            {store.isSbAnte ? 'SB Ante' : null}
            {store.isBbAnte ? 'BB Ante' : null}
        </Container>
    </>
});

const LevelElementRow = observer(({ sdk, level, levelId, levelArrayPos, editmode, store, deleteInsteadLevel, pauseLevel }: any) => {
    const isBreak = level?.isBreak;
    const bgColor = editmode ? 'rgba(120, 190, 255, 0.3)' : '';

    return <>
        <Container
            containerOption={{
                width: '20%',
                height: '100%',
                bgColor,
                float: 'left'
            }}
            onClick={() => deleteInsteadLevel ? store.deleteBreak(levelArrayPos - 1) : store.setEditLevel(levelArrayPos)}
        >
            {deleteInsteadLevel ?
                <IconButton
                    id={'remove'}
                    source={'./data/icon/delete.svg'}
                    enableDelay={false}
                    buttonOption={{
                        widthPx: 60,
                        heightPx: 60,
                        margin: [8, 0, 8, 16],
                        float: 'left',
                        border: 'none'
                    }}
                />
                :
                <>
                    <Checkbox
                        id={`select-lvl-${levelId}`}
                        label={isBreak ? '-' : `00${levelId}`.slice(-2)}
                        value={editmode ? 1 : 0}
                        onChange={() => store.setEditLevel(levelArrayPos)}
                        containerOption={{
                            float: 'left',
                            widthPx: 440,
                            margin: [20, 0, 0, 0]
                        }}
                        labelOption={{
                            widthPx: 64
                        }}
                    />
                </>}
        </Container>
        <Container
            containerOption={{
                width: '18%',
                height: '100%',
                float: 'left',
                bgColor
            }}
        >
            <TextField
                inputType={'number'}
                inputOption={{
                    width: '90%',
                    height: '80%',
                    padding: [0, 0, 0, '10%']
                }}
                store={level}
                storeProperty={pauseLevel ? 'timeMin' : store.allowMattmode ? 'avgStack' : 'timeMin'}
            />
        </Container>
        {isBreak ?
            (<>
                {
                    level.isMattmode ? <>
                        <Container
                            containerOption={{
                                width: '30%',
                                height: '100%',
                                float: 'left'
                            }}
                        >
                            <TextField
                                inputType={'number'}
                                inputOption={{
                                    width: '90%',
                                    height: '80%',
                                    padding: [0, 0, 0, '10%']
                                }}
                                store={level}
                                storeProperty={'timeTotal'}
                            />
                        </Container>
                        <Container
                            containerOption={{
                                width: '30%',
                                height: '100%',
                                float: 'left'
                            }}
                        >
                            Break
                        </Container>
                    </> : <Container
                        containerOption={{
                            width: '60%',
                            height: '100%',
                            float: 'left'
                        }}
                    >
                        Break
                    </Container>
                }
            </>
            ) : (<>
                <Container
                    containerOption={{
                        width: '22%',
                        height: '100%',
                        float: 'left',
                        bgColor
                    }}
                >
                    <TextField
                        inputType={'number'}
                        inputOption={{
                            width: '90%',
                            height: '80%',
                            padding: [0, 0, 0, '10%']
                        }}
                        store={level}
                        storeProperty={'smallBlind'}
                        onInputFinished={() => level.updateAnte(store.isSbAnte, store.isBbAnte)}
                    />
                </Container>
                <Container
                    containerOption={{
                        width: '22%',
                        height: '100%',
                        float: 'left',
                        bgColor
                    }}
                >
                    <TextField
                        inputType={'number'}
                        inputOption={{
                            width: '90%',
                            height: '80%',
                            padding: [0, 0, 0, '10%']
                        }}
                        store={level}
                        storeProperty={'bigBlind'}
                        onInputFinished={() => level.updateAnte(store.isSbAnte, store.isBbAnte)}
                    />
                </Container>
                <Container
                    containerOption={{
                        width: '18%',
                        height: '100%',
                        float: 'left',
                        bgColor
                    }}
                >
                    {store.isCustomAnte ?
                        <TextField
                            inputType={'number'}
                            inputOption={{
                                width: '90%',
                                height: '80%',
                                padding: [0, 0, 0, '10%']
                            }}
                            store={level}
                            storeProperty={'ante'}
                        /> : <Checkbox
                            id={'sbbbante'}
                            value={level.hasAnte}
                            onChange={() => level.switchAnte(store.isBbAnte)}
                            containerOption={{
                                float: 'left',
                                widthPx: 440,
                                margin: [20, 0, 0, 48]
                            }}
                            checkboxImages={['icon/no', 'icon/yes'] }
                            labelOption={{
                                widthPx: 310
                            }}
                        />}
                    {/* {level.hasAnte ? 'Yes' : 'No'} */}
                </Container>
            </>)}

    </>
});

const PauseElementAdd = observer(({ sdk, level, pos, store }: any) => {
    return <Container
        containerOption={{
            width: '100%',
            height: '100%',
            float: 'left'
        }}
    >
        <ActionButton
            actionColor={'green'}
            onClick={() => store.addOwnBreak()}
            buttonOption={{
                widthPx: 320,
                heightPx: 64,
                lineHeight: 16,
                heightToWidth: undefined,
                margin: [16, 0, 16, 0]
            }}
        >
            + Add break
        </ActionButton>
    </Container>
});

const LevelElementEdit = observer(({ sdk, level, pos, store }: any) => {
    return <Container
        containerOption={{
            width: '100%',
            height: '100%',
            float: 'left'
        }}
    >
        <IconButton
            id={'remove'}
            source={'./data/icon/delete.svg'}
            onClick={() => store.removeLevel(level)}
            enableDelay={false}
            buttonOption={{
                widthPx: 64,
                heightPx: 64,
                margin: [16, 64, 0, 0],
                float: 'right',
                border: 'none'
            }}
        />

        <ActionButton
            actionColor={'green'}
            onClick={() => store.addLevel(level, pos)}
            buttonOption={{
                widthPx: 160,
                heightPx: 64,
                lineHeight: 16,
                heightToWidth: undefined,
                margin: [16, 64, 16, 0],
                float: 'right'
            }}
        >
            + Lvl
        </ActionButton>
        {store.allowMattmode ?
            null
            : (
                <ActionButton
                    actionColor={'yellow'}
                    onClick={() => store.addBreak(level, pos)}
                    buttonOption={{
                        widthPx: 160,
                        heightPx: 64,
                        lineHeight: 16,
                        heightToWidth: undefined,
                        margin: [16, 64, 16, 0],
                        float: 'right'
                    }}
                >
                    + Break
                </ActionButton>
            )}

    </Container>
});

const buildPauseLevel = (sdk: any, level: any, store: any) => {
    const lvlArray: any = [];
    let levelId = 0;

    store.pauseLevel.forEach((breakLevel: any, i: number) => {
        lvlArray.push(<LevelElementRow
            sdk={sdk}
            deleteInsteadLevel={true}
            levelId={levelId}
            levelArrayPos={i + 1}
            level={breakLevel}
            pauseLevel={true}
            store={store}
        />)
    });

    lvlArray.push(<PauseElementAdd sdk={sdk} store={store} />)
    return lvlArray;
}

const buildLevel = (sdk: any, level: any, store: any) => {
    let levelId = 0;
    const lvlArray: any = [];

    level.forEach((lvl: any, i: number) => {
        const editMode = i + 1 === store.blindEdit;

        if (!lvl.isBreak) {
            levelId += 1;
        }

        if (editMode) {
            lvlArray.push(<LevelElementEdit sdk={sdk} level={i} pos={-1} store={store} />)
        }

        lvlArray.push(<LevelElementRow
            sdk={sdk}
            level={lvl}
            levelId={levelId}
            levelArrayPos={i + 1}
            editmode={editMode}
            store={store}
        />)

        if (editMode) {
            lvlArray.push(<LevelElementEdit sdk={sdk} level={i} pos={+1} store={store} />)
        }

    })

    return lvlArray;
}

const LevelEditNoLevel = observer(({ sdk, store }: any) => {
    return <>
        <Container>
            <ActionButton
                actionColor={'orange'}
                onClick={() => {
                    sdk.templateStore.openTemplate(
                        'blind',
                        (data: any) => store.loadBlindStructure(data),
                        BLIND_TEMPLATES
                    )
                }}
                // disabled={useStore?.isValid ? false : true}
                icon={'./data/icon/load.svg'}
                iconOption={{
                    widthPx: 64,
                    heightPx: 64
                }}
                buttonOption={{
                    widthPx: 480,
                    heightPx: 96,
                    lineHeight: 80,
                    fontSize: 30,
                    heightToWidth: undefined,
                    padding: [0, 8, 0, 8],
                    margin: [16, 0, 16, 0],
                    textAlign: 'center'
                }}
            >
                Load template
            </ActionButton>
        </Container>
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                margin: [32, 0, 0, 0],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Special Modes
        </Container>
        <Checkbox
            id={'mattmode'}
            store={store}
            storeProperty={'allowMattmode'}
            label={'Savage Average Mode'}
            containerOption={{
                float: 'left',
                widthPx: 440,
                margin: [0, 0, 0, 240]
            }}
            labelOption={{
                widthPx: 310
            }}
        />
        <Container
            containerOption={{
                heightPx: 64,
                lineHeight: 64,
                widthPx: 680,
                margin: [32, 0, 0, 0],
                float: 'left',
                // textAlign: 'left',
                fontSize: 32,
                fontWeight: 'bold'
            }}
        >
            Amount of level
        </Container>
        <TextField
            id={'level-count'}
            inputType={'number'}
            inputOption={{
                widthPx: 500,
                margin: [0, 0, 16, 50],
                float: 'left',
                borderSize: 2,
                border: 'solid #fff'
            }}
            store={store}
            storeProperty={'optionStartLevel'}
        />
        {!store.allowMattmode ? (
            <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        // margin: [16, 0, 0, 64],
                        float: 'left',
                        // textAlign: 'left',
                        fontSize: 32,
                        fontWeight: 'bold'
                    }}
                >
                    Minutes / Level
                </Container>
                <TextField
                    id={'time-count'}
                    inputType={'number'}
                    inputOption={{
                        widthPx: 500,
                        margin: [0, 0, 16, 50],
                        float: 'left',
                        borderSize: 2,
                        border: 'solid #fff'
                    }}
                    store={store}
                    storeProperty={'optionStartTime'}
                />
            </>
        ) : null}
        <ActionButton
            actionColor={'green'}
            onClick={() => store.generate()}
            // disabled={useStore?.isValid ? false : true}
            buttonOption={{
                widthPx: 320,
                heightPx: 96,
                heightToWidth: undefined,
                margin: [16, 0, 16, 16]
            }}
        >
            Generate Blind Structure
        </ActionButton>
    </>
});

const LevelEdit = observer(({ sdk, store }: any) => {

    // The list of all clocks
    return <>

        {store.noLevel ? <>
            <LevelEditNoLevel
                sdk={sdk}
                store={store}
            />
        </> : (<>
            <IconButton
                id={'remove'}
                source={'./data/icon/delete.svg'}
                onClick={() => store.reset()}
                enableDelay={false}
                buttonOption={{
                    widthPx: 60,
                    heightPx: 60,
                    margin: [8, 0, 8, 16],
                    float: 'left',
                    border: 'none'
                }}
            />

            <ActionButton
                actionColor={'green'}
                onClick={() => store.switchAnteType()}
                enableDelay={false}
                buttonOption={{
                    widthPx: 228,
                    heightPx: 64,
                    lineHeight: 16,
                    heightToWidth: undefined,
                    margin: [8, 16, 16, 32],
                    float: 'right'
                }}
            >
                {store.anteTypeLabel}
            </ActionButton>
            <IconButton
                id={'save'}
                source={'./data/icon/diskette.svg'}
                onClick={() => sdk.templateStore.saveTemplate(sdk, 'blind', store.config)}
                enableDelay={false}
                buttonOption={{
                    widthPx: 60,
                    heightPx: 60,
                    margin: [8, 0, 8, 16],
                    float: 'right',
                    border: 'none'
                }}
            />
            {store.allowMattmode ? <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        overflow: 'hidden',
                        bgColor: '#4f4f4f'
                    }}
                >
                    <PauseElementHead sdk={sdk} store={store} />
                </Container>
                <List
                    components={
                        buildPauseLevel(sdk, store.blindLevel, store)
                    }
                    containerOption={{
                        widthPx: 680,
                        heightPx: 240,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    listOption={{
                        heightPx: 96,
                        widthPx: 680
                    }}
                />
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        overflow: 'hidden',
                        bgColor: '#4f4f4f'
                    }}
                >
                    <LevelElementHead sdk={sdk} store={store} />
                </Container>
                <List
                    components={
                        buildLevel(sdk, store.blindLevel, store)
                    }
                    containerOption={{
                        widthPx: 680,
                        heightPx: 516,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    listOption={{
                        heightPx: 96,
                        widthPx: 680
                    }}
                />
            </> : <>
                <Container
                    containerOption={{
                        heightPx: 64,
                        lineHeight: 64,
                        widthPx: 680,
                        overflow: 'hidden',
                        bgColor: '#4f4f4f'
                    }}
                >
                    <LevelElementHead sdk={sdk} store={store} />
                </Container>
                <List
                    components={
                        buildLevel(sdk, store.blindLevel, store)
                    }
                    containerOption={{
                        widthPx: 680,
                        heightPx: 820,
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}
                    listOption={{
                        heightPx: 96,
                        widthPx: 680
                    }}
                />
            </>}

        </>)}



    </>;
});

export default LevelEdit;